import { Button, ButtonProps } from '@mui/material';
import { FC, useContext } from 'react';
import { UICtx } from '../../UIProvider';

const SignInButton: FC<ButtonProps> = props => {
  const { darkMode, isMobile } = useContext(UICtx);

  return (
    <Button
      {...props}
      sx={{
        background: darkMode ? '#222' : '#f5f5f5',
        color: darkMode ? '#AAA' : '#0d0c22',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        textTransform: 'none',
        borderRadius: '8px',
        fontWeight: 700,
        py: 0,
        '&:hover': {
          background: darkMode ? '#333' : '#FAFAFA',
          color: darkMode ? 'white' : '#0d0c22',
        },
        fontSize: '0.7rem',
        width: isMobile ? '50px' : '64px',
        height: '36px',
        ...props.sx,
      }}
      onClick={props.onClick}
    >
      Login
    </Button>
  );
};

export default SignInButton;
