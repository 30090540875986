import React, { useContext } from 'react';
import { Stack, StackProps } from '@mui/material';
import { UICtx } from '../../UIProvider'; // Adjust the import path as needed

// Extend StackProps to allow any props that Stack component accepts
interface StyledStackProps extends StackProps {
  children?: React.ReactNode;
}

const StyledStack: React.FC<StyledStackProps> = ({ children, ...props }) => {
  const { darkMode } = useContext(UICtx);

  return (
    <Stack
      spacing={'6px'}
      {...props} // Spread any other Stack props
      sx={{
        p: '6px',
        backgroundColor: darkMode
          ? 'rgba(230, 255, 255, 0.02)'
          : 'rgba(0, 0, 0, 0.03)',
        borderRadius: '20px',
        ...props.sx, // Allow custom styles to be merged
      }}
    >
      {children}
    </Stack>
  );
};

export default StyledStack;
