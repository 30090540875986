import { Language } from './ifaces';

import { ReactComponent as AR } from './assets/flags/AR.svg';
import { ReactComponent as AT } from './assets/flags/AT.svg';
import { ReactComponent as AU } from './assets/flags/AU.svg';
import { ReactComponent as BE } from './assets/flags/BE.svg';
import { ReactComponent as BO } from './assets/flags/BO.svg';
import { ReactComponent as BR } from './assets/flags/BR.svg';
import { ReactComponent as CH } from './assets/flags/CH.svg';
import { ReactComponent as CL } from './assets/flags/CL.svg';
import { ReactComponent as CZ } from './assets/flags/CZ.svg';
import { ReactComponent as CN } from './assets/flags/CN.svg';
import { ReactComponent as CO } from './assets/flags/CO.svg';
import { ReactComponent as CU } from './assets/flags/CU.svg';
import { ReactComponent as DE } from './assets/flags/DE.svg';
import { ReactComponent as DK } from './assets/flags/DK.svg';
import { ReactComponent as EC } from './assets/flags/EC.svg';
import { ReactComponent as ES } from './assets/flags/ES.svg';
import { ReactComponent as FI } from './assets/flags/FI.svg';
import { ReactComponent as FR } from './assets/flags/FR.svg';
import { ReactComponent as GB } from './assets/flags/GB.svg';
import { ReactComponent as GR } from './assets/flags/GR.svg';
import { ReactComponent as HU } from './assets/flags/HU.svg';
import { ReactComponent as IT } from './assets/flags/IT.svg';
import { ReactComponent as JP } from './assets/flags/JP.svg';
import { ReactComponent as KR } from './assets/flags/KR.svg';
import { ReactComponent as MX } from './assets/flags/MX.svg';
import { ReactComponent as NL } from './assets/flags/NL.svg';
import { ReactComponent as NO } from './assets/flags/NO.svg';
import { ReactComponent as PE } from './assets/flags/PE.svg';
import { ReactComponent as PL } from './assets/flags/PL.svg';
import { ReactComponent as PT } from './assets/flags/PT.svg';
import { ReactComponent as PY } from './assets/flags/PY.svg';
import { ReactComponent as RU } from './assets/flags/RU.svg';
import { ReactComponent as SE } from './assets/flags/SE.svg';
import { ReactComponent as TR } from './assets/flags/TR.svg';
import { ReactComponent as TW } from './assets/flags/TW.svg';
import { ReactComponent as US } from './assets/flags/US.svg';
import { ReactComponent as VE } from './assets/flags/VE.svg';

import { FC } from 'react';
import { Stack, StackProps } from '@mui/material';

export const SUPPORTED_LANGUAGES: Language[] = [
  { language: 'en', code: 'en-US', name: 'English' },
  { language: 'es', code: 'es-ES', name: 'Spanish' },
  { language: 'fr', code: 'fr-FR', name: 'French' },
  { language: 'de', code: 'de-DE', name: 'German' },
  { language: 'fi', code: 'fi-FI', name: 'Finnish' },
  { language: 'it', code: 'it-IT', name: 'Italian' },
  { language: 'nl', code: 'nl-NL', name: 'Dutch' },
];

// Country Flags: https://github.com/danalloway/react-country-flag/blob/main/example/countries.ts
export const LANGUAGE_TO_FLAG_MAP: { [key: string]: string } = {
  'en-us': 'US',
  'en-gb': 'GB',
  'es-es': 'ES',
  'es-ven': 'VE',
  'de-de': 'DE',
  'fi-fi': 'FI',
  'fr-fr': 'FR',
  'it-it': 'IT',
  'nl-nl': 'NL',

};

export const COUNTRY_FLAG_ICONS_MAP: {
  [key: string]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
} = {
  AR: AR,
  AT: AT,
  AU: AU,
  BE: BE,
  BO: BO,
  BR: BR,
  CH: CH,
  CL: CL,
  CZ: CZ,
  CN: CN,
  CO: CO,
  CU: CU,
  DE: DE,
  DK: DK,
  EC: EC,
  ES: ES,
  FI: FI,
  FR: FR,
  GB: GB,
  GR: GR,
  HU: HU,
  IT: IT,
  JP: JP,
  KR: KR,
  MX: MX,
  NL: NL,
  NO: NO,
  PE: PE,
  PL: PL,
  PT: PT,
  PY: PY,
  RU: RU,
  SE: SE,
  TR: TR,
  TW: TW,
  US: US,
  VE: VE,
};

export const FlagIcon: FC<
  {
    countryCode: string;
  } & StackProps
> = props => {
  const FlagComponent = COUNTRY_FLAG_ICONS_MAP[props.countryCode];

  return (
    <Stack
      sx={{
        height: '18px',
        width: '28px',
        borderRadius: '4px',
        overflow: 'hidden',
        ...props.sx,
      }}
    >
      {FlagComponent ? (
        <FlagComponent
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      ) : null}
    </Stack>
  );
};

export const languageToFlag = (language: string): string => {
  return LANGUAGE_TO_FLAG_MAP[language.toLowerCase()] || 'default';
};
