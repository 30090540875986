import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Stack, Box, Tab, styled, TabProps } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ReactCountryFlag from 'react-country-flag';

import { UICtx } from '../../UIProvider';
import { Place, PoI, Tour, TourTemplate } from '../../ifaces';
import AITravelAssistantMap from '../AITravelAssistantMap';
import { DataCtx } from '../../DataProvider';
import {
  getPlaceDetails,
  getPoIsForLocation,
  getTourTemplates,
  updateTourTemplate,
} from '../../API';
import ToursList from './ToursList';
import { GeoPoint } from 'firebase/firestore';
import { useCookies } from 'react-cookie';
// import TourCreationWidget from './TourCreationWidget';
import TemplatesList from '../TourTemplatesList/TemplatesList';
import TourTemplateDetails from '../TourTemplateDetails/TourTemplateDetails';
import PoIsList from '../PoIsList/PoIsList';
import { CopyBold, CopySmallBold } from '../Typography';
import MyLocationButton from '../buttons/MyLocationButton';
import PoICreationWidget from '../PoICreationWidget/PoICreationWidget';
import StyledStack from '../containers/StyledStack';
import UpdateCityPoIsButton from '../buttons/UpdateCityPoIsButton';

interface ToursListWithMapProps {
  tours?: Tour[];
  // templates?: TourTemplate[];
  pois?: PoI[];
}

// interface StyledTabsProps {
//   children?: React.ReactNode;
//   value: number;
//   onChange: (event: React.SyntheticEvent, newValue: number) => void;
// }

const AntTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    marginRight: theme.spacing(1),
    color: '#00294f',
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#1890ff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

const ToursListWithMap: FC<ToursListWithMapProps> = ({
  tours = [],
  // templates = [],
  pois = [],
}) => {
  // Cookies
  const [cookies] = useCookies(['selected_place_id', 'selected_zoom']);

  // Loading State
  const [loadingPoIs, setLoadingPoIs] = useState<boolean>(false);

  // Data Context
  const { selectedPrediction } = useContext(DataCtx);

  // Selected Place State
  const [selectedPlace, setSelectedPlace] = useState<Place>();
  const [value, setValue] = useState('3');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // Map control state
  const [currentPoIs, setCurrentPoIs] = useState<PoI[]>();
  const [currentCoordinates, setCurrentCoordinates] = useState<GeoPoint>();
  const [viewport, setViewport] = useState<{
    viewportNE: GeoPoint;
    viewportSW: GeoPoint;
  }>();
  const { darkMode, isMobile, addNotification } = useContext(UICtx);
  const [mapVisible, setMapVisible] = useState(!isMobile);

  // PoI Selection Control
  const [selectedPoI, setSelectedPoI] = useState<PoI>();

  // Tour Selected
  const [selectedZoom, setSelectedZoom] = useState<number>(9);
  // const [selectedTour, setSelectedTour] = useState<Tour>();

  // Tour Template Details
  const [tourTemplates, setTourTemplates] = useState<TourTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<TourTemplate>();
  const [displayTemplateDetails, setDisplayTemplateDetails] =
    useState<boolean>(false);

  useEffect(() => {
    setMapVisible(!isMobile);
  }, [isMobile]);

  const handleSelectTourTemplate = (template: TourTemplate) => {
    // setDisplayTemplateDetails(true);
    setSelectedTemplate(template);
    // fetchDataForSelectedPlace(template.placeId);
    if (template.viewportCenter) {
      setCurrentCoordinates(
        new GeoPoint(
          template.viewportCenter.latitude,
          template.viewportCenter.longitude,
        ),
      );
    }
    if (template.zoom) {
      setSelectedZoom(template.zoom);
    }
    if (template.viewportNE && template.viewportSW) {
      setViewport({
        viewportNE: new GeoPoint(
          template.viewportNE.latitude,
          template.viewportNE.longitude,
        ),
        viewportSW: new GeoPoint(
          template.viewportSW.latitude,
          template.viewportSW.longitude,
        ),
      });
    }
  };

  const fetchTourTemplates = async () => {
    const templates = await getTourTemplates();
    setTourTemplates(templates);
  };

  const handleOpenTemplateDetails = (template: TourTemplate) => {
    if (template.viewportCenter) {
      setCurrentCoordinates(
        new GeoPoint(
          template.viewportCenter.latitude,
          template.viewportCenter.longitude,
        ),
      );
    }
    if (template.zoom) {
      setSelectedZoom(template.zoom);
    }
    setSelectedTemplate(template);
    setDisplayTemplateDetails(true);
  };

  const fetchDataForSelectedPlace = useCallback(async (place_id: string) => {
    try {
      const response = await getPlaceDetails({ placeId: place_id });
      const placeDetailsResult = response.result;
      setSelectedPlace(placeDetailsResult);
      setCurrentCoordinates(placeDetailsResult.location);
      setSelectedZoom(12);
      // Optionally reset coordinates after a delay
      // setTimeout(() => setCurrentCoordinates(undefined), 1000);
    } catch (error) {
      console.error('Error fetching data for prediction', error);
      // Handle errors, e.g., by setting an error state here
    }
  }, []);

  useEffect(() => {
    if (selectedPrediction) {
      fetchDataForSelectedPlace(selectedPrediction.place_id);
    }
  }, [selectedPrediction, fetchDataForSelectedPlace]);

  useEffect(() => {
    if (cookies.selected_place_id) {
      fetchDataForSelectedPlace(cookies.selected_place_id);
    }
    if (cookies.selected_zoom) {
      setSelectedZoom(parseInt(cookies.selected_zoom));
    }
  }, [cookies, fetchDataForSelectedPlace]);

  const fetchData = useCallback(async () => {
    try {
      if (selectedPlace) {
        setLoadingPoIs(true);
        const PoIs = await getPoIsForLocation({
          lat: selectedPlace.location.latitude,
          lng: selectedPlace.location.longitude,
          radius: 20000,
        });
        setCurrentPoIs(PoIs.result);
      }
    } catch (error) {
      addNotification({
        type: 'error',
        message: `Failed to fetch PoIs for location.`,
      });
      console.error('Error fetching data for prediction', error);
    }
    setLoadingPoIs(false);
  }, [selectedPlace, addNotification]);

  const handleUpdateCityPoIs = async () => {
    try {
      if (selectedPlace) {
        setLoadingPoIs(true);

        // TODO: Update PoIs for the selected city
      }
    } catch (error) {
      addNotification({
        type: 'error',
        message: `Failed to update city PoIs.`,
      });
      console.error('Error fetching data for prediction', error);
    }
    setLoadingPoIs(false);
  };

  useEffect(() => {
    fetchData();
  }, [selectedPlace, fetchData]);

  return (
    <Stack
      sx={{
        flex: 1,
        width: '100%',
        background: darkMode ? '#040404' : '#fff',
        alignItems: 'center',
      }}
    >
      <Stack direction={'row'} sx={{ flex: 1, width: '100%' }}>
        <Stack flex={1}>
          {/* //////////////////////////////////////////////////////////////////// */}
          {/* ////                     Tour Template Details                  //// */}
          {/* //////////////////////////////////////////////////////////////////// */}

          {displayTemplateDetails && selectedTemplate && (
            <Stack
              sx={{
                flex: 1,
                m: 2,
                mr: isMobile ? 2 : 1,
                borderRadius: '10px',
                overflow: 'hidden',
                filter: !darkMode
                  ? 'drop-shadow(0px 0px 10px rgba(70, 70, 70, 0.2))'
                  : undefined,
              }}
            >
              <TourTemplateDetails
                template={selectedTemplate}
                onClose={() => {
                  setDisplayTemplateDetails(false);
                  setSelectedTemplate(undefined);
                }}
              />
            </Stack>
          )}

          {/* //////////////////////////////////////////////////////////////////// */}
          {/* ////                         Tab Selector                       //// */}
          {/* //////////////////////////////////////////////////////////////////// */}

          {!displayTemplateDetails && (
            <Stack
              sx={{
                flex: 1,
                p: 2,
                pr: isMobile ? 2 : '6px',
              }}
            >
              <StyledStack
                sx={{
                  width: '100%',
                  p: 2,
                  py: 1,
                  borderRadius: '10px',
                  alignItems: 'center',
                }}
                direction={'row'}
              >
                <Stack flex={1}>
                  <CopyBold
                    sx={{
                      fontSize: '0.8rem',
                      color: darkMode ? '#666' : '#333',
                    }}
                  >
                    Selected Place
                  </CopyBold>
                  <Stack direction={'row'} spacing={1}>
                    {selectedPlace?.countryShort && (
                      <ReactCountryFlag
                        countryCode={selectedPlace.countryShort}
                        svg
                        style={{
                          width: '1.5em',
                          height: '1.5em',
                        }}
                      />
                    )}
                    <CopySmallBold>
                      {selectedPlace?.name}, {selectedPlace?.country}
                    </CopySmallBold>
                  </Stack>
                </Stack>
                <MyLocationButton
                  onClick={() => {
                    if (selectedPlace) {
                      setCurrentCoordinates(selectedPlace.location);
                      setSelectedZoom(14);
                      setTimeout(() => {
                        setCurrentCoordinates(undefined);
                      }, 1000);
                    }
                  }}
                />

                <UpdateCityPoIsButton onClick={handleUpdateCityPoIs} />
              </StyledStack>

              <Box
                component={Stack}
                sx={{
                  width: '100%',
                  typography: 'body1',
                  flex: 1,
                  pt: 0,
                }}
              >
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <AntTab
                        label={`PoIs (${currentPoIs?.length || 0})`}
                        value="3"
                      />
                      <AntTab
                        label={`Tours (${tours?.length || 0})`}
                        value="1"
                      />
                      <AntTab
                        label={`Templates (${tourTemplates?.length || 0})`}
                        value="2"
                      />
                    </TabList>
                  </Box>
                  {/* //////////////////////////////////////////////////////////////////// */}
                  {/* ////                          Tours List                        //// */}
                  {/* //////////////////////////////////////////////////////////////////// */}

                  <TabPanel value="1">
                    <ToursList tours={tours} />
                  </TabPanel>

                  {/* //////////////////////////////////////////////////////////////////// */}
                  {/* ////                        Tour Templates                      //// */}
                  {/* //////////////////////////////////////////////////////////////////// */}

                  <TabPanel
                    value="2"
                    sx={{
                      '&.MuiTabPanel-root': {
                        display: value === '2' ? 'flex' : 'none',
                        flex: 1,
                        width: '100%',
                        p: 0,
                      },
                    }}
                  >
                    <TemplatesList
                      templates={tourTemplates}
                      onTemplateSelected={handleSelectTourTemplate}
                      onOpenTemplateDetails={handleOpenTemplateDetails}
                      onCenterOnMap={tourTemplate => {
                        setSelectedTemplate(tourTemplate);
                        setSelectedPoI(undefined);
                        setTimeout(() => {
                          setSelectedTemplate(undefined);
                        }, 1000);
                      }}
                    />
                  </TabPanel>

                  {/* //////////////////////////////////////////////////////////////////// */}
                  {/* ////                              PoIs                          //// */}
                  {/* //////////////////////////////////////////////////////////////////// */}

                  <TabPanel
                    value="3"
                    sx={{
                      '&.MuiTabPanel-root': {
                        display: value === '3' ? 'flex' : 'none',
                        flex: 1,
                        width: '100%',
                        p: 0,
                      },
                    }}
                  >
                    <Stack flex={1}>
                      <PoIsList
                        loading={loadingPoIs}
                        pois={currentPoIs}
                        onCenterOnMap={location => {
                          setSelectedTemplate(undefined);
                          setTimeout(() => {
                            setSelectedPoI(undefined);
                          }, 1000);
                        }}
                        onPoISelected={poi => {
                          setSelectedPoI(poi);
                          setSelectedTemplate(undefined);
                        }}
                      />
                      {/* //////////////////////////////////////////////////////////////////// */}
                      {/* ////                      PoI Creation Widget                   //// */}
                      {/* //////////////////////////////////////////////////////////////////// */}

                      <PoICreationWidget />
                    </Stack>
                  </TabPanel>
                </TabContext>
              </Box>
              {/* //////////////////////////////////////////////////////////////////// */}
              {/* ////                      Tour Creation Widget                  //// */}
              {/* //////////////////////////////////////////////////////////////////// */}

              {/* <TourCreationWidget place={selectedPlace} /> */}
            </Stack>
          )}
        </Stack>

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                               MAP                              //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <Stack
          sx={{
            display: mapVisible ? 'flex' : 'none',
            flex: 1,
            borderRadius: '10px',
            overflow: 'hidden',
            m: 2,
            ml: '6px',
          }}
        >
          {!isMobile ? (
            mapVisible ? (
              <AITravelAssistantMap
                selectedCenter={currentCoordinates}
                selectedZoom={selectedZoom}
                selectedPoI={selectedPoI}
                selectedTourTemplate={selectedTemplate}
                onSaveCenterAndZoom={
                  selectedTemplate
                    ? async (center, zoom) => {
                        await updateTourTemplate(selectedTemplate?.id, {
                          center: center,
                          zoom: zoom,
                        });
                        await fetchTourTemplates();
                        // setCurrentCoordinates(center);
                      }
                    : undefined
                }
                viewport={viewport}
                // markers={markers}
                tourPoIIds={selectedTemplate?.stops.map(stop => stop.placeId)}
                // encodedPolyline={selectedTemplate?.encodedPolyline}
                pois={currentPoIs}
              />
            ) : (
              <Stack sx={{ flex: 1 }}>Loading</Stack>
            )
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ToursListWithMap;
