import { FC } from 'react';
import NavigationBar from './NavigationBar';

interface FixedNavigationBarProps {
  lightVersion?: boolean;
  displayDarkModeSwitch?: boolean;
  withSearchBar?: boolean;
}

const FixedNavigationBar: FC<FixedNavigationBarProps> = props => {
  return <NavigationBar useFixedPosition={false} {...props} />;
};

export default FixedNavigationBar;
