import { FC } from 'react';
import { PoI } from '../../ifaces';
import { ExtraBoldItalic } from '../Typography';
import { Stack } from '@mui/material';

interface PoIPopularityrops {
  poi: PoI;
}

const PoIPopularity: FC<PoIPopularityrops> = ({ poi }) => {
  const roundToPlaces = (value: number, places: number) => {
    const maxValue = 10;
    const factor = 10 ** places;
    return Math.round(value * maxValue * factor) / factor;
  };

  return (
    <Stack
    // sx={{
    //   background: 'rgba(125, 125,125, 0.5)',
    //   borderRadius: '50%',
    // }}
    >
      <ExtraBoldItalic
        sx={{
          fontSize: '1rem',
        }}
      >
        {poi?.estimatedPopularity
          ? roundToPlaces(poi.estimatedPopularity, 1)
          : 0}{' '}
        ({poi.estimatedPopularityWeight || 0})
      </ExtraBoldItalic>
    </Stack>
  );
};

export default PoIPopularity;
