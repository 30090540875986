import { FC, useContext } from 'react';
import { ButtonProps } from '@mui/material';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { UICtx } from '../../UIProvider';
import PLessIconButton from './PLessIconButton';

const OpenInNewButton: FC<{ fontSize?: string } & ButtonProps> = ({
  onClick,
  sx,
  fontSize,
}) => {
  const { darkMode } = useContext(UICtx);

  return (
    <PLessIconButton
      onClick={onClick}
      sx={{
        color: darkMode ? '#777' : '#000',
        ':hover': {
          color: darkMode ? '#fff' : '#000',
        },
        ...sx,
      }}
    >
      <OpenInNew
        sx={{
          fontSize: fontSize ? fontSize : '0.9rem',
        }}
      />
    </PLessIconButton>
  );
};

export default OpenInNewButton;
