import { Stack } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { Copy, Header1 } from './Typography';
import GetStartedButton from './buttons/GetStartedButton';
import { UICtx } from '../UIProvider';
import { DataCtx } from '../DataProvider';

interface AITravelAssistantIntroProps {
  onIntroComplete?: () => void;
  onGetStarted?: () => void;
}

const IntroMessage = () => {
  return (
    <Stack>
      <Header1
        sx={{
          textAlign: 'center',
        }}
      >
        AI Travel Assistant
      </Header1>
      <Copy sx={{ textAlign: 'center' }}>
        Use GPT-4 to generate a travel itinerary.
      </Copy>
    </Stack>
  );
};

const AITravelAssistantIntro: FC<AITravelAssistantIntroProps> = ({
  onIntroComplete,
  onGetStarted,
}) => {
  const { isMobile } = useContext(UICtx);
  const { isLoading } = useContext(DataCtx);
  const [progress] = useState(0);
  // const [localLoading, setLocalLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (progress === 100) {
        onIntroComplete?.();
      }
    }, 300);
  }, [progress, onIntroComplete]);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress(oldProgress => {
  //       const diff = Math.random() * 10;
  //       return Math.min(oldProgress + diff, 100);
  //     });
  //   }, 50);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        p: isMobile ? 2 : 4,
      }}
      spacing={2}
    >
      {/* {localLoading ? (
        <SplashScreen progress={progress} />
      ) : ( */}
      <>
        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                           INTRO MESSAGE                        //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <IntroMessage />

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                        GET STARTED BUTTON                      //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <GetStartedButton onClick={onGetStarted} disabled={isLoading} />
      </>
      {/* )} */}
    </Stack>
  );
};

export default AITravelAssistantIntro;
