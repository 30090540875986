import { FC, useContext, useState } from 'react';
import { Button, IconButton, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { UICtx } from '../../UIProvider';
import PoISearchInput from '../PoISearchInput/PoISearchInput';
import { AutoCompletePrediction, createPoI, getPoI } from '../../API';
import { CopySmallBold } from '../Typography';
import { City, PoI } from '../../ifaces';
import LoadingView from '../../pages/LoadingPage/LoadingView';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px',
  p: 4,
};

interface ComponentProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  withCity?: City;
  onPoICreated?: (poi: PoI) => void;
}

const PoICreationModal: FC<ComponentProps> = ({
  open,
  setOpen,
  withCity,
  onPoICreated,
}) => {
  const { darkMode, addNotification } = useContext(UICtx);
  const [prediction, setPrediction] = useState<AutoCompletePrediction>();
  const [creatingPoI, setCreatingPoI] = useState(false);

  const clear = () => {};

  const handleClose = () => {
    setOpen(false);
    setTimeout(clear, 500); // Delay due to closing animation
  };

  const handlePredictionSelected = (prediction: AutoCompletePrediction) => {
    setPrediction(prediction);
  };

  const handleCreatePoI = async (placeId: string) => {
    try {
      setCreatingPoI(true);
      await createPoI(placeId, withCity?.id);
      const poi = await getPoI(placeId);
      if (poi && onPoICreated) {
        onPoICreated(poi);
      }
    } catch (error) {
      console.error('Failed to create PoI:', error);
      addNotification({
        type: 'error',
        message: `Failed to create PoI.`,
      });
    }
    setCreatingPoI(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={{ ...style, background: darkMode ? '#161616' : '#fff' }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 20,
              right: 20,
              color: darkMode ? '#999' : '#000',
              '&:hover': {
                color: darkMode ? '#fff' : '#000',
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Stack
            sx={{
              flex: 1,
              minHeight: '70vh',
            }}
            spacing={2}
          >
            {creatingPoI ? (
              <LoadingView />
            ) : (
              <>
                <PoISearchInput
                  onPredictionSelected={handlePredictionSelected}
                />

                {prediction && (
                  <Stack>
                    <CopySmallBold>{prediction.description}</CopySmallBold>
                    <Button
                      onClick={async () => {
                        await handleCreatePoI(prediction.place_id);
                      }}
                    >
                      <CopySmallBold>Create</CopySmallBold>
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </Stack>
          {/* End of Main Stack */}
        </Box>
        {/* End of Modal Box */}
      </Fade>
    </Modal>
  );
};

export default PoICreationModal;
