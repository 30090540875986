import { FC, useContext, useState } from 'react';
import { Button, Stack } from '@mui/material';

// Contexts
import { UICtx } from '../../UIProvider';

// CUSTOM
import { Header3 } from '../Typography';
import StayLengthSelect from '../StayLengthSelect';

export enum AITravelAssistantState {
  INITIAL_LOADING = 0,
  INTRO = 1,
  GET_DESTINATION = 2,
  GET_DURATION = 3,
  LOADING_POIS = 4,
  DISPLAY_POIS = 5,
  GENERATING_ITINERARY = 6,
}

interface DurationInputProps {
  onDurationSelected: (length: number) => void;
}

const DurationInput: FC<DurationInputProps> = ({ onDurationSelected }) => {
  // Contexts
  const { isMobile, darkMode } = useContext(UICtx);

  const [stayLength, setStayLength] = useState<number>(0);

  const handleStayLengthChange = (length: number) => {
    setStayLength(length);
  };

  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        p: isMobile ? 4 : 8,
      }}
      spacing={2}
    >
      <Header3
        sx={{
          textAlign: 'center',
        }}
      >
        How long are you staying there?
      </Header3>
      <Stack direction={'row'} sx={{ minWidth: '250px' }} spacing={1}>
        <StayLengthSelect
          darkMode={darkMode}
          onStayLengthSelected={handleStayLengthChange}
        />
        <Button
          disabled={stayLength === 0}
          sx={{
            background: darkMode ? '#141414' : '#EEE',
            color: darkMode ? '#888' : '#0d0c22',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            textTransform: 'none',
            borderRadius: '8px',
            py: 0,
            '&:hover': {
              border: darkMode ? '1px solid white' : '1px solid #0d0c22',
              color: darkMode ? 'white' : '#0d0c22',
            },
            fontSize: 16,
            '&:disabled': {
              color: darkMode ? '#888' : '#0d0c22',
              background: darkMode ? '#111' : '#EEE',
            },
            transition: 'none',
          }}
          onClick={() => onDurationSelected(stayLength)}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
};

export default DurationInput;
