import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import 'mapbox-gl/dist/mapbox-gl.css';
import './index.css';

// MUI
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

// FIREBASE
// Importing the firebase module will initialize the firebase app client
import './Firebase';

// Provider
import { AuthProvider } from './AuthProvider';
import { DataProvider } from './DataProvider';

// Pages
import SignInPage from './pages/SignInPage/SignInPage';
import Page404 from './pages/404Page/Page404';
import theme from './theme';
import { UIProvider } from './UIProvider';

import reportWebVitals from './reportWebVitals';
import IndexPage from './pages/LandingPage/IndexPage';
import SignOutPage from './pages/SignOutPage/SignOutPage';
import DevPage from './pages/DevPage/DevPage';
import SecureLayout from './pages/SecureLayout';
import TripDetailsPage from './pages/TripDetailsPage/TripDetailsPage';
import TripsPage from './pages/TripsPage/TripsPage';
import AudioGuidePage from './pages/AudioGuidePage/AudioGuidePage';
import BuilderPage from './pages/BuilderPage/BuilderPage';
import TourDetailsPage from './pages/TourDetailsPage/TourDetailsPage';
import ToursPage from './pages/ToursPage/ToursPage';
import CitiesPage from './pages/CitiesPage/CitiesPage';
import CityDetailsPage from './pages/CityDetailsPage/CityDetailsPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <UIProvider>
        <AuthProvider>
          <DataProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<IndexPage />} />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                               Builder                         //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route
                    path="/builder"
                    element={
                      <SecureLayout adminRequired>
                        <BuilderPage />
                      </SecureLayout>
                    }
                  />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                                Cities                          //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route
                    path="/cities"
                    element={
                      <SecureLayout adminRequired>
                        <CitiesPage />
                      </SecureLayout>
                    }
                  />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                             City Details                       //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route
                    path="/cities/:cityId"
                    element={
                      <SecureLayout adminRequired>
                        <CityDetailsPage />
                      </SecureLayout>
                    }
                  />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                                Tours                           //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route
                    path="/tours"
                    element={
                      <SecureLayout adminRequired>
                        <ToursPage />
                      </SecureLayout>
                    }
                  />

                  <Route
                    path="/tours/:id"
                    element={
                      <SecureLayout adminRequired>
                        <TourDetailsPage />
                      </SecureLayout>
                    }
                  />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                            Trips Overview                      //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route
                    path="/trips"
                    element={
                      <SecureLayout adminRequired>
                        <TripsPage />
                      </SecureLayout>
                    }
                  />

                  <Route
                    path="/trips/:id"
                    element={
                      <SecureLayout adminRequired>
                        <TripDetailsPage />
                      </SecureLayout>
                    }
                  />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                            AudioGuidePage                      //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route
                    path="/audioguides/:id"
                    element={
                      <SecureLayout adminRequired>
                        <AudioGuidePage />
                      </SecureLayout>
                    }
                  />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                               SignIn                           //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route path="/login" element={<SignInPage />} />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                               SignOut                          //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route path="/signout" element={<SignOutPage />} />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                            Token Sign In                       //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route
                    path="/auth"
                    element={<SignInPage allowSignInWithCode />}
                  />

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                                 DEV                            //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  {process.env.REACT_APP_DEBUG === 'true' && (
                    <Route path="/dev" element={<DevPage />} />
                  )}

                  {/* //////////////////////////////////////////////////////////////////////// */}
                  {/* ////                          NOT FOUND PAGE                        //// */}
                  {/* //////////////////////////////////////////////////////////////////////// */}

                  <Route path="*" element={<Page404 />} />
                </Routes>
              </BrowserRouter>
            </ThemeProvider>
          </DataProvider>
        </AuthProvider>
      </UIProvider>
    </CookiesProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
