import * as React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PLessIconButton from '../buttons/PLessIconButton';
import { UICtx } from '../../UIProvider';
import { City } from '../../ifaces';

interface PoIItemOptionsProps {
  onDelete?: () => void;
  onEdit?: () => void;
  onAddToCity?: () => void;
  city?: City;
}

const PoIItemOptions: React.FC<PoIItemOptionsProps> = ({
  onDelete,
  onEdit,
  onAddToCity,
  city,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { darkMode } = React.useContext(UICtx);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnDelete = () => {
    handleClose();
    onDelete?.();
  };

  const handleOnEdit = () => {
    handleClose();
    onEdit?.();
  };

  const handleAddToCity = () => {
    handleClose();
    onAddToCity?.();
  };

  return (
    <>
      <PLessIconButton sx={{ p: 0 }} onClick={handleClick}>
        <MoreVertIcon
          sx={{
            color: darkMode ? '#777' : '#000',
            fontSize: '1.2rem',
            '&:hover': {
              color: darkMode ? '#fff' : '#000',
            },
          }}
        />
      </PLessIconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOnEdit}>Edit</MenuItem>
        <MenuItem onClick={handleOnDelete}>Delete</MenuItem>
        {city && <MenuItem onClick={handleAddToCity}>Add to city</MenuItem>}
      </Menu>
    </>
  );
};

export default PoIItemOptions;
