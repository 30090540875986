import { FC, useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

// Contexts
import { UICtx } from '../UIProvider';
import { AuthCtx } from '../AuthProvider';
import { DataCtx } from '../DataProvider';

// CUSTOM
import { firestore } from '../Firebase';
import ChatInputField from './InputField';
import { Copy, CopySmall, Header3 } from './Typography';
import AITravelAssistantIntro from './AITravelAssistantIntro';
import AITravelAssistantPoIsList from './PoIsList/PoIsListWithMap';
import {
  AutoCompletePrediction,
  addTripStop,
  createTrip,
  getTrips,
} from '../API';
import DebugPanel from './DebugPanel/DebugPanel';
import LoadingView from '../pages/LoadingPage/LoadingView';
import DurationInput from './DurationInput/DurationInput';

export enum AITravelAssistantState {
  INITIAL_LOADING = 0,
  INTRO = 1,
  GET_DESTINATION = 2,
  GET_DURATION = 3,
  LOADING_POIS = 4,
  DISPLAY_POIS = 5,
  GENERATING_ITINERARY = 6,
}

const stateToString = (state?: AITravelAssistantState) => {
  switch (state) {
    case AITravelAssistantState.INITIAL_LOADING:
      return 'INITIAL_LOADING';
    case AITravelAssistantState.INTRO:
      return 'INTRO';
    case AITravelAssistantState.GET_DESTINATION:
      return 'GET_DESTINATION';
    case AITravelAssistantState.GET_DURATION:
      return 'GET_DURATION';
    case AITravelAssistantState.LOADING_POIS:
      return 'LOADING_POIS';
    case AITravelAssistantState.DISPLAY_POIS:
      return 'DISPLAY_POIS';
    case AITravelAssistantState.GENERATING_ITINERARY:
      return 'GENERATING_ITINERARY';
    default:
      return 'UNKNOWN';
  }
};

interface AITravelAssistantProps {
  onRequestUserToSignInAnonymously?: () => void;
}

const AITravelAssistant: FC<AITravelAssistantProps> = () => {
  // Navigation Hook
  const navigate = useNavigate();

  // Contexts
  const { setTrips, selectedTrip, setSelectedTrip, isLoading } =
    useContext(DataCtx);
  const { user } = useContext(AuthCtx);
  const { isMobile } = useContext(UICtx);

  const [loadingMessage, setLoadingMessage] = useState<string>('Loading...');

  const [assistantState, setAssistantState] = useState<
    AITravelAssistantState | undefined
  >(AITravelAssistantState.INITIAL_LOADING);

  const [destination, setDestination] = useState<string>();

  const handleGetStarted = async () => {
    setLoadingMessage('Initializing... ✈️');
    const loadingStartTime = Date.now();
    // First make sure the user is signed in
    if (!user) {
      try {
        const auth = getAuth();
        await signInAnonymously(auth);

        const res = await createTrip();
        setTrips([res.result]);
        setSelectedTrip(res.result);
        setAssistantState(AITravelAssistantState.GET_DESTINATION);
        setLoadingMessage('');
      } catch (e) {
        console.log(e);
        setLoadingMessage('');
        return;
      }
    } else {
      // Make sure user trips are loaded
      if (!selectedTrip) {
        const response = await getTrips();
        if (response.status === 'OK') {
          setTrips(response.result);
        } else if (response.status === 'ZERO_RESULTS') {
          // To start create a new Trip instance
          const res = await createTrip();
          setTrips([res.result]);
          setSelectedTrip(res.result);
        }
        const timeSinceLastLoading = Date.now() - loadingStartTime;
        const shouldWait = timeSinceLastLoading < 1000;
        setTimeout(
          () => {
            // Then get the destination from the user
            setAssistantState(AITravelAssistantState.GET_DESTINATION);
            setLoadingMessage('');
          },
          shouldWait ? 1500 - timeSinceLastLoading : 0,
        );
      } else {
        setLoadingMessage('');
        if (
          !selectedTrip.initialConfig ||
          !selectedTrip.initialConfig.destination
        ) {
          setAssistantState(AITravelAssistantState.GET_DESTINATION);
        } else if (!selectedTrip.initialConfig.duration) {
          setDestination(selectedTrip.initialConfig.destination);
          setAssistantState(AITravelAssistantState.GET_DURATION);
        } else {
          navigate(`/trips/${selectedTrip.id}`);
          // setAssistantState(AITravelAssistantState.LOADING_POIS);
        }
      }
    }
  };

  /**
   *
   * @param place
   */
  // const handleCreateItinerary = async (place: AutoCompletePrediction) => {
  //   setAssistantState(AITravelAssistantState.GENERATING_ITINERARY);
  //   try {
  //     await generateItinerary(place.place_id);
  //     setAssistantState(AITravelAssistantState.DISPLAY_RESULT);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const handlePlaceSelected = async (place: AutoCompletePrediction) => {
    setAssistantState(AITravelAssistantState.GET_DURATION);
    if (selectedTrip) {
      await updateDoc(doc(firestore, 'trips', selectedTrip.id), {
        initialConfig: {
          ...selectedTrip.initialConfig,
          destination: place.place_id,
        },
      });
    }
  };

  const handleStayLengthSelected = async (selectedDuration: number) => {
    if (selectedTrip && destination) {
      setAssistantState(AITravelAssistantState.LOADING_POIS);
      const tripDoc = doc(firestore, 'trips', selectedTrip.id);
      const docSnap = await getDoc(tripDoc);
      await updateDoc(tripDoc, {
        initialConfig: {
          ...docSnap.data()?.initialConfig,
          duration: selectedDuration,
        },
      });

      await addTripStop(selectedTrip.id, {
        destination: destination,
        duration: selectedDuration,
      });

      navigate(`/trips/${selectedTrip.id}`);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (
        isLoading &&
        assistantState === AITravelAssistantState.INITIAL_LOADING
      ) {
        setAssistantState(AITravelAssistantState.INTRO);
        setLoadingMessage('');
      }
    }, 500);
  }, [isLoading, assistantState]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (selectedTrip) {
  //       if (assistantState === AITravelAssistantState.INITIAL_LOADING) {
  //         //   if (selectedTrip.initialConfig?.destination && selectedTrip.initialConfig?.duration) {
  //         //     setAssistantState(AITravelAssistantState.LOADING_POIS);
  //         //   } else if (!selectedTrip.initialConfig?.destination || !selectedTrip.initialConfig?.duration) {
  //         //     setAssistantState(AITravelAssistantState.INTRO);
  //         //   } else {
  //         //     setAssistantState(AITravelAssistantState.INTRO);
  //         //   }
  //         setAssistantState(AITravelAssistantState.INTRO);
  //         setLoadingMessage('');
  //       }
  //       if (assistantState === AITravelAssistantState.GET_DURATION) {
  //         setAssistantState(AITravelAssistantState.LOADING_POIS);
  //         setLoadingMessage('Loading...');
  //       }
  //     }
  //   }, 500);
  // }, [selectedTrip, assistantState, isLoading]);

  if (loadingMessage) {
    return (
      <>
        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                              DEBUGGING                         //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        {process.env.REACT_APP_DEBUG === 'true' && (
          <DebugPanel
            message={stateToString(assistantState) + ': ' + selectedTrip?.id}
          />
        )}

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                            LOADING MESSAGE                     //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <LoadingView withMessage={loadingMessage} />
      </>
    );
  }

  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
      spacing={1}
    >
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                              DEBUGGING                         //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {process.env.REACT_APP_DEBUG === 'true' && (
        <DebugPanel
          message={stateToString(assistantState) + ': ' + selectedTrip?.id}
        />
      )}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                                INTRO                           //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {assistantState === AITravelAssistantState.INTRO && (
        <AITravelAssistantIntro
          onIntroComplete={() => {
            console.log('Intro Complete');
            setAssistantState(AITravelAssistantState.GET_DESTINATION);
          }}
          onGetStarted={handleGetStarted}
        />
      )}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                       GET INITIAL DESTINATION                  //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {assistantState === AITravelAssistantState.GET_DESTINATION && (
        <Stack
          sx={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            p: isMobile ? 4 : 8,
          }}
          spacing={1}
        >
          <Stack spacing={2} alignItems={'center'}>
            <Header3
              sx={{
                textAlign: 'center',
              }}
            >
              Where are you travelling to?
            </Header3>
            <CopySmall sx={{ color: '#666' }}>
              Example: Berlin, Germany
            </CopySmall>
          </Stack>
          <ChatInputField onPlaceSelected={handlePlaceSelected} />
        </Stack>
      )}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                      GET INITIAL TRIP DURATION                 //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {assistantState === AITravelAssistantState.GET_DURATION && (
        <DurationInput onDurationSelected={handleStayLengthSelected} />
      )}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                             DISPLAY POIS                       //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {assistantState === AITravelAssistantState.DISPLAY_POIS && (
        <AITravelAssistantPoIsList />
      )}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                          GENERATE ITINERARY                    //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {assistantState === AITravelAssistantState.GENERATING_ITINERARY && (
        <Copy sx={{ textAlign: 'center' }}>
          Generating Itinerary for {destination}
        </Copy>
      )}
    </Stack>
  );
};

export default AITravelAssistant;
