import React, { FC, useEffect, useState } from 'react';

// MUI
import { Box, CircularProgress, Stack } from '@mui/material';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';

// Contexts
import { AuthCtx } from '../../AuthProvider';

// CUSTOM
import { UICtx } from '../../UIProvider';
import { CopySmall } from '../../components/Typography';

interface WindowsLocationType {
  from: Location | undefined;
}

interface SignInPageProps {
  allowSignInWithCode?: boolean;
}

const SignInPage: FC<SignInPageProps> = () => {
  // HOOKS
  const location = useLocation();
  const navigate = useNavigate();

  // CONTEXTS
  const { user } = React.useContext(AuthCtx);
  const { darkMode, locale } = React.useContext(UICtx);

  // STATE
  const [isLoading, setLoading] = useState<boolean>(false);

  const state = location.state as WindowsLocationType | undefined;
  const from = state?.from?.pathname || '/';

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [from, navigate, user]);

  const handleSignInWithGoogle = async () => {
    // Sign in using firebase google auth provider
    setLoading(true);
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(result => {
        console.log(result);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleSignInWithApple = async () => {
    const provider = new OAuthProvider('apple.com');
    provider.setCustomParameters({
      locale: locale,
    });

    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(result => {
        console.log(result);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <Stack
      sx={{
        background: darkMode ? 'black' : 'white',
        flex: 1,
        backgroundSize: 'cover',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLoading ? (
        <CircularProgress sx={{ color: 'white' }} />
      ) : (
        <Stack
          spacing={2}
          minWidth={'400px'}
          sx={{ padding: 10, alignItems: 'center' }}
        >
          <CopySmall sx={{ textTransform: 'uppercase' }}>
            Sign up with
          </CopySmall>
          <Stack direction={'row'} spacing={2}>
            <Box
              onClick={() => handleSignInWithGoogle()}
              title="Sign in with Apple"
              sx={{
                width: '48px',
                height: '48px',
                borderRadius: '10px',
                color: 'white',
                backgroundPosition: 'center',
                backgroundImage:
                  'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjU3OTYgOS4xNjg2MkMxNy41Nzk2IDguNDMxMTcgMTcuNTE5NyA3Ljg5MzAzIDE3LjM5MDIgNy4zMzQ5Nkg4Ljk2OTI0VjEwLjY2MzRIMTMuOTEyMkMxMy44MTI1IDExLjQ5MDYgMTMuMjc0NCAxMi43MzYzIDEyLjA3ODUgMTMuNTczNEwxMi4wNjE3IDEzLjY4NDhMMTQuNzI0MyAxNS43NDc1TDE0LjkwODggMTUuNzY1OUMxNi42MDI5IDE0LjIwMTMgMTcuNTc5NiAxMS44OTkyIDE3LjU3OTYgOS4xNjg2MloiIGZpbGw9IiM0Mjg1RjQiLz4KPHBhdGggZD0iTTguOTY4OTUgMTcuOTM4NEMxMS4zOTA2IDE3LjkzODQgMTMuNDIzNSAxNy4xNDExIDE0LjkwODUgMTUuNzY1OUwxMi4wNzgyIDEzLjU3MzRDMTEuMzIwOCAxNC4xMDE1IDEwLjMwNDMgMTQuNDcwMyA4Ljk2ODk1IDE0LjQ3MDNDNi41OTcxNCAxNC40NzAzIDQuNTg0MDkgMTIuOTA1NyAzLjg2NjUgMTAuNzQzMkwzLjc2MTMxIDEwLjc1MjFMMC45OTI3NDkgMTIuODk0N0wwLjk1NjU0MyAxMi45OTU0QzIuNDMxNDQgMTUuOTI1MiA1LjQ2MTAxIDE3LjkzODQgOC45Njg5NSAxNy45Mzg0WiIgZmlsbD0iIzM0QTg1MyIvPgo8cGF0aCBkPSJNMy44NjY2MyAxMC43NDMxQzMuNjc3MjkgMTAuMTg1IDMuNTY3NzEgOS41ODcwNCAzLjU2NzcxIDguOTY5MkMzLjU2NzcxIDguMzUxMjkgMy42NzcyOSA3Ljc1MzM4IDMuODU2NjcgNy4xOTUzMUwzLjg1MTY2IDcuMDc2NDVMMS4wNDg0IDQuODk5NDFMMC45NTY2NzkgNC45NDMwNEMwLjM0ODgwMSA2LjE1ODg2IDAgNy41MjQxOSAwIDguOTY5MkMwIDEwLjQxNDIgMC4zNDg4MDEgMTEuNzc5NSAwLjk1NjY3OSAxMi45OTUzTDMuODY2NjMgMTAuNzQzMVoiIGZpbGw9IiNGQkJDMDUiLz4KPHBhdGggZD0iTTguOTY4OTUgMy40NjgwMkMxMC42NTMxIDMuNDY4MDIgMTEuNzg5MiA0LjE5NTUxIDEyLjQzNyA0LjgwMzQ2TDE0Ljk2ODIgMi4zMzE5NkMxMy40MTM2IDAuODg2OTQ3IDExLjM5MDYgMCA4Ljk2ODk1IDBDNS40NjEwMSAwIDIuNDMxNDQgMi4wMTMwNSAwLjk1NjU0MyA0Ljk0MjkyTDMuODU2NTQgNy4xOTUxOUM0LjU4NDA5IDUuMDMyNjUgNi41OTcxNCAzLjQ2ODAyIDguOTY4OTUgMy40NjgwMloiIGZpbGw9IiNFQjQzMzUiLz4KPC9zdmc+Cg==")',
                backgroundColor: 'white',
                backgroundRepeat: 'no-repeat',
                boxShadow: darkMode
                  ? 'rgba(255, 255, 255, 0.1) 0px 0px 16px 0px'
                  : 'rgba(0, 0, 0, 0.1) 0px 0px 16px 0px',
                cursor: 'pointer',
              }}
            />
            <Box
              onClick={() => handleSignInWithApple()}
              title="Sign in with Apple"
              sx={{
                width: '48px',
                height: '48px',
                borderRadius: '10px',
                color: 'white',
                backgroundPosition: 'center',
                backgroundImage:
                  'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjIwcHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDE3MCAxNzAiIHZlcnNpb249IjEuMSIgaGVpZ2h0PSIxNzBweCI+CiAgPHBhdGggZD0ibTE1MC4zNyAxMzAuMjVjLTIuNDUgNS42Ni01LjM1IDEwLjg3LTguNzEgMTUuNjYtNC41OCA2LjUzLTguMzMgMTEuMDUtMTEuMjIgMTMuNTYtNC40OCA0LjEyLTkuMjggNi4yMy0xNC40MiA2LjM1LTMuNjkgMC04LjE0LTEuMDUtMTMuMzItMy4xOC01LjE5Ny0yLjEyLTkuOTczLTMuMTctMTQuMzQtMy4xNy00LjU4IDAtOS40OTIgMS4wNS0xNC43NDYgMy4xNy01LjI2MiAyLjEzLTkuNTAxIDMuMjQtMTIuNzQyIDMuMzUtNC45MjkgMC4yMS05Ljg0Mi0xLjk2LTE0Ljc0Ni02LjUyLTMuMTMtMi43My03LjA0NS03LjQxLTExLjczNS0xNC4wNC01LjAzMi03LjA4LTkuMTY5LTE1LjI5LTEyLjQxLTI0LjY1LTMuNDcxLTEwLjExLTUuMjExLTE5LjktNS4yMTEtMjkuMzc4IDAtMTAuODU3IDIuMzQ2LTIwLjIyMSA3LjA0NS0yOC4wNjggMy42OTMtNi4zMDMgOC42MDYtMTEuMjc1IDE0Ljc1NS0xNC45MjVzMTIuNzkzLTUuNTEgMTkuOTQ4LTUuNjI5YzMuOTE1IDAgOS4wNDkgMS4yMTEgMTUuNDI5IDMuNTkxIDYuMzYyIDIuMzg4IDEwLjQ0NyAzLjU5OSAxMi4yMzggMy41OTkgMS4zMzkgMCA1Ljg3Ny0xLjQxNiAxMy41Ny00LjIzOSA3LjI3NS0yLjYxOCAxMy40MTUtMy43MDIgMTguNDQ1LTMuMjc1IDEzLjYzIDEuMSAyMy44NyA2LjQ3MyAzMC42OCAxNi4xNTMtMTIuMTkgNy4zODYtMTguMjIgMTcuNzMxLTE4LjEgMzEuMDAyIDAuMTEgMTAuMzM3IDMuODYgMTguOTM5IDExLjIzIDI1Ljc2OSAzLjM0IDMuMTcgNy4wNyA1LjYyIDExLjIyIDcuMzYtMC45IDIuNjEtMS44NSA1LjExLTIuODYgNy41MXptLTMxLjI2LTEyMy4wMWMwIDguMTAyMS0yLjk2IDE1LjY2Ny04Ljg2IDIyLjY2OS03LjEyIDguMzI0LTE1LjczMiAxMy4xMzQtMjUuMDcxIDEyLjM3NS0wLjExOS0wLjk3Mi0wLjE4OC0xLjk5NS0wLjE4OC0zLjA3IDAtNy43NzggMy4zODYtMTYuMTAyIDkuMzk5LTIyLjkwOCAzLjAwMi0zLjQ0NiA2LjgyLTYuMzExMyAxMS40NS04LjU5NyA0LjYyLTIuMjUxNiA4Ljk5LTMuNDk2OCAxMy4xLTMuNzEgMC4xMiAxLjA4MzEgMC4xNyAyLjE2NjMgMC4xNyAzLjI0MDl6IiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPgo=")',
                backgroundColor: darkMode ? '#222' : 'black',
                backgroundRepeat: 'no-repeat',
                boxShadow: darkMode
                  ? 'rgba(255, 255, 255, 0.1) 0px 0px 16px 0px'
                  : 'rgba(0, 0, 0, 0.1) 0px 0px 16px 0px',
                cursor: 'pointer',
              }}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default SignInPage;
