import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  InputBase,
  Stack,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { UICtx } from '../UIProvider';
import { AutoCompletePrediction, autocompletePlace } from '../API';
import { CopySmall } from './Typography';
import { DataCtx } from '../DataProvider';

interface ChatInputFieldProps {
  initialPrediction?: AutoCompletePrediction;
  placeholder?: string;
  inputText?: string;
  onPlaceSelected?: (place: AutoCompletePrediction) => void;
  onPredictionSelected?: (prediction: AutoCompletePrediction) => void;
  disabled?: boolean;
  height?: string;
  fontSize?: string;
  maxWidth?: string;
}

const ChatInputField: FC<ChatInputFieldProps> = ({
  initialPrediction,
  placeholder = 'Enter your destination',
  inputText,
  onPlaceSelected,
  onPredictionSelected,
  disabled,
  height,
  fontSize,
  maxWidth,
}) => {
  const { darkMode, isMobile } = useContext(UICtx);
  const { setSelectedPrediction } = useContext(DataCtx);
  const [localInputState, setLocalInputState] = useState('');

  const [lastInputTime, setLastInputTime] = useState(0);
  const [lastInputTimeout, setLastInputTimeout] =
    useState<NodeJS.Timeout | null>(null);

  const [predictions, setPredictions] = useState<AutoCompletePrediction[]>([]);
  const [predictionsOpen, setPredictionsOpen] = useState(false);
  const [prediction, setPrediction] = useState(initialPrediction);

  const fetchPlaces = async (input: string) => {
    if (input.length < 3) {
      setPredictions([]);
      return;
    }
    const { result } = await autocompletePlace(input);
    setPredictions(result);
    setPredictionsOpen(true);
  };

  const handleSubmit = () => {
    if (prediction) {
      setSelectedPrediction(prediction);
      if (onPlaceSelected) {
        onPlaceSelected(prediction);
      }
    }
  };

  // Handle enter key press
  const handleKeyPress = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && onPlaceSelected) {
      handleSubmit();
    }
  };

  const handleTextChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newVal = event.target.value;
    setLocalInputState(newVal);

    // Check if last input was less than 500ms ago
    if (Date.now() - lastInputTime < 500) {
      // Clear timeout
      if (lastInputTimeout) {
        clearTimeout(lastInputTimeout);
      }
    }
    // Set new timeout
    const newTimeout = setTimeout(async () => {
      await fetchPlaces(newVal);
    }, 500);

    setLastInputTimeout(newTimeout);

    // Set last input time
    setLastInputTime(Date.now());
  };

  useEffect(() => {
    if (inputText) {
      setLocalInputState(inputText);
    }
  }, [inputText]);

  useEffect(() => {
    if (initialPrediction) {
      setLocalInputState(initialPrediction.main_text);
      setPrediction(initialPrediction);
    }
  }, [initialPrediction]);

  return (
    <ClickAwayListener onClickAway={() => setPredictionsOpen(false)}>
      <Box
        sx={{
          display: 'flex',
          background: darkMode ? 'transparent' : 'rgb(244, 245, 251)',
          borderRadius: '40px',
          p: 1,
          pr: 1,
          width: '100%',
          minWidth: isMobile ? undefined : '300px',
          maxWidth: maxWidth,
          position: 'relative',
          height: height || '70px',
        }}
      >
        <InputBase
          onChange={handleTextChange}
          // Listen for enter key press
          onKeyPress={handleKeyPress}
          onFocus={() => {
            if (predictions.length > 0) {
              setPredictionsOpen(true);
            }
          }}
          disabled={disabled}
          value={localInputState}
          placeholder={placeholder}
          sx={{
            color: darkMode ? '#DDD' : 'black',
            pl: '10px',
            pr: 0,
            fontSize: fontSize || (isMobile ? '1.2rem' : '1.5rem'),
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            '.MuiInputBase-input:disabled': {
              // borderBottom: darkMode ? '2px solid #CCC' : undefined,
            },
            '.MuiInputBase-input::placeholder': {
              fontSize: fontSize || '1rem',
              lineHeight: fontSize || '1rem',
            },
            // https://www.w3docs.com/snippets/css/how-to-auto-hide-placeholder-text-on-focus-with-css-and-jquery.html
            // Trick to hide placeholder text when input is focused
            '.MuiInputBase-input:focus::placeholder': {
              color: 'transparent',
              fontSize: fontSize || '1rem',
            },
            borderBottom: !darkMode
              ? undefined
              : disabled
                ? '2px solid #444'
                : '2px solid #CCC',
            position: 'absolute',
            top: 5,
            left: 20,
            right: 20,
            bottom: 5,
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleSubmit}
                sx={{
                  color: disabled ? '#444' : '#B1B1A1',
                  p: '2px',
                  mt: '2px',
                }}
                disableRipple
              >
                <SearchIcon
                  sx={{
                    fontSize: '1.5rem',
                  }}
                />
              </IconButton>
            </InputAdornment>
          }
        />
        {predictionsOpen && (
          <Stack
            sx={{
              top: '100%',
              right: 20,
              left: 20,
              position: 'absolute',
            }}
            spacing={'2px'}
          >
            {predictions.map((prediction, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    background: darkMode ? '#222' : '#fff',
                    zIndex: 100,
                    borderRadius: '10px',
                    p: 1,
                    maxHeight: '200px',
                    overflowY: 'auto',
                    border: darkMode ? undefined : '1px solid #EEE',
                    '&:hover': {
                      cursor: 'pointer',
                      background: darkMode ? '#444' : '#DDD',
                    },
                  }}
                  onClick={() => {
                    setPrediction(prediction);
                    setLocalInputState(prediction.main_text);
                    setPredictionsOpen(false);
                    if (onPredictionSelected) {
                      onPredictionSelected(prediction);
                    }
                  }}
                >
                  <CopySmall>
                    {prediction.main_text}, {prediction.secondary_text}
                  </CopySmall>
                </Box>
              );
            })}
          </Stack>
        )}
      </Box>
    </ClickAwayListener>
  );
};
export default ChatInputField;
