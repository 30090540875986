import { Button, ButtonProps } from '@mui/material';
import { FC, useContext } from 'react';
import { UICtx } from '../../UIProvider';

interface GetStartedButtonProps extends ButtonProps {
  children?: React.ReactNode;
}

const PoICreateButton: FC<GetStartedButtonProps> = props => {
  const { darkMode } = useContext(UICtx);

  return (
    <Button
      {...props}
      sx={{
        background: darkMode ? '#DEDEDE' : 'rgb(6, 3, 24)',
        color: darkMode ? '#0d0c22' : 'white',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        textTransform: 'none',
        borderRadius: '14px',
        py: 1,
        pr: 1,
        height: '40px',
        width: '100px',
        fontSize: '0.8rem',
        fontWeight: 700,
        '&:hover': {
          background: darkMode ? '#E1E1E1' : '#0d0c22',
          color: darkMode ? '#0d0c22' : 'white',
        },
        ...props.sx,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export default PoICreateButton;
