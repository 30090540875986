import { FC, useContext } from 'react';
import { Stack, StackProps } from '@mui/material';
import { UICtx } from '../../UIProvider';

const StyledBarItem: FC<StackProps> = props => {
  const { darkMode } = useContext(UICtx);

  const getBackgroundColor = (isHovered: boolean = false) => {
    if (isHovered) {
      return darkMode ? '#222' : '#f2f2f2';
    }
    return darkMode ? '#111' : 'white';
  };

  return (
    <Stack
      direction={'row'}
      {...props}
      sx={{
        p: '4px',
        background: getBackgroundColor(),
        borderRadius: '16px',
        ':hover': {
          background: getBackgroundColor(true),
        },
        ...props.sx,
      }}
    >
      {props.children}
    </Stack>
  );
};

export default StyledBarItem;
