import { FC, useContext } from 'react';
import { ButtonProps, IconButton } from '@mui/material';
import MemoryIcon from '@mui/icons-material/Memory';
import { UICtx } from '../../UIProvider';

const MiniGenerateButton: FC<ButtonProps> = props => {
  const { darkMode } = useContext(UICtx);
  return (
    <IconButton
      {...props}
      sx={{
        p: 0,
        color: darkMode ? '#999' : '#000',
        '&:hover': {
          color: darkMode ? '#fff' : '#000',
        },
        ...props.sx,
      }}
    >
      <MemoryIcon
        sx={{
          fontSize: '1.3rem',
        }}
      />
    </IconButton>
  );
};

export default MiniGenerateButton;
