import { useContext } from 'react';
import { Stack } from '@mui/material';
import { UICtx } from '../../UIProvider';
import NavigationBar from '../../components/Navigation/NavigationBar';

const BuilderPage = () => {
  const { darkMode } = useContext(UICtx);

  return (
    <Stack sx={{ flex: 1, background: darkMode ? '#121212' : '#fff' }}>
      <NavigationBar displayDarkModeSwitch />
    </Stack>
  );
};

export default BuilderPage;
