import { FC, useContext, useEffect, useState } from 'react';
import { onValue, ref } from 'firebase/database';
import { IconButton, Menu, MenuItem, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UICtx } from '../../UIProvider';
import { PoI, TripPoI, TripStop } from '../../ifaces';
import { CopyBold } from '../Typography';
import { database } from '../../Firebase';
import PoIsList from '../PoIsList/PoIsList';
import { DataCtx } from '../../DataProvider';

interface TripStopItemProps {
  tripStop: TripStop;
  onPoISelected?: (poi: PoI) => void;
  onTripPoISelected?: (poi: TripPoI) => void;
  onDeleteTripStop?: () => void;
}

type TripStopRealtimeDBState = {
  PoIs: PoI[];
};

const TripStopItem: FC<TripStopItemProps> = ({
  tripStop,
  onPoISelected,
  onDeleteTripStop,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [pointsOfInterest, setPointsOfInterest] = useState<PoI[]>([]);
  const { darkMode } = useContext(UICtx);
  const { setMarkers } = useContext(DataCtx);

  useEffect(() => {
    setMarkers(prevMarkers => {
      // Return the markers by making sure to remove markers
      // which have group === tripStop.destination and are not in the
      // pointsOfInterest array

      // First, remove all markers which have group === tripStop.trip_id
      const newMarkers = prevMarkers.filter(marker => {
        return marker.group !== tripStop.destination;
      });

      // Then, add the new markers
      const newMarkers2 = newMarkers.concat(
        pointsOfInterest.map((poi, index) => {
          return {
            id: poi.placeId,
            lat: poi.location.latitude,
            lng: poi.location.longitude,
            group: tripStop.destination,
          };
        }),
      );

      return newMarkers2;
    });
  }, [tripStop.destination, setMarkers, pointsOfInterest]);

  useEffect(() => {
    // Then subscribe to the trip's information on the realtime database
    const refPath = `trips/${tripStop.trip_id}/stops/${tripStop.id}`;
    const userTravelRef = ref(database, refPath);
    console.log(refPath);
    const unsub = onValue(userTravelRef, snapshot => {
      try {
        const tripStopData = snapshot.val() as TripStopRealtimeDBState;
        if (tripStopData) {
          console.log(tripStopData);
          setPointsOfInterest(tripStopData.PoIs);
        }
      } catch (error) {
        console.log('Something failed');
        console.log(error);
      }
    });

    // Return the unsubscribe function, to detach the listener
    // when the component unmounts
    return () => {
      console.log('Unsubscribing from trip stop: ' + tripStop.id);
      unsub();
    };
  }, [tripStop]);

  return (
    <Stack
      sx={{
        p: 1,
        background: darkMode ? '#121212' : '#f2f2f2',
        borderRadius: '16px',
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          // background: getBackgroundColor(),
          height: '80px',
          // width: '80px',
          borderRadius: 2,
          alignItems: 'center',
        }}
      >
        <Stack
          sx={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CopyBold>{tripStop.destination}</CopyBold>
        </Stack>
        <IconButton
          sx={{
            color: darkMode ? 'white' : 'black',
            p: 0,
            // height: '20px',
          }}
          disableRipple
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={onDeleteTripStop}>Remove Stop</MenuItem>
        </Menu>
      </Stack>

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                             PoIs List                          //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <PoIsList pois={pointsOfInterest} onPoISelected={onPoISelected} />

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                        Day Itineraries List                    //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {/* {tripStop.day_itineraries.map((dayItinerary, index) => {
        return <DayItinerary key={'day-itinerary-' + index} itinerary={dayItinerary} onPoISelected={onPoISelected} />;
      })} */}
    </Stack>
  );
};

export default TripStopItem;
