import { Stack } from '@mui/material';
import { Copy } from '../../components/Typography';
import { FC } from 'react';

interface LoadingViewProps {
  withMessage?: string;
}

const LoadingView: FC<LoadingViewProps> = ({ withMessage }) => {
  return (
    <Stack
      sx={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Copy>{withMessage || 'Loading...'}</Copy>
    </Stack>
  );
};
export default LoadingView;
