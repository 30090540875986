import { FC, useContext, useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { UICtx } from '../../UIProvider';
import { TripStop } from '../../ifaces';
import AITravelAssistantMap from '../AITravelAssistantMap';
import { DataCtx } from '../../DataProvider';
import TripStopItem from '../TripStopItem/TripStopItem';
import PlaceSelectionModal from '../PlaceSelectionModal/PlaceSelectionModal';
import { addTripStop, deleteTripStop } from '../../API';

interface TripStopsListWithMapProps {
  // onSubmitPoIsPreferences?: (PoIs_to_skip: PoI[], PoIs_to_include: PoI[]) => void;
}

const TripStopsListWithMap: FC<TripStopsListWithMapProps> = () => {
  // Modal State
  const [placeSelectionModalOpen, setPlaceSelectionModalOpen] = useState(false);

  // Map control state
  const { selectedTrip, tripStops, fetchTripStops, markers } =
    useContext(DataCtx);
  const { darkMode, isMobile } = useContext(UICtx);
  const [mapVisible, setMapVisible] = useState(!isMobile);

  useEffect(() => {
    setMapVisible(!isMobile);
  }, [isMobile]);

  const handleCreateStop = async (place_id: string, duration: number) => {
    console.log('handleCreateStop');
    if (selectedTrip) {
      await addTripStop(selectedTrip.id, {
        destination: place_id,
        duration: duration,
      });
      fetchTripStops(selectedTrip.id);
    }
  };

  const handleDeleteStop = async (tripStop: TripStop) => {
    if (selectedTrip) {
      await deleteTripStop(selectedTrip.id, tripStop.id);
    }
  };

  return (
    <Stack
      direction={'row'}
      sx={{
        flex: 1,
        width: '100%',
        overflow: 'hidden',
        background: darkMode ? '#040404' : '#fff',
      }}
    >
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                              DEBUGGING                         //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {/* {process.env.REACT_APP_DEBUG === 'true' && <DebugPanel message={selectedTrip?.id} />} */}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                        Place Selection Modal                   //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <PlaceSelectionModal
        open={placeSelectionModalOpen}
        setOpen={() => setPlaceSelectionModalOpen(false)}
        onConfirm={handleCreateStop}
      />

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                             Stops List                         //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <Stack
        sx={{
          flex: 1,
          position: 'relative',
          background: darkMode ? '#040404' : '#fff',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '0.4em',
          },
          '&::-webkit-scrollbar-track': {
            WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,100,.1)',
            borderRadius: '10px',
          },
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 10,
            right: 10,
            left: 10,
            bottom: 20,
          }}
          spacing={1}
        >
          {tripStops.map((tripStop, index) => (
            <>
              {/* //////////////////////////////////////////////////////////////// */}
              {/* ////                        Stop Details                    //// */}
              {/* //////////////////////////////////////////////////////////////// */}

              <TripStopItem
                key={'trip-stop-' + index}
                tripStop={tripStop}
                onDeleteTripStop={() => handleDeleteStop(tripStop)}
              />

              {/* //////////////////////////////////////////////////////////////// */}
              {/* ////                      Add Stop Button                   //// */}
              {/* //////////////////////////////////////////////////////////////// */}

              {index === tripStops.length - 1 && (
                <Button
                  sx={{
                    background: darkMode ? '#646464' : '#fff',
                    color: darkMode ? '#fff' : '#040404',
                    width: '100%',
                    mb: 2,
                    '&:hover': {
                      background: darkMode ? '#333' : '#FAFAFA',
                      color: darkMode ? 'white' : '#0d0c22',
                    },
                  }}
                  onClick={() => setPlaceSelectionModalOpen(true)}
                >
                  Add Stop
                </Button>
              )}
            </>
          ))}
        </Stack>
      </Stack>

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                                 MAP                            //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {!isMobile ? (
        mapVisible ? (
          <AITravelAssistantMap
            // mapViewState={{
            //   latitude: currentCoordinates?.lat || 0,
            //   longitude: currentCoordinates?.lng || 0,
            //   zoom: selectedZoom,
            // }}
            markers={markers}
          />
        ) : (
          <Stack sx={{ flex: 1 }}>Loading</Stack>
        )
      ) : null}
    </Stack>
  );
};

export default TripStopsListWithMap;
