import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import { FC, forwardRef, useContext } from 'react';
import { FixedColors } from '../theme';
import { UICtx } from '../UIProvider';

interface CustomTypographyProps extends TypographyProps {
  isMobile?: boolean;
  multiline?: boolean;
}

export const FONT_SIZES = {
  bigTitle: { mobile: '42px', desktop: '96px', rawMobile: 42, rawDesktop: 96 },
  header1: { mobile: '40px', desktop: '48px', rawMobile: 40, rawDesktop: 48 },
  header2: { mobile: '32px', desktop: '32px', rawMobile: 32, rawDesktop: 32 },
  header3: { mobile: '28px', desktop: '32px', rawMobile: 28, rawDesktop: 32 },
  copy: { mobile: '16px', desktop: '18px', rawMobile: 16, rawDesktop: 18 },
  copyBold: { mobile: '16px', desktop: '18px', rawMobile: 16, rawDesktop: 18 },
  copySmall: { mobile: '12px', desktop: '12px', rawMobile: 12, rawDesktop: 12 },
  copySmallBold: {
    mobile: '12px',
    desktop: '12px',
    rawMobile: 12,
    rawDesktop: 12,
  },
  quote: { mobile: '24px', desktop: '36px', rawMobile: 24, rawDesktop: 36 },
  quoteBold: { mobile: '24px', desktop: '36px', rawMobile: 24, rawDesktop: 36 },
};

export const FONT_WEIGHTS = {
  bigTitle: 600,
  header1: 600,
  header2: 600,
  header3: 400,
  copy: 400,
  copyBold: 600,
  copySmall: 400,
  copySmallBold: 600,
  quote: 400,
  quoteBold: 600,
};

export const MAIN_FONT = "'Plus Jakarta Sans', sans-serif";

export const getFontSize = (
  type: keyof typeof FONT_SIZES,
  isMobile: boolean,
): string => (isMobile ? FONT_SIZES[type].mobile : FONT_SIZES[type].desktop);

export const getRawFontSize = (
  type: keyof typeof FONT_SIZES,
  isMobile: boolean,
): number =>
  isMobile ? FONT_SIZES[type].rawMobile : FONT_SIZES[type].rawDesktop;

export const getFontWeight = (type: keyof typeof FONT_WEIGHTS): number =>
  FONT_WEIGHTS[type];

export const BigTitle: FC<CustomTypographyProps> = ({
  children,
  sx,
  onClick,
  multiline,
}) => {
  const { darkMode, isMobile } = useContext(UICtx);

  return (
    <Typography
      component={'h1'}
      onClick={onClick}
      sx={{
        fontFamily: MAIN_FONT,
        lineHeight: isMobile ? '46px' : '88px',
        color: !darkMode ? FixedColors.almostBlack : 'white',
        fontSize: getFontSize('bigTitle', isMobile),
        fontWeight: getFontWeight('bigTitle'),
        whiteSpace: multiline ? 'pre-wrap' : undefined,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

BigTitle.displayName = 'BigTitle';

export const Header1: FC<CustomTypographyProps> = ({
  children,
  sx,
  onClick,
  multiline,
}) => {
  const { darkMode, isMobile } = useContext(UICtx);
  return (
    <Typography
      component={'h1'}
      onClick={onClick}
      sx={{
        fontFamily: MAIN_FONT,
        lineHeight: isMobile ? '46px' : '56px',
        color: !darkMode ? FixedColors.almostBlack : 'white',
        fontSize: getFontSize('header1', isMobile),
        fontWeight: getFontWeight('header1'),
        whiteSpace: multiline ? 'pre-wrap' : undefined,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

Header1.displayName = 'Header1';

export const Header2: FC<CustomTypographyProps> = ({
  children,
  sx,
  onClick,
  multiline,
}) => {
  const { darkMode, isMobile } = useContext(UICtx);
  return (
    <Typography
      component={'h2'}
      onClick={onClick}
      sx={{
        fontFamily: MAIN_FONT,
        lineHeight: isMobile ? '38px' : '38px',
        color: !darkMode ? FixedColors.almostBlack : 'white',
        fontSize: getFontSize('header2', isMobile),
        fontWeight: getFontWeight('header2'),
        whiteSpace: multiline ? 'pre-wrap' : undefined,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

Header2.displayName = 'Header2';

export const Header3: FC<CustomTypographyProps> = ({
  children,
  sx,
  onClick,
  multiline,
}) => {
  const { darkMode, isMobile } = useContext(UICtx);
  return (
    <Typography
      component={'h3'}
      onClick={onClick}
      sx={{
        fontFamily: MAIN_FONT,
        lineHeight: isMobile ? '38px' : '40px',
        color: !darkMode ? FixedColors.almostBlack : 'white',
        fontSize: getFontSize('header3', isMobile),
        fontWeight: getFontWeight('header3'),
        whiteSpace: multiline ? 'pre-wrap' : undefined,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

Header3.displayName = 'Header3';

export const Copy = forwardRef<HTMLDivElement, CustomTypographyProps>(
  ({ children, sx, onClick, multiline, ...otherProps }, ref) => {
    const { darkMode, isMobile } = useContext(UICtx);
    return (
      <Typography
        ref={ref} // Forward the ref to the Typography component
        component={'p'}
        onClick={onClick}
        sx={{
          fontFamily: MAIN_FONT,
          lineHeight: isMobile ? '26px' : '28px',
          color: !darkMode ? FixedColors.almostBlack : 'white',
          fontSize: getFontSize('copy', isMobile),
          fontWeight: getFontWeight('copy'),
          whiteSpace: multiline ? 'pre-wrap' : undefined,
          ...sx,
        }}
        {...otherProps}
      >
        {children}
      </Typography>
    );
  },
);

Copy.displayName = 'Copy';

export const CopyBold = forwardRef<HTMLDivElement, CustomTypographyProps>(
  ({ children, sx, onClick, multiline, ...otherProps }, ref) => {
    const { darkMode, isMobile } = useContext(UICtx);
    return (
      <Typography
        ref={ref} // Forward the ref to the Typography component
        component={'p'}
        onClick={onClick}
        sx={{
          fontFamily: MAIN_FONT,
          lineHeight: '26px',
          color: !darkMode ? FixedColors.almostBlack : 'white',
          fontSize: getFontSize('copyBold', isMobile),
          fontWeight: getFontWeight('copyBold'),
          whiteSpace: multiline ? 'pre-wrap' : undefined,
          ...sx,
        }}
        {...otherProps}
      >
        {children}
      </Typography>
    );
  },
);

CopyBold.displayName = 'CopyBold';

export const CopySmall = forwardRef<HTMLDivElement, CustomTypographyProps>(
  ({ children, sx, onClick, multiline, ...otherProps }, ref) => {
    const { darkMode, isMobile } = useContext(UICtx);
    return (
      <Typography
        ref={ref} // Forward the ref to the Typography component
        component={'p'}
        sx={{
          fontFamily: MAIN_FONT,
          lineHeight: '22px',
          color: !darkMode ? FixedColors.almostBlack : 'white',
          fontSize: getFontSize('copySmall', isMobile),
          fontWeight: getFontWeight('copySmall'),
          whiteSpace: multiline ? 'pre-wrap' : undefined,
          ...sx,
        }}
        onClick={onClick}
        {...otherProps}
      >
        {children}
      </Typography>
    );
  },
);

CopySmall.displayName = 'CopySmall';

export const CopySmallBold = forwardRef<HTMLDivElement, CustomTypographyProps>(
  ({ children, sx, onClick, multiline, ...otherProps }, ref) => {
    const { darkMode, isMobile } = useContext(UICtx);
    return (
      <Typography
        ref={ref} // Forward the ref to the Typography component
        component={'p'}
        sx={{
          fontFamily: MAIN_FONT,
          lineHeight: isMobile ? '22px' : '20px',
          color: !darkMode ? FixedColors.almostBlack : 'white',
          fontSize: getFontSize('copySmallBold', isMobile),
          fontWeight: getFontWeight('copySmallBold'),
          whiteSpace: multiline ? 'pre-wrap' : undefined,
          ...sx,
        }}
        onClick={onClick}
        {...otherProps}
      >
        {children}
      </Typography>
    );
  },
);

CopySmallBold.displayName = 'CopySmallBold';

export const QuoteBold = forwardRef<HTMLDivElement, CustomTypographyProps>(
  ({ children, sx, onClick, multiline, ...otherProps }, ref) => {
    const { darkMode, isMobile } = useContext(UICtx);
    return (
      <Typography
        ref={ref} // Forward the ref to the Typography component
        component={'p'}
        sx={{
          fontFamily: MAIN_FONT,
          lineHeight: isMobile ? '30px' : '46px',
          color: !darkMode ? FixedColors.almostBlack : 'white',
          fontSize: getFontSize('quoteBold', isMobile),
          fontWeight: getFontWeight('quoteBold'),
          whiteSpace: multiline ? 'pre-wrap' : undefined,
          ...sx,
        }}
        onClick={onClick}
        {...otherProps}
      >
        {children}
      </Typography>
    );
  },
);

QuoteBold.displayName = 'QuoteBold';

export const Quote: FC<CustomTypographyProps> = ({
  children,
  sx,
  multiline,
}) => {
  const { darkMode, isMobile } = useContext(UICtx);
  return (
    <Typography
      component={'p'}
      sx={{
        fontFamily: MAIN_FONT,
        lineHeight: isMobile ? '30px' : '46px',
        color: !darkMode ? FixedColors.almostBlack : 'white',
        fontSize: getFontSize('quote', isMobile),
        fontWeight: getFontWeight('quote'),
        whiteSpace: multiline ? 'pre-wrap' : undefined,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

Quote.displayName = 'Quote';

export const ExtraBoldItalic: FC<CustomTypographyProps> = ({
  children,
  sx,
  multiline,
}) => {
  const { darkMode } = useContext(UICtx);
  return (
    <Typography
      component={'p'}
      sx={{
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 900,
        fontStyle: 'italic',
        lineHeight: '1rem',
        fontSize: '1rem',
        color: !darkMode ? FixedColors.almostBlack : 'white',
        whiteSpace: multiline ? 'pre-wrap' : undefined,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

ExtraBoldItalic.displayName = 'ExtraBoldItalic';

export const MediumItalic: FC<CustomTypographyProps> = ({
  children,
  sx,
  multiline,
}) => {
  const { darkMode } = useContext(UICtx);
  return (
    <Typography
      component={'p'}
      sx={{
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 500,
        fontStyle: 'italic',
        lineHeight: '1rem',
        fontSize: '1rem',
        color: !darkMode ? FixedColors.almostBlack : 'white',
        whiteSpace: multiline ? 'pre-wrap' : undefined,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

MediumItalic.displayName = 'MediumItalic';
