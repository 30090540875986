import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { UICtx } from '../../UIProvider';
import { CustomNotification } from '../../ifaces';

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

export default function NotificationManager() {
  const { notifications, removeNotification } = useContext(UICtx);

  const [notificationItems, setNotificationItems] = useState<
    Array<CustomNotification>
  >([]);

  useEffect(() => {
    setNotificationItems(notifications);
  }, [notifications]);

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      {notificationItems.map(notification => (
        <Snackbar
          key={`notification-${notification.id}`}
          open={true}
          autoHideDuration={5000}
          onClose={() =>
            removeNotification(notification.id ?? 'SnackbarFallbackID')
          }
        >
          <Alert
            id={notification.id + ''}
            key={`notification-${notification.id}`}
            onClose={() =>
              removeNotification(notification.id ?? 'SnackbarFallbackID')
            }
            severity={notification.type}
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </Stack>
  );
}
