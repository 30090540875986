import { FC, useContext, useState } from 'react';
import { Link, Stack } from '@mui/material';
import { UICtx } from '../../UIProvider';
import { City, PoICandidate } from '../../ifaces';
import { MediumItalic } from '../Typography';
import StyledBarItem from '../containers/StyledBarItem';
import MyLocationButton from '../buttons/MyLocationButton';

interface PoICandidateItemProps {
  candidate: PoICandidate;
  onSelect?: () => void;
  onCenterOnMap?: () => void;
  onDeletePoICandidate?: () => void;
  city?: City;
}

const PoICandidateItem: FC<PoICandidateItemProps> = ({
  candidate,
  onSelect,
  onCenterOnMap,
  onDeletePoICandidate,
  city,
}) => {
  const { darkMode } = useContext(UICtx);

  return (
    <StyledBarItem
      direction={'row'}
      sx={{
        p: 1,
        alignItems: 'center',
      }}
    >
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'flex-start',
          flex: 1,
          px: 1,
        }}
      >
        <Stack
          direction={'row'}
          sx={{
            alignItems: 'center',
          }}
          spacing={'4px'}
        >
          <MediumItalic
            onClick={onSelect}
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
              fontSize: '0.9rem',
            }}
          >
            {candidate.name}
          </MediumItalic>
        </Stack>
        <Link
          href={`https://www.google.com/maps/place/?q=place_id:${candidate.placeId}`}
          target="_blank"
          rel="noreferrer"
          sx={{
            color: darkMode ? '#fff' : '#000',
            fontSize: '0.7rem',
          }}
        >
          Open in Google Maps
        </Link>
      </Stack>
      <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={'4px'}>
        <MyLocationButton onClick={onCenterOnMap} />
      </Stack>
    </StyledBarItem>
  );
};

export default PoICandidateItem;
