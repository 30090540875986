import { FC, useState } from 'react';
import { Tour } from '../../ifaces';
import ToursListItem from './ToursListItem';
import ListContainer from '../containers/ListContainer';

interface ToursListProps {
  tours: Tour[];
  onTourSelected?: (tour: Tour) => void;
}

const ToursList: FC<ToursListProps> = ({ tours, onTourSelected }) => {

  // Selected Item
  const [selected, setSelected] = useState<Tour>();

  const handleSelect = (tour: Tour) => {
    setSelected(tour);
    onTourSelected?.(tour);
  };

  return (
    <ListContainer spacing={1}>
      {tours.map((tour, index) => {
        return (
          <ToursListItem
            key={index}
            tour={tour}
            onSelect={() => handleSelect(tour)}
            isSelected={tour.id === selected?.id}
          />
        );
      })}
    </ListContainer>
  );
};

export default ToursList;
