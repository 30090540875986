import { useContext } from 'react';
import MainLayout from '../MainLayout';
import ToursListWithMap from '../../components/ToursList/ToursListWithMap';
import { DataCtx } from '../../DataProvider';
import NavigationBar from '../../components/Navigation/NavigationBar';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import { Stack } from '@mui/material';

const ToursPage = () => {
  const { tours } = useContext(DataCtx);

  return (
    <MainLayout>
      <NavigationBar displayDarkModeSwitch withSearchBar />
      <Stack direction={'row'} flex={1}>
        <CustomDrawer />
        <ToursListWithMap tours={tours} />
      </Stack>
    </MainLayout>
  );
};

export default ToursPage;
