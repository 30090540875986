import { FC, useState } from 'react';
import { City } from '../../ifaces';
import MiniCircleAddButton from '../../components/buttons/MiniCircleAddButton';
import CityCreationModal from './CityCreationModal';

interface WidgetProps {
  onCityCreated?: (city: City) => void;
}

const CityCreationWidget: FC<WidgetProps> = ({ onCityCreated }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                        TOUR CREATION MODAL                     //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <CityCreationModal
        open={modalOpen}
        setOpen={setModalOpen}
        onCityCreated={onCityCreated}
      />

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                        TOUR CREATE BUTTON                      //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <MiniCircleAddButton onClick={() => setModalOpen(true)} />
    </>
  );
};

export default CityCreationWidget;
