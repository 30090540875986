import { FC } from 'react';
import { Stack } from '@mui/material';
import { TourTemplate } from '../../ifaces';
import { CopySmallBold } from '../Typography';
import StyledBarItem from '../containers/StyledBarItem';
import MyLocationButton from '../buttons/MyLocationButton';

interface TemplateItemProps {
  template: TourTemplate;
  locked?: boolean;
  isSelected?: boolean;
  onSelect: () => void;
  onOpenDetails?: () => void;
  onCenterOnMap?: () => void;
}

const TemplatesListItem: FC<TemplateItemProps> = ({
  template,
  onOpenDetails,
  onCenterOnMap,
}) => {
  return (
    <StyledBarItem
      sx={{
        p: 1,
        pl: 2,
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <CopySmallBold
          sx={{
            ':hover': {
              cursor: 'pointer',
              textDecoration: 'underline',
            },
          }}
          onClick={onOpenDetails}
        >
          {template.id}
        </CopySmallBold>
        <MyLocationButton onClick={onCenterOnMap} />
      </Stack>
    </StyledBarItem>
  );
};

export default TemplatesListItem;
