import { FC, useContext, useEffect, useState } from 'react';
import { Button, IconButton, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { CopyBold, CopySmall, CopySmallBold } from '../Typography';
import { UICtx } from '../../UIProvider';
import { PoI, PoIDescription, PoISource, PoITranscript } from '../../ifaces';
import PoITranscriptList from '../TranscriptList/PoITranscriptList';
import SourcesList from '../SourcesList/SourcesList';
import ListContainer from '../containers/ListContainer';
import PoIImage from './PoIImage';
import PoIDescriptions from '../DescriptionsList/DescriptionsList';
import StyledStack from '../containers/StyledStack';
import { EditableCopySmall } from '../TypographyEditable';
import { approvePoI, refetchPoIDetails, updatePoI } from '../../API';
import OpenInNewButton from '../buttons/OpenInNewButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px',
  p: 3,
};

interface ComponentProps {
  poi: PoI;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onPoIApproved?: () => void;
  onConfirm?: () => void;
}

const PoIDetailsModal: FC<ComponentProps> = ({
  poi,
  open,
  setOpen,
  onPoIApproved,
}) => {
  const { darkMode, windowSize, addNotification } = useContext(UICtx);

  // UI State
  const [localLoading, setLocalLoading] = useState(false);

  // PoI Sub Components
  const [descriptions, setDescriptions] = useState<PoIDescription[]>([]);
  const [poiTranscripts, setPoITranscripts] = useState<PoITranscript[]>([]);
  const [sources, setSources] = useState<PoISource[]>([]);

  const [completeness, setCompleteness] = useState(0);
  const [missingInformation, setMissingInformation] = useState<string[]>([]);

  const clear = () => {};

  const handleClose = () => {
    setOpen(false);
    setTimeout(clear, 500); // Delay due to closing animation
  };

  useEffect(() => {
    const calculateCompleteness = () => {
      const completionData: Record<string, boolean> = {
        'PoI Name': poi.name !== '',
        // 'PoI Image': poi.imageName !== undefined && poi.imageName !== null,
        // 'PoI Image Source':
        //   poi.imageSource !== undefined && poi.imageSource !== null,
        // 'PoI Description': descriptions.length > 0,
        'PoI Sources': sources.length > 0,
        // 'PoI Transcripts': poiTranscripts.length > 0,
        'PoI Estimated Popularity':
          poi.estimatedPopularity !== undefined &&
          poi.estimatedPopularity !== null,
        'PoI Country': poi.country !== undefined && poi.country !== null,
        'PoI Country Short':
          poi.countryShort !== undefined && poi.countryShort !== null,
      };

      // Calculate percentage based on how many trues are in the completionData object
      const total = Object.keys(completionData).length;
      const completed = Object.values(completionData).filter(v => v).length;
      let percentage = (completed / total) * 100;

      // Round to 0 decimal places
      percentage = Math.round(percentage);

      // Set Missing information wherever the value is false
      const missing = Object.keys(completionData).filter(
        key => !completionData[key],
      );

      setMissingInformation(missing);
      setCompleteness(percentage);
    };
    calculateCompleteness();
  }, [poi, descriptions, poiTranscripts, sources]);

  const handleUpdateImageSource = async (newSource: string) => {
    await updatePoI(poi.id, { imageSource: newSource });
  };

  const handleApprovePoI = async () => {
    if (completeness < 100) {
      addNotification({
        type: 'error',
        message: 'Information incomplete',
      });
    } else {
      try {
        setLocalLoading(true);
        await approvePoI(poi.id);
        addNotification({
          type: 'success',
          message: 'PoI Approved',
        });
        handleClose();
        onPoIApproved?.();
      } catch (error) {
        addNotification({
          type: 'error',
          message: `${error}`,
        });
      } finally {
        setLocalLoading(false);
      }
    }
  };

  const handleReFetchDetails = async () => {
    try {
      if (localLoading) return;
      setLocalLoading(true);
      await refetchPoIDetails(poi.id);
      addNotification({
        type: 'success',
        message: 'PoI Details Updated',
      });
    } catch (error) {
      addNotification({
        type: 'error',
        message: `${error}`,
      });
    } finally {
      setLocalLoading(false);
    }
  };

  const handleOpenPoI = () => {
    window.open(
      `https://www.google.com/maps/place/?q=place_id:${poi.placeId}`,
      '_blank',
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={{ ...style, background: darkMode ? '#161616' : '#fff' }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 20,
              right: 20,
              color: darkMode ? '#999' : '#000',
              '&:hover': {
                color: darkMode ? '#fff' : '#000',
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Stack
            sx={{
              flex: 1,
              justifyContent: 'center',
              minHeight: '80vh',
            }}
            spacing={2}
          >
            {/* //////////////////////////////////////////////////////////////////////// */}
            {/* ////                             POI ACTIONS                        //// */}
            {/* //////////////////////////////////////////////////////////////////////// */}
            <Stack direction={'row'}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  ':hover': {
                    background: darkMode ? '#333' : '#EEE',
                  },
                }}
                onClick={handleReFetchDetails}
              >
                <CopySmall>Re-Fetch Details</CopySmall>
              </Box>
            </Stack>
            <Stack direction={'row'} spacing={2}>
              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                              POI IMAGE                         //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}
              <Stack
                sx={{
                  height: windowSize.height > 900 ? '150px' : '100px',
                  width: windowSize.width > 900 ? '200px' : '100px',
                  borderRadius: '20px',
                  overflow: 'hidden',
                  border: darkMode ? '1px solid #333' : '3px solid #EEE',
                }}
              >
                <PoIImage poi={poi} />
              </Stack>

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                              POI NAME                          //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <Stack>
                <Stack direction={'row'} spacing={'4px'}>
                  <CopySmall sx={{ fontSize: '0.6rem' }}>{poi.id}</CopySmall>
                  <OpenInNewButton onClick={handleOpenPoI} />
                </Stack>
                <CopyBold>{poi.name}</CopyBold>
              </Stack>
            </Stack>

            <ListContainer spacing={1}>
              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                           IMAGE SOURCE                         //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <CopySmall
                sx={{
                  lineHeight: '0.9rem',
                  fontSize: '0.9rem',
                }}
              >
                Image Source
              </CopySmall>

              <StyledStack sx={{ px: 2 }}>
                <EditableCopySmall onSubmit={handleUpdateImageSource}>
                  {poi.imageSource}
                </EditableCopySmall>
              </StyledStack>

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                        Information Sources                     //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <SourcesList poi={poi} onUpdate={setSources} />

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                          PoI Description                       //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <PoIDescriptions poi={poi} onUpdate={setDescriptions} />

              {/* //////////////////////////////////////////////////////////////////////// */}
              {/* ////                           PoI Transcripts                      //// */}
              {/* //////////////////////////////////////////////////////////////////////// */}

              <PoITranscriptList poi={poi} onUpdate={setPoITranscripts} />
            </ListContainer>
            {/* //////////////////////////////////////////////////////////////////////// */}
            {/* ////                         BOTTOM ACTIONS BAR                     //// */}
            {/* //////////////////////////////////////////////////////////////////////// */}

            <Stack
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              direction={'row'}
              spacing={1}
            >
              <Stack
                sx={{
                  alignItems: 'flex-start',
                }}
              >
                {missingInformation.length > 0 && (
                  <CopySmallBold sx={{ fontSize: '0.8rem' }}>
                    Missing Information:
                  </CopySmallBold>
                )}
                {missingInformation.map((info, index) => (
                  <CopySmall key={index} sx={{ fontSize: '0.7rem' }}>
                    - {info}
                  </CopySmall>
                ))}
              </Stack>
              <Button
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: completeness < 100 ? 'brown' : 'green',
                  height: '50px',
                  width: '150px',
                  cursor: 'pointer',
                  userSelect: 'none',
                  borderRadius: '10px',
                  textTransform: 'none',
                  ':hover': {
                    background: completeness < 100 ? 'gray' : 'green',
                  },
                }}
                disabled={completeness < 100 || localLoading}
                onClick={handleApprovePoI}
              >
                <CopySmallBold sx={{ fontSize: '0.8rem', color: 'white' }}>
                  {completeness < 100
                    ? `Incomplete (${completeness}%)`
                    : 'Approve'}
                </CopySmallBold>
              </Button>
            </Stack>
            {/* End of Bottom Actions Stack */}
          </Stack>
          {/* End of Main Stack */}
        </Box>
        {/* End of Modal Box */}
      </Fade>
    </Modal>
  );
};

export default PoIDetailsModal;
