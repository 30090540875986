import { FC, useCallback, useContext, useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CopySmall } from '../Typography';
import StyledBarItem from '../containers/StyledBarItem';
import StyledStack from '../containers/StyledStack';
import PLessIconButton from '../buttons/PLessIconButton';
import { PoI, PoISource } from '../../ifaces';
import { createPoISource, deletePoISource, getPoISources } from '../../API';
import { EditableCopySmall } from '../TypographyEditable';
import { Stack } from '@mui/material';
import MiniCircleAddButton from '../buttons/MiniCircleAddButton';
import { UICtx } from '../../UIProvider';

interface ListItemProps {
  source: PoISource;
  onDelete?: () => void;
}

const SourceListItem: FC<ListItemProps> = ({ source, onDelete }) => {
  return (
    <StyledBarItem
      sx={{
        p: '10px',
        py: '5px',
        justifyContent: 'space-between',
      }}
    >
      <CopySmall
        sx={{
          fontSize: '0.8rem',
        }}
      >
        {source.url}
      </CopySmall>
      <PLessIconButton onClick={onDelete}>
        <DeleteForeverIcon
          sx={{
            color: 'crimson',
            fontSize: '1.5rem',
            ':hover': {
              filter: 'contrast(200%)',
            },
          }}
        />
      </PLessIconButton>
    </StyledBarItem>
  );
};

interface ComponentProps {
  poi: PoI;
  onUpdate?: (sources: PoISource[]) => void;
}

const SourcesList: FC<ComponentProps> = ({ poi, onUpdate }) => {
  const { addNotification } = useContext(UICtx);
  const [localSources, setLocalSources] = useState<PoISource[]>([]);
  const [loading, setLoading] = useState(false);
  const [creatingSource, setCreatingSource] = useState(false);

  const fetchSources = useCallback(async () => {
    try {
      setLoading(true);
      const fetchedSources = await getPoISources(poi.id);
      setLocalSources(fetchedSources);
      onUpdate?.(fetchedSources);
    } catch (error) {
      console.error('Failed to load PoI sources:', error);
      addNotification({
        type: 'error',
        message: 'Failed to fetch PoI sources',
      });
    } finally {
      setLoading(false);
    }
  }, [poi.id, addNotification, onUpdate]);

  useEffect(() => {
    if (poi) {
      fetchSources();
    }
  }, [poi, fetchSources]);

  const handleCreateNewSource = async (text: string) => {
    try {
      setCreatingSource(false);
      setLoading(true);
      await createPoISource(poi.id, text);
      await fetchSources();
    } catch (error) {
      addNotification({
        type: 'error',
        message: 'Failed to create PoI source',
      });
    }
  };

  const handleDeletePoISource = async (source: PoISource) => {
    try {
      setLoading(true);
      await deletePoISource(source);
      await fetchSources(); // Re-fetch sources after deletion
    } catch (error) {
      addNotification({
        type: 'error',
        message: 'Failed to delete PoI source',
      });
    }
  };

  return (
    <Stack spacing={1}>
      <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={'6px'}>
        <CopySmall
          sx={{
            lineHeight: '0.9rem',
            fontSize: '0.9rem',
          }}
        >
          Information Sources
        </CopySmall>
        <MiniCircleAddButton onClick={() => setCreatingSource(true)} />
      </Stack>
      <StyledStack>
        {!loading &&
          localSources.map((source, index) => (
            <SourceListItem
              key={index}
              source={source}
              onDelete={() => handleDeletePoISource(source)}
            />
          ))}

        {loading && (
          <CopySmall
            sx={{
              textAlign: 'center',
              p: 1,
            }}
          >
            Loading...
          </CopySmall>
        )}

        {!loading && localSources.length === 0 && !creatingSource && (
          <CopySmall
            sx={{
              textAlign: 'center',
              p: 1,
            }}
          >
            No sources available. Please add one.
          </CopySmall>
        )}

        {!loading && creatingSource && (
          <StyledStack sx={{ p: 1 }}>
            <EditableCopySmall
              editOnInit
              onSubmit={handleCreateNewSource}
              disableUnderline
              onCancel={() => setCreatingSource(false)}
            />
          </StyledStack>
        )}
      </StyledStack>
    </Stack>
  );
};

export default SourcesList;
