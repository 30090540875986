import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { UICtx } from '../../UIProvider';
import { SUPPORTED_LANGUAGES } from '../../Constants';
import { FC, useContext, useState } from 'react';
import MiniGenerateButton from '../buttons/MiniGenerateButton';
import { Language } from '../../ifaces';
import { CopySmall } from '../Typography';

interface PoIItemOptionsProps {
  onLanguagePicked?: (language: string) => void;
  disabled?: boolean;
  availableLanguages?: Language[];
}

const LangGenPicker: FC<PoIItemOptionsProps> = ({
  onLanguagePicked,
  disabled,
  availableLanguages = SUPPORTED_LANGUAGES,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { darkMode } = useContext(UICtx);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguagePicked = (language: string) => {
    handleClose();
    onLanguagePicked?.(language);
  };

  return (
    <>
      <MiniGenerateButton onClick={handleClick} disabled={disabled} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiList-root': {
            py: '4px',
            backgroundColor: darkMode ? '#333' : '#fff',
          },
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: darkMode ? '#333' : '#fff',
            },
          },
        }}
      >
        {availableLanguages.map(lang => (
          <MenuItem
            key={lang.code}
            onClick={() => handleLanguagePicked(lang.code)}
            sx={{
              p: 1,
              py: '4px',
              backgroundColor: darkMode ? '#333' : '#fff',
              '&:hover': {
                backgroundColor: darkMode ? '#444' : '#EEE',
              },
            }}
          >
            <CopySmall>{lang.name}</CopySmall>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LangGenPicker;
