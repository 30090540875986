import { FC, useContext, useEffect, useState } from 'react';
import { PoI } from '../../ifaces';
import { IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PoIDetailsModal from './PoIDetailsModal';
import { UICtx } from '../../UIProvider';

interface WidgetProps {
  poi: PoI;
  editModalOpen?: boolean;
  onPoIApproved?: () => void;
}

const PoIEditWidgetSmall: FC<WidgetProps> = ({
  poi,
  editModalOpen,
  onPoIApproved,
}) => {
  const { darkMode } = useContext(UICtx);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(editModalOpen || false);
  }, [editModalOpen]);

  return (
    <>
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                          POI DETAILS MODAL                     //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {modalOpen && (
        <PoIDetailsModal
          open={modalOpen}
          setOpen={setModalOpen}
          poi={poi}
          onPoIApproved={onPoIApproved}
          // onConfirm={handleCreateTour}
        />
      )}

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                          POI EDIT BUTTON                       //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <IconButton sx={{ p: 0 }} onClick={() => setModalOpen(true)}>
        <OpenInNewIcon
          sx={{
            color: darkMode ? '#777' : '#000',
            fontSize: '1.2rem',
            '&:hover': {
              color: darkMode ? '#fff' : '#000',
            },
          }}
        />
      </IconButton>
    </>
  );
};

export default PoIEditWidgetSmall;
