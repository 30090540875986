import { ChangeEvent, FC, useContext, useState } from 'react';
import { Box, ClickAwayListener, InputBase, Link, Stack } from '@mui/material';
import { UICtx } from '../../UIProvider';
import { AutoCompletePrediction, autocompletePoI } from '../../API';
import { CopySmall } from '../Typography';
import { GeoPoint } from 'firebase/firestore';

interface PoISearchInputProps {
  placeholder?: string;
  onPlaceSelected?: (place: AutoCompletePrediction) => void;
  onPredictionSelected?: (prediction: AutoCompletePrediction) => void;
  disabled?: boolean;
  height?: string;
  fontSize?: string;
  maxWidth?: string;
  locationBias?: GeoPoint;
}

const PoISearchInput: FC<PoISearchInputProps> = ({
  placeholder = 'Find Point of Interest',
  onPlaceSelected,
  onPredictionSelected,
  disabled,
  height,
  fontSize,
  maxWidth,
  locationBias,
}) => {
  const { darkMode, isMobile } = useContext(UICtx);
  const [localInputState, setLocalInputState] = useState('');

  const [predictions, setPredictions] = useState<AutoCompletePrediction[]>([]);
  const [predictionsOpen, setPredictionsOpen] = useState(false);
  const [prediction, setPrediction] = useState<AutoCompletePrediction>();

  const [lastInputTime, setLastInputTime] = useState(0);
  const [lastInputTimeout, setLastInputTimeout] =
    useState<NodeJS.Timeout | null>(null);

  const handleSubmit = () => {
    if (prediction) {
      if (onPlaceSelected) {
        onPlaceSelected(prediction);
      }
    }
  };

  // Handle enter key press
  const handleKeyPress = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && onPlaceSelected) {
      handleSubmit();
    }
  };

  const handleTextChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newVal = event.target.value;
    setLocalInputState(newVal);

    // Check if last input was less than 500ms ago
    if (Date.now() - lastInputTime < 500) {
      // Clear timeout
      if (lastInputTimeout) {
        clearTimeout(lastInputTimeout);
      }
    }
    // Set new timeout
    const newTimeout = setTimeout(async () => {
      if (newVal.length < 3) {
        setPredictions([]);
      } else {
        const response = await autocompletePoI(newVal, locationBias);
        setPredictions(response.result);
        if (response.result.length > 0) {
          setPredictionsOpen(true);
        }
      }
    }, 500);

    setLastInputTimeout(newTimeout);

    // Set last input time
    setLastInputTime(Date.now());
  };

  return (
    <ClickAwayListener onClickAway={() => setPredictionsOpen(false)}>
      <Box
        sx={{
          display: 'flex',
          background: darkMode ? 'transparent' : 'rgb(244, 245, 251)',
          borderRadius: '40px',
          p: 1,
          pr: 1,
          width: '100%',
          minWidth: isMobile ? undefined : '300px',
          maxWidth: maxWidth,
          position: 'relative',
          height: height || '70px',
        }}
      >
        <InputBase
          onChange={handleTextChange}
          // Listen for enter key press
          onKeyPress={handleKeyPress}
          onFocus={() => {
            if (predictions.length > 0) {
              setPredictionsOpen(true);
            }
          }}
          disabled={disabled}
          value={localInputState}
          placeholder={placeholder}
          sx={{
            color: darkMode ? '#DDD' : 'black',
            pl: '10px',
            pr: 0,
            fontSize: fontSize || (isMobile ? '1.2rem' : '1.5rem'),
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            '.MuiInputBase-input:disabled': {
              // borderBottom: darkMode ? '2px solid #CCC' : undefined,
            },
            '.MuiInputBase-input::placeholder': {
              fontSize: fontSize || '1rem',
              lineHeight: fontSize || '1rem',
            },
            // https://www.w3docs.com/snippets/css/how-to-auto-hide-placeholder-text-on-focus-with-css-and-jquery.html
            // Trick to hide placeholder text when input is focused
            '.MuiInputBase-input:focus::placeholder': {
              color: 'transparent',
              fontSize: fontSize || '1rem',
            },
            borderBottom: !darkMode
              ? undefined
              : disabled
                ? '2px solid #444'
                : '2px solid #CCC',
            position: 'absolute',
            top: 5,
            left: 20,
            right: 20,
            bottom: 5,
          }}
        />
        {predictionsOpen && (
          <Stack
            sx={{
              top: '100%',
              right: 20,
              left: 20,
              position: 'absolute',
            }}
            spacing={'4px'}
          >
            {predictions.map((prediction, index) => {
              return (
                <Stack
                  key={index}
                  flexDirection={'row'}
                  sx={{
                    background: darkMode ? '#222' : '#fff',
                    zIndex: 100,
                    borderRadius: '10px',
                    px: 1,
                    py: '2px',
                    maxHeight: '200px',
                    overflowY: 'auto',
                    border: darkMode ? undefined : '1px solid #EEE',
                    '&:hover': {
                      background: darkMode ? '#444' : '#DDD',
                    },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Stack flex={1}>
                    <CopySmall>
                      {prediction.main_text}, {prediction.secondary_text}
                    </CopySmall>
                    <CopySmall sx={{ fontSize: '0.7rem' }}>
                      {prediction.place_id}
                    </CopySmall>
                    <Link
                      href={`https://www.google.com/maps/place/?q=place_id:${prediction.place_id}`}
                      target="_blank"
                      rel="noreferrer"
                      sx={{
                        color: darkMode ? '#fff' : '#000',
                        fontSize: '0.7rem',
                      }}
                    >
                      Open in Google Maps
                    </Link>
                  </Stack>
                  <Stack
                    sx={{
                      px: 2,
                      background: darkMode ? '#444' : '#EEE',
                      justifyContent: 'center',
                      height: '30px',
                      borderRadius: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setPrediction(prediction);
                      setLocalInputState(prediction.description);
                      setPredictionsOpen(false);
                      if (onPredictionSelected) {
                        onPredictionSelected(prediction);
                      }
                    }}
                  >
                    <CopySmall>Select</CopySmall>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        )}
      </Box>
    </ClickAwayListener>
  );
};
export default PoISearchInput;
