import { FC } from 'react';
import { TourTemplate } from '../../ifaces';
import ListContainer from '../containers/ListContainer';
import TemplatesListItem from './TemplatesListItem';

interface TemplatesListProps {
  templates: TourTemplate[];
  onTemplateSelected?: (template: TourTemplate) => void;
  onOpenTemplateDetails?: (template: TourTemplate) => void;
  onCenterOnMap?: (template: TourTemplate) => void;
}

const TemplatesList: FC<TemplatesListProps> = ({
  templates,
  onTemplateSelected,
  onOpenTemplateDetails,
  onCenterOnMap,
}) => {
  const handleSelect = (template: TourTemplate) => {
    onTemplateSelected?.(template);
  };

  return (
    <ListContainer spacing={'10px'}>
      {templates.map((template, index) => {
        return (
          <TemplatesListItem
            key={index}
            template={template}
            onSelect={() => handleSelect(template)}
            onOpenDetails={() => onOpenTemplateDetails?.(template)}
            onCenterOnMap={() => {
              onCenterOnMap?.(template);
            }}
            // isSelected={template.id === selected?.id}
          />
        );
      })}
    </ListContainer>
  );
};

export default TemplatesList;
