// Custom Components
import { useContext, useEffect } from 'react';
import { Stack } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import MainLayout from '../MainLayout';
import { Header3 } from '../../components/Typography';
import { AuthCtx } from '../../AuthProvider';
import { UICtx } from '../../UIProvider';

const SignOutPage = () => {
  const { darkMode } = useContext(UICtx);
  const { user } = useContext(AuthCtx);

  const handleSignOut = async () => {
    const auth = getAuth();
    await signOut(auth);
  };

  useEffect(() => {
    setTimeout(() => {
      handleSignOut();
      window.location.href = '/';
    }, 1500);
  }, [user]);

  return (
    <MainLayout>
      <Stack
        sx={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          background: darkMode ? '#121212' : '#fff',
        }}
      >
        <Header3 sx={{ color: 'white' }}>Bye bye ✌️</Header3>
      </Stack>
    </MainLayout>
  );
};
export default SignOutPage;
