import axios from 'axios';
import { database, firestore, headerConfig, storage } from './Firebase';
import { documentId } from 'firebase/firestore';
import { API_URL, REACT_APP_DEV_BACKEND_URL } from './config';
import {
  City,
  CityPoI,
  Place,
  PoI,
  PoIAudio,
  PoIDescription,
  PoISource,
  PoITranscript,
  Tour,
  TourTemplate,
  Trip,
  TripStop,
} from './ifaces';
import { get, ref, update } from 'firebase/database';
import {
  collection,
  deleteDoc,
  doc,
  GeoPoint,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from 'firebase/storage';
import { capitalize } from './utils';

type StatusResponse = {
  status:
    | 'OK'
    | 'REQUEST_DENIED'
    | 'INVALID_REQUEST'
    | 'UNKNOWN_ERROR'
    | 'ZERO_RESULTS';
};

////////////////////////////////////////////////////////////////////////
////                               IMAGES                           ////
////////////////////////////////////////////////////////////////////////

const getStorageDownloadURL = async (path: string): Promise<string> => {
  const downloadURL = await getDownloadURL(storageRef(storage, path));
  return downloadURL;
};

export const getImage = async (url: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(url, { responseType: 'blob' });
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = () => {
        const base64data = reader.result;
        const result = base64data as string;
        resolve(result);
      };
    } catch (error) {
      reject(error);
    }
  });
};

export const getTourImage = async (imageName: string): Promise<string> => {
  const path = `tour_images/${imageName}`;
  const downloadURL = await getStorageDownloadURL(path);
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.open('GET', downloadURL);
  xhr.send();
  return downloadURL;
};

export const getPoIImage = async (imageName: string): Promise<string> => {
  const path = `poi_images/${imageName}`;
  const downloadURL = await getStorageDownloadURL(path);
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.open('GET', downloadURL);
  xhr.send();
  return downloadURL;
};

export const uploadImage = async (
  path: string,
  file: File,
): Promise<string | null> => {
  try {
    const imageRef = storageRef(storage, path);
    const snapshot = await uploadBytes(imageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image', error);
    return null;
  }
};

export const uploadTourImage = async (
  imageName: string,
  file: File,
): Promise<string | null> => {
  const path = `tour_images/${imageName}`;
  return uploadImage(path, file);
};

export const uploadPoIImage = async (
  imageName: string,
  file: File,
): Promise<string | null> => {
  const path = `poi_images/${imageName}`;
  return uploadImage(path, file);
};

////////////////////////////////////////////////////////////////////////
////                               PLACES                           ////
////////////////////////////////////////////////////////////////////////

export type PlaceDetailsParams = {
  placeId: string;
};

export type PlaceDetailsResponse = StatusResponse & {
  result: Place;
};

export const getPlaceDetails = async (
  params: PlaceDetailsParams,
): Promise<PlaceDetailsResponse> => {
  const response = await axios
    .get(
      `${API_URL}/ai-travel-assistant/place-details?placeId=${params.placeId}`,
      await headerConfig(),
    )
    .catch(e => e.response);
  return response.data as PlaceDetailsResponse;
};

////////////////////////////////////////////////////////////////////////
////                         PLACES AUTOCOMPLETE                    ////
////////////////////////////////////////////////////////////////////////

export type AutoCompletePrediction = {
  description: string;
  place_id: string;
  main_text: string;
  secondary_text: string;
  country?: string;
};

export type AutoCompleteResponse = StatusResponse & {
  result: AutoCompletePrediction[];
};

export const autocompletePlace = async (
  input: string,
): Promise<AutoCompleteResponse> => {
  const response = await axios
    .post(
      `${API_URL}/ai-travel-assistant/autocomplete`,
      { input: input },
      await headerConfig(),
    )
    .catch(e => e.response);

  return response.data as AutoCompleteResponse;
};

////////////////////////////////////////////////////////////////////////
////                          PoIs AUTOCOMPLETE                     ////
////////////////////////////////////////////////////////////////////////

export const autocompletePoI = async (
  input: string,
  locationBias?: GeoPoint,
): Promise<AutoCompleteResponse> => {
  const reqBody: {
    input: string;
    latitude?: number;
    longitude?: number;
  } = {
    input: input,
  };

  if (locationBias) {
    reqBody['latitude'] = locationBias.latitude;
    reqBody['longitude'] = locationBias.longitude;
  }

  const response = await axios
    .post(
      `${API_URL}/ai-travel-assistant/autocomplete-poi`,
      reqBody,
      await headerConfig(),
    )
    .catch(e => e.response);

  return response.data as AutoCompleteResponse;
};

////////////////////////////////////////////////////////////////////////
////                           POIS : OLD                           ////
////////////////////////////////////////////////////////////////////////

type LoadPoIsParams = {
  trip_id: string;
  place_id: string;
  skip?: PoI[];
  include?: PoI[];
};

export const loadPointsOfInterest = async (
  params: LoadPoIsParams,
): Promise<void> => {
  await axios
    .put(
      `${API_URL}/ai-travel-assistant/load-pois`,
      { ...params },
      await headerConfig(),
    )
    .catch(e => e.response);
};

////////////////////////////////////////////////////////////////////////
////                           POIS : NEW                           ////
////////////////////////////////////////////////////////////////////////

type CreatePoIResponse = StatusResponse & {
  result: PoI;
};

export const createPoI = async (
  placeId: string,
  withCity?: string,
): Promise<CreatePoIResponse> => {
  const response = await axios
    .post(
      `${API_URL}/ai-travel-assistant/pois`,
      { placeId: placeId, city: withCity },
      await headerConfig(),
    )
    .catch(e => e.response);
  return response.data as CreatePoIResponse;
};

type UpdatePoIParams = {
  imageName?: string;
  imageSource?: string;
};

export const updatePoI = async (
  poiId: string,
  params: UpdatePoIParams,
): Promise<void> => {
  const poisRef = collection(firestore, 'pois');
  const poiDocRef = doc(poisRef, poiId); // Get a reference to the PoI document

  // Update the document with the new params
  await updateDoc(poiDocRef, {
    ...params,
  });
};

type GetPoIsParams = {
  lat: number;
  lng: number;
  radius: number;
};

type GetPoIsResponse = StatusResponse & {
  result: PoI[];
};

export const getPoIsForLocation = async (
  params: GetPoIsParams,
): Promise<GetPoIsResponse> => {
  const response = await axios
    .get(
      `${API_URL}/ai-travel-assistant/pois?lat=${params.lat}&lng=${params.lng}&radius=${params.radius}`,
      await headerConfig(),
    )
    .catch(e => e.response);
  return response.data as GetPoIsResponse;
};

export const getPoI = async (poi_id: string): Promise<PoI | null> => {
  // Load from firestore directly
  const poiRef = doc(firestore, 'pois', poi_id);
  const docSnap = await getDoc(poiRef);
  if (docSnap.exists()) {
    return docSnap.data() as PoI;
  } else {
    return null;
  }
};

export const getPoIs = async (poi_ids: string[]): Promise<PoI[]> => {
  const pois: PoI[] = [];
  for (const place_id of poi_ids) {
    const poi = await getPoI(place_id);
    if (poi) {
      pois.push(poi);
    } else {
      console.error('Error fetching PoI', place_id);
      throw new Error('Error fetching PoIs. Conflicting PoI: ' + place_id);
    }
  }
  return pois;
};

export const deletePoI = async (id: string): Promise<void> => {
  await axios.delete(
    `${API_URL}/ai-travel-assistant/pois/${id}`,
    await headerConfig(),
  );
};

export const approvePoI = async (id: string): Promise<void> => {
  await axios.put(
    `${API_URL}/ai-travel-assistant/pois/${id}/approve`,
    {},
    await headerConfig(),
  );
};

export const refetchPoIDetails = async (id: string): Promise<void> => {
  await axios.put(
    `${API_URL}/ai-travel-assistant/pois/${id}/refetch-details`,
    {},
    await headerConfig(),
  );
};

////////////////////////////////////////////////////////////////////////
////                              CITIES                            ////
////////////////////////////////////////////////////////////////////////

export const getCity = async (cityId: string): Promise<City | null> => {
  const cityRef = doc(firestore, 'cities', cityId);
  const docSnap = await getDoc(cityRef);
  if (docSnap.exists()) {
    return docSnap.data() as City;
  } else {
    return null;
  }
};

export const getCities = async (
  orderByField: string = 'name',
  filterByCountry: string | null = null,
  nameFilter: string = '',
): Promise<City[]> => {
  const citiesRef = collection(firestore, 'cities');
  let conditions = [];

  if (filterByCountry) {
    conditions.push(where('countryShort', '==', filterByCountry));
  }

  if (nameFilter !== '') {
    const startAtName = capitalize(nameFilter);
    const endAtName = capitalize(nameFilter) + '\uf8ff'; // Use a high value in the Unicode range to include all possible suffixes
    conditions.push(
      where('name', '>=', startAtName),
      where('name', '<=', endAtName),
    );
  }

  conditions.push(orderBy(orderByField));

  // Combine all conditions into the query
  const q = query(citiesRef, ...conditions);

  const querySnapshot = await getDocs(q);
  const cities: City[] = [];
  querySnapshot.forEach(doc => {
    cities.push(doc.data() as City);
  });
  return cities;
};

export const getCityPoIs = async (cityId: string): Promise<PoI[]> => {
  const poisRef = collection(firestore, 'cities', cityId, 'pois');
  const querySnapshot = await getDocs(poisRef);
  const poiIds: string[] = [];
  querySnapshot.forEach(doc => {
    const cityPoi = doc.data() as CityPoI;
    poiIds.push(cityPoi.poi); // Assuming 'poi' is the field with the PoI ID
  });

  const pois: PoI[] = [];
  const BATCH_SIZE = 10;
  for (let i = 0; i < poiIds.length; i += BATCH_SIZE) {
    const batch = poiIds.slice(i, i + BATCH_SIZE);
    const poisCollectionRef = collection(firestore, 'pois');
    const batchQuery = query(
      poisCollectionRef,
      where(documentId(), 'in', batch),
    );
    const batchSnapshot = await getDocs(batchQuery);
    batchSnapshot.forEach(doc => {
      pois.push(doc.data() as PoI);
    });
  }

  return pois;
};

export const addPoIToCity = async (
  cityId: string,
  poiId: string,
): Promise<void> => {
  const cityPoisRef = collection(firestore, 'cities', cityId, 'pois');
  const docRef = doc(cityPoisRef, poiId);
  await setDoc(docRef, { poi: poiId });

  const poiCitiesRef = collection(firestore, 'pois');
  await updateDoc(doc(poiCitiesRef, poiId), { city: cityId });
};

// export const getCities = async (
//   pageSize: number = 10,
//   pageNumber: number = 1,
//   orderByField: string = 'name',
// ): Promise<City[]> => {
//   const citiesRef = collection(firestore, 'cities');

//   // Calculate the offset
//   const offset = (pageNumber - 1) * pageSize;

//   // Create a query with orderBy (assuming 'name' as a field to order by)
//   let citiesQuery = query(citiesRef, orderBy(orderByField), limit(pageSize));

//   // If not the first page, adjust the query to start after the last document of the previous page
//   if (pageNumber > 1) {
//     const lastVisibleDoc = await getDocs(
//       query(citiesRef, orderBy(orderByField), limit(offset)),
//     );
//     const lastVisibleDocSnapshot =
//       lastVisibleDoc.docs[lastVisibleDoc.docs.length - 1];
//     citiesQuery = query(
//       citiesRef,
//       orderBy(orderByField),
//       startAfter(lastVisibleDocSnapshot),
//       limit(pageSize),
//     );
//   }

//   const querySnapshot = await getDocs(citiesQuery);
//   const cities: City[] = [];
//   querySnapshot.forEach(doc => {
//     cities.push(doc.data() as City);
//   });

//   return cities;
// };

type ConvertPlaceToCityParams = {
  placeId: string;
};

type ConvertPlaceToCityResponse = StatusResponse & {
  result: City;
};

export const convertPlaceToCity = async (
  params: ConvertPlaceToCityParams,
): Promise<ConvertPlaceToCityResponse> => {
  const response = await axios
    .post(
      `${API_URL}/ai-travel-assistant/convert-to-city`,
      { ...params },
      await headerConfig(),
    )
    .catch(e => e.response);
  return response.data as ConvertPlaceToCityResponse;
};

export const createCity = async (city: City): Promise<City> => {
  // ONLY if the city does not exist
  const docRef = doc(firestore, 'cities', city.id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    throw new Error('City already exists');
  } else {
    await setDoc(docRef, city);
    return city;
  }
};

////////////////////////////////////////////////////////////////////////
////                           TOUR TEMPLATES                       ////
////////////////////////////////////////////////////////////////////////

export const getTourTemplates = async (): Promise<TourTemplate[]> => {
  // Load templates from firebase realtime database
  const templatesRef = ref(database, 'tour_generation_templates');
  const snapshot = await get(templatesRef);
  if (snapshot.exists()) {
    const templates: TourTemplate[] = [];
    const data = snapshot.val() as { [key: string]: TourTemplate };
    for (const key in data) {
      templates.push(data[key]);
    }
    return templates;
  } else {
    return [];
  }
};

type UpdateTourTemplateParams = {
  center?: GeoPoint;
  zoom?: number;
  name?: string;
  description?: string;
  imageName?: string;
};

export const updateTourTemplate = async (
  tourTemplateId: string,
  params: UpdateTourTemplateParams,
): Promise<void> => {
  const updates: any = {};
  if (params.center) {
    updates[
      '/tour_generation_templates/' + tourTemplateId + '/viewportCenter'
    ] = {
      latitude: params.center.latitude,
      longitude: params.center.longitude,
    };
  }
  if (params.zoom) {
    updates['/tour_generation_templates/' + tourTemplateId + '/zoom'] =
      params.zoom;
  }
  if (params.name) {
    updates['/tour_generation_templates/' + tourTemplateId + '/name'] =
      params.name;
  }
  if (params.description) {
    updates['/tour_generation_templates/' + tourTemplateId + '/description'] =
      params.description;
  }
  if (params.imageName) {
    updates['/tour_generation_templates/' + tourTemplateId + '/imageName'] =
      params.imageName;
  }

  update(ref(database), updates).then(() => {
    console.log('Tour template updated');
  });
};

export const approveTourTemplate = async (
  tourTemplateId: string,
): Promise<void> => {
  await axios
    .put(
      `${API_URL}/ai-travel-assistant/tour-templates/approve`,
      { tourTemplateId: tourTemplateId },
      await headerConfig(),
    )
    .catch(e => e.response);
};

////////////////////////////////////////////////////////////////////////
////                               TOURs                            ////
////////////////////////////////////////////////////////////////////////

type CreateTourParams = {};

type CreateTourResponse = StatusResponse & {
  result: Tour;
};

export const createTour = async (
  params: CreateTourParams,
): Promise<CreateTourResponse> => {
  const response = await axios
    .post(`${API_URL}/ai-travel-assistant/tours`, params, await headerConfig())
    .catch(e => e.response);
  return response.data as CreateTourResponse;
};

type GetToursParams = {
  lat: number;
  lng: number;
  radius?: number;
};

type GetToursResponse = StatusResponse & {
  result: Tour[];
};

export const getTours = async (
  params: GetToursParams,
): Promise<GetToursResponse> => {
  let url = `${API_URL}/ai-travel-assistant/tours?lat=${params.lat}&lng=${params.lng}`;
  if (params.radius) {
    url += `&radius=${params.radius}`;
  }
  const response = await axios
    .get(url, await headerConfig())
    .catch(e => e.response);
  return response.data as GetToursResponse;
};

// type GenerateTourStopsParams = {
//   startLocation: string;
//   endLocation: string;
// };

// type GenerateTourStopsResponse = StatusResponse & {
//   result: Tour;
// };

// export const generateTourStops = async (
//   params: GenerateTourStopsParams,
// ): Promise<GenerateTourStopsResponse> => {
//   const response = await axios
//     .post(
//       `${API_URL}/ai-travel-assistant/do-the-magic`,
//       { ...params },
//       await headerConfig(),
//     )
//     .catch(e => e.response);
//   return response.data as GenerateTourStopsResponse;
// };

////////////////////////////////////////////////////////////////////////
////                               TRIPs                            ////
////////////////////////////////////////////////////////////////////////

type GetTripsResponse = StatusResponse & {
  result: Trip[];
};

export const getTrips = async (): Promise<GetTripsResponse> => {
  const response = await axios
    .get(`${API_URL}/ai-travel-assistant/trips`, await headerConfig())
    .catch(e => e.response);
  return response.data as GetTripsResponse;
};

// Trip Itinerary

type TripItineraryResponse = StatusResponse & {
  result: Trip;
};

export const generateItinerary = async (
  place_id: string,
): Promise<TripItineraryResponse> => {
  const response = await axios
    .post(
      `${API_URL}/ai-travel-assistant/do-the-magic`,
      { place_id: place_id },
      await headerConfig(),
    )
    .catch(e => e.response);
  return response.data as TripItineraryResponse;
};

// Create Trip

type CreateTripResponse = StatusResponse & {
  result: Trip;
};

export const createTrip = async (): Promise<CreateTripResponse> => {
  const response = await axios
    .post(`${API_URL}/ai-travel-assistant/trips`, {}, await headerConfig())
    .catch(e => e.response);
  return response.data as CreateTripResponse;
};

////////////////////////////////////////////////////////////////////////
////                             TRIP STOPS                         ////
////////////////////////////////////////////////////////////////////////

type AddTripStopParams = {
  destination: string;
  duration: number;
};

type AddTripStopResponse = StatusResponse & {
  result: TripStop;
};

export const addTripStop = async (
  trip_id: string,
  params: AddTripStopParams,
): Promise<AddTripStopResponse> => {
  const response = await axios
    .post(
      `${API_URL}/ai-travel-assistant/trips/${trip_id}/stops`,
      params,
      await headerConfig(),
    )
    .catch(e => e.response);
  return response.data as AddTripStopResponse;
};

export const deleteTripStop = async (
  trip_id: string,
  stop_id: string,
): Promise<void> => {
  await axios.delete(
    `${API_URL}/ai-travel-assistant/trips/${trip_id}/stops/${stop_id}`,
    await headerConfig(),
  );
};

////////////////////////////////////////////////////////////////////////
////                           DAY ITINERARY                        ////
////////////////////////////////////////////////////////////////////////

type UpdateDayItineraryParams = {
  PoIsToInclude?: string[];
  PoIsToSkip?: string[];
};

type UpdateDayItineraryResponse = {
  status: string;
  result: Trip;
};

export const updateDayItinerary = async (
  params: UpdateDayItineraryParams,
): Promise<UpdateDayItineraryResponse> => {
  const response = await axios
    .post(
      `${API_URL}/ai-travel-assistant/do-the-magic`,
      { ...params },
      await headerConfig(),
    )
    .catch(e => e.response);
  return response.data as UpdateDayItineraryResponse;
};

////////////////////////////////////////////////////////////////////////
////                            AUDIOGUIDES                         ////
////////////////////////////////////////////////////////////////////////

export const createAudioGuide = async (place_id: string): Promise<void> => {
  await axios.post(
    `${API_URL}/ai-travel-assistant/audioguides`,
    { place_id: place_id },
    await headerConfig(),
  );
};

////////////////////////////////////////////////////////////////////////
////                     POI INFORMATION SOURCES                    ////
////////////////////////////////////////////////////////////////////////

export const getPoISources = async (poiId: string): Promise<PoISource[]> => {
  const results: PoISource[] = [];
  const ref = collection(firestore, `pois/${poiId}/sources`);
  const querySnapshot = await getDocs(ref);
  querySnapshot.forEach(doc => {
    results.push(doc.data() as PoISource);
  });
  return results;
};

export const createPoISource = async (
  poiId: string,
  url: string,
): Promise<PoISource> => {
  // Firestore reference to the sources subcollection for a specific PoI
  const sourcesRef = collection(firestore, `pois/${poiId}/sources`);

  // Manually create a new document reference with a unique ID
  const docRef = doc(sourcesRef);

  // The data to be saved, including the document ID
  const sourceData = {
    id: docRef.id, // Explicitly include the generated ID in the document data
    poi: poiId,
    url: url,
  };

  // Adding the new source to Firestore with the manually generated ID
  await setDoc(docRef, sourceData);

  return sourceData;
};

export const deletePoISource = async (source: PoISource): Promise<void> => {
  const sourceDocRef = doc(
    firestore,
    `pois/${source.poi}/sources/${source.id}`,
  );
  await deleteDoc(sourceDocRef);
};

////////////////////////////////////////////////////////////////////////
////                         POI DESCRIPTIONS                       ////
////////////////////////////////////////////////////////////////////////

export const getPoIDescriptions = async (
  poiId: string,
): Promise<PoIDescription[]> => {
  const descriptions: PoIDescription[] = [];
  const poiDescriptionsRef = collection(
    firestore,
    `pois/${poiId}/descriptions`,
  );
  const querySnapshot = await getDocs(poiDescriptionsRef);
  querySnapshot.forEach(doc => {
    descriptions.push(doc.data() as PoIDescription);
  });
  return descriptions;
};

export const enqueuePoIDescriptionGeneration = async (
  poiId: string,
  language: string,
): Promise<void> => {
  if (
    process.env.NODE_ENV === 'development' &&
    REACT_APP_DEV_BACKEND_URL !== ''
  ) {
    await axios.post(
      `${REACT_APP_DEV_BACKEND_URL}/generate_poi_description`,
      { poiId: poiId, language: language },
      await headerConfig(),
    );
  } else {
    await axios.post(
      `${API_URL}/ai-travel-assistant/poi-descriptions`,
      { poiId: poiId, language: language },
      await headerConfig(),
    );
  }
};

////////////////////////////////////////////////////////////////////////
////                           TRANSCRIPTS                          ////
////////////////////////////////////////////////////////////////////////

export const enqueuePoITranscriptGeneration = async (
  poiId: string,
  language: string,
): Promise<void> => {
  if (
    process.env.NODE_ENV === 'development' &&
    REACT_APP_DEV_BACKEND_URL !== ''
  ) {
    await axios.post(
      `${REACT_APP_DEV_BACKEND_URL}/generate_poi_transcript`,
      { poiId: poiId, language: language },
      await headerConfig(),
    );
  } else {
    await axios.post(
      `${API_URL}/ai-travel-assistant/pois/${poiId}/transcripts`,
      { language: language },
      await headerConfig(),
    );
  }
};

export const getPoITranscripts = async (
  poiId: string,
): Promise<PoITranscript[]> => {
  const poiTranscriptsRef = collection(
    firestore,
    'pois/' + poiId + '/transcripts',
  );
  const transcripts: PoITranscript[] = [];
  const querySnapshot = await getDocs(poiTranscriptsRef);
  querySnapshot.forEach(doc => {
    transcripts.push(doc.data() as PoITranscript);
  });
  return transcripts;
};

export const generatePoITranscript = async (
  poiId: string,
  language: string,
): Promise<PoITranscript> => {
  const response = await axios
    .put(
      `${API_URL}/ai-travel-assistant/pois/${poiId}/generate-transcript`,
      {
        language: language,
      },
      await headerConfig(),
    )
    .catch(e => e.response);
  return response.data as PoITranscript;
};

export const regeneratePoITranscript = async (
  transcript: PoITranscript,
): Promise<void> => {
  if (
    process.env.NODE_ENV === 'development' &&
    REACT_APP_DEV_BACKEND_URL !== ''
  ) {
    await axios.post(
      `${REACT_APP_DEV_BACKEND_URL}/generate_poi_transcript`,
      { poiId: transcript.poi, transcriptId: transcript.id },
      await headerConfig(),
    );
  } else {
    await axios
      .put(
        `${API_URL}/ai-travel-assistant/pois/${transcript.poi}/generate-transcript`,
        { poiId: transcript.poi, transcriptId: transcript.id },
        await headerConfig(),
      )
      .catch(e => e.response);
  }
};

export const deletePoITranscript = async (
  poiId: string,
  transcriptId: string,
): Promise<void> => {
  await axios.delete(
    `${API_URL}/ai-travel-assistant/pois/${poiId}/transcripts/${transcriptId}`,
    await headerConfig(),
  );
};

////////////////////////////////////////////////////////////////////////
////                      POI TRANSCRIPT AUDIOS                     ////
////////////////////////////////////////////////////////////////////////

export const getPoITranscriptAudios = async (
  poiId: string,
  transcriptId: string,
): Promise<PoIAudio[]> => {
  const poiTranscriptAudiosRef = collection(
    firestore,
    `pois/${poiId + '/transcripts/' + transcriptId + '/audios'}`,
  );
  const audios: PoIAudio[] = [];
  const querySnapshot = await getDocs(poiTranscriptAudiosRef);
  querySnapshot.forEach(doc => {
    audios.push(doc.data() as PoIAudio);
  });
  return audios;
};

export const generatePoITranscriptAudio = async (
  poiId: string,
  transcriptId: string,
  ttsProvider: string | undefined | null = null,
  voiceId: string | undefined | null = null,
): Promise<void> => {
  if (
    process.env.NODE_ENV === 'development' &&
    REACT_APP_DEV_BACKEND_URL !== ''
  ) {
    await axios.post(
      `${REACT_APP_DEV_BACKEND_URL}/generate_poi_transcript_audio`,
      { poiId, transcriptId, ttsProvider, voiceId },
      await headerConfig(),
    );
  }
  // else {
  //   await axios.post(
  //     `${API_URL}/ai-travel-assistant/pois/${poiId}/transcripts`,
  //     { language: language },
  //     await headerConfig(),
  //   );
  // }
  // const response = await axios
  //   .put(
  //     `${API_URL}/ai-travel-assistant/pois/${poiId}/transcripts/${transcriptId}/generate-audio`,
  //     {},
  //     await headerConfig(),
  //   )
  //   .catch(e => e.response);
  // return response.data as PoIAudio;
};
