import { FC, useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { UICtx } from '../../UIProvider';
import { PoI } from '../../ifaces';
import AITravelAssistantMap from '../AITravelAssistantMap';

interface PoIsListWithMapProps {
  onSubmitPoIsPreferences?: (
    PoIs_to_skip: PoI[],
    PoIs_to_include: PoI[],
  ) => void;
}

const PoIsListWithMap: FC<PoIsListWithMapProps> = () => {
  // Map control state
  const { darkMode, isMobile } = useContext(UICtx);
  const [mapVisible, setMapVisible] = useState(!isMobile);

  useEffect(() => {
    setMapVisible(!isMobile);
  }, [isMobile]);

  return (
    <Stack
      direction={'row'}
      sx={{
        flex: 1,
        width: '100%',
        overflow: 'hidden',
        background: darkMode ? '#040404' : '#fff',
      }}
    >
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                               PoIs List                        //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <Stack sx={{ flex: 1, p: 1, pt: 0, position: 'relative' }}>
        {/* <PoIsList onPoISelected={handlePoISelected} /> */}
      </Stack>

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                                 MAP                            //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {!isMobile ? (
        mapVisible ? (
          <AITravelAssistantMap
          // mapViewState={{
          //   latitude: selectedPoI?.location.latitude || 0,
          //   longitude: selectedPoI?.location.longitude || 0,
          //   zoom: 15,
          // }}
          />
        ) : (
          <Stack sx={{ flex: 1 }}>Loading</Stack>
        )
      ) : null}
    </Stack>
  );
};

export default PoIsListWithMap;
