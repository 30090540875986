import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import { FC, useState } from 'react';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: '8px',
    position: 'relative',
    // border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 20px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: "'Plus Jakarta Sans', sans-serif",
    '&:focus': {
      borderRadius: '8px',
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

interface StayLengthSelectProps {
  onStayLengthSelected?: (stayLength: number) => void;
  darkMode?: boolean;
}

const StayLengthSelect: FC<StayLengthSelectProps> = ({
  darkMode = false,
  onStayLengthSelected,
}) => {
  const [stayLength, setStayLength] = useState<number>(0);
  const STAY_LENGTH_VALUES = [1, 2, 3, 4, 5, 6, 7, 14];

  const handleStayLengthChange = (event: { target: { value: string } }) => {
    setStayLength(parseInt(event.target.value));
    if (onStayLengthSelected) {
      onStayLengthSelected(parseInt(event.target.value));
    }
  };

  const getFontColor = () => {
    if (stayLength === 0) {
      return darkMode ? '#666' : '#CCC';
    } else {
      return darkMode ? '#CCC' : 'black';
    }
  };

  const getOptionText = (length: number) => {
    if (length === 0) {
      return 'Select stay length';
    } else if (length > 6) {
      return `${length / 7} ${length / 7 === 1 ? 'Week' : 'Weeks'}`;
    } else {
      return `${length} ${length === 1 ? 'Day' : 'Days'}`;
    }
  };

  return (
    <FormControl fullWidth>
      <NativeSelect
        id="stay-length-select"
        value={stayLength}
        onChange={handleStayLengthChange}
        input={
          <BootstrapInput
            sx={{
              borderRadius: '8px',
              color: getFontColor(),
              backgroundColor: darkMode ? '#141414' : '#fff',
              '& .MuiSvgIcon-root': {
                color: getFontColor(),
              },
            }}
          />
        }
      >
        <option value={0} disabled>
          Select duration
        </option>
        {STAY_LENGTH_VALUES.map(value => (
          <option key={`option-${value}`} value={value}>
            {getOptionText(value)}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

export default StayLengthSelect;
