import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';

import { firebaseConfig } from './config';
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const database = getDatabase(app);
const firestore = getFirestore(app);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const headerConfig = async (): Promise<{ [key: string]: any }> => {
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    return { headers: { Authorization: 'Bearer ' + token } };
  } else {
    return {};
  }
};

const headerAuthConfig = async (): Promise<{ [key: string]: string }> => {
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    return { Authorization: 'Bearer ' + token };
  } else {
    return {};
  }
};

const uploadFileToStorage = async (
  fileName: string,
  storagePath: string,
  file: File,
  onLoadingHandler?: (progress: boolean) => void,
  onProgressHandler?: (progress: number) => void,
) => {
  if (onLoadingHandler) onLoadingHandler(true);

  const destPath = `${storagePath}/${fileName}`;

  const storageRef = ref(storage, destPath);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    'state_changed',
    snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      if (onProgressHandler) onProgressHandler(progress);
    },
    error => {
      console.log(error);
      if (onLoadingHandler) onLoadingHandler(false);
      if (onProgressHandler) onProgressHandler(-1);
    },
    async () => {
      if (onLoadingHandler) onLoadingHandler(false);
      getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
        console.log('File available at', downloadURL);
      });
    },
  );
};

const uploadImageToStorage = async (
  fileName: string,
  storagePath: string,
  file: File,
  onLoadingHandler?: (progress: boolean) => void,
  onProgressHandler?: (progress: number) => void,
) => {
  await uploadFileToStorage(
    fileName,
    storagePath,
    file,
    onLoadingHandler,
    onProgressHandler,
  );
};

export {
  app,
  auth,
  database,
  firestore,
  storage,
  headerConfig,
  headerAuthConfig,
  uploadFileToStorage,
  uploadImageToStorage,
};
