import { FC, useContext } from 'react';
import { ButtonProps, IconButton } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { UICtx } from '../../UIProvider';

const MyLocationButton: FC<{ fontSize?: string } & ButtonProps> = ({
  onClick,
  sx,
  fontSize,
}) => {
  const { darkMode } = useContext(UICtx);

  return (
    <IconButton
      onClick={onClick}
      sx={{
        color: darkMode ? '#777' : '#000',
        ':hover': {
          color: darkMode ? '#fff' : '#000',
        },
        p: 0,
        ...sx,
      }}
    >
      <MyLocationIcon
        sx={{
          fontSize: fontSize ? fontSize : '1.2rem',
        }}
      />
    </IconButton>
  );
};

export default MyLocationButton;
