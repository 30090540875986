import { useContext } from 'react';
import { Stack } from '@mui/material';
import { UICtx } from '../../UIProvider';
import FixedNavigationBar from '../../components/Navigation/FixedNavigationBar';

const TripsPage = () => {
  const { darkMode } = useContext(UICtx);

  return (
    <Stack sx={{ flex: 1, background: darkMode ? '#121212' : '#fff' }}>
      <FixedNavigationBar displayDarkModeSwitch />
    </Stack>
  );
};

export default TripsPage;
