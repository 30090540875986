import React, { useState, FC, useEffect, useContext } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getPoIImage, updatePoI, uploadPoIImage } from '../../API';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { UICtx } from '../../UIProvider';
import MiniGenerateButton from '../buttons/MiniGenerateButton';
import { PoI } from '../../ifaces';

interface ImageComponentProps {
  poi: PoI;
  onUploadTourImage?: (file: File) => void;
  onUploadPoIImage?: (file: File) => void;
  enableEdit?: boolean;
}

const ImageComponent: FC<ImageComponentProps> = ({
  onUploadTourImage,
  onUploadPoIImage,
  poi,
}) => {
  const { darkMode } = useContext(UICtx);
  const [imageFile, setImageFile] = useState<File>();
  const [loadingImage, setLoadingImage] = useState(true);
  const [imageData, setImageData] = useState<string>();

  useEffect(() => {
    if (poi.imageName) {
      const fetchImage = async (imageName: string) => {
        try {
          const data = await getPoIImage(imageName);
          setImageData(data);
          setLoadingImage(false);
        } catch (error) {
          console.error('Failed to load image:', error);
          setLoadingImage(false);
        }
      };
      fetchImage(poi.imageName);
    } else {
      setLoadingImage(false);
    }
  }, [poi.imageName]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      setImageFile(file);
      const fileUrl = URL.createObjectURL(file);
      setImageData(fileUrl);
    }
  };

  const uploadImageToFirestore = async () => {
    if (!imageFile) {
      return;
    }
    const imageName = `${poi.id}`;
    await uploadPoIImage(imageName, imageFile);
    await updatePoI(poi.id, { imageName });
  };

  const openFilePicker = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = e => {
      const target = e.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const file = target.files[0];
        setImageFile(file);
        const fileUrl = URL.createObjectURL(file);
        setImageData(fileUrl);
      }
    };
    input.click();
  };
  return (
    <Stack
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      sx={{
        height: '150px',
        width: '100%',
        background: darkMode ? '#151515' : '#F9F9F9',
        position: 'relative', // Ensure the Stack component is positioned relatively for the absolute positioning of children
        alignItems: 'center', // Center the CircularProgress vertically
        justifyContent: 'center', // Center the CircularProgress horizontally
        overflow: 'hidden', // Ensure the image doesn't overflow the Stack component
      }}
    >
      {loadingImage ? (
        <CircularProgress
          sx={{ color: darkMode ? 'grey.500' : 'primary.main' }}
        />
      ) : (
        imageData && (
          <img
            style={{
              width: '100%', // This will make the image fill the width
              height: 'auto', // This will maintain the aspect ratio
              objectFit: 'contain', // Ensure the whole image fits within the container without cropping
            }}
            src={imageData}
            loading="lazy"
            alt="Tour"
            // onLoad={() => URL.revokeObjectURL(tourImage)}
          />
        )
      )}

      <Box
        sx={{
          position: 'absolute',
          left: '10px',
          top: '10px',
        }}
      >
        <MiniGenerateButton />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          left: '10px',
          bottom: '10px',
          zIndex: 1,
          borderRadius: '50%',
        }}
      >
        <IconButton
          sx={{
            p: '2px',
            color: darkMode ? '#999' : '#000',
            '&:hover': {
              color: darkMode ? '#fff' : '#000',
              background: darkMode ? '#444' : '#DDD',
            },
            background: darkMode ? '#333' : '#EEE',
          }}
          onClick={imageFile ? uploadImageToFirestore : openFilePicker}
        >
          {imageFile ? (
            <FolderOpenIcon
              sx={{
                fontSize: '1.2rem',
              }}
            />
          ) : (
            <DriveFolderUploadIcon
              sx={{
                fontSize: '1.2rem',
              }}
            />
          )}
        </IconButton>
      </Box>
    </Stack>
  );
};

const PoIImage: FC<ImageComponentProps> = props => {
  return (
    <DndProvider backend={HTML5Backend}>
      <ImageComponent {...props} />
    </DndProvider>
  );
};

export default PoIImage;
