import { FC, useContext, useEffect, useState } from 'react';

// Material UI Components
import { IconButton, Stack, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// DnD Components
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

// Custom
import { UICtx } from '../../UIProvider';
import { PoI, TourStopTemplate } from '../../ifaces';
import { CopySmallBold } from '../Typography';
import { getPoI } from '../../API';
import PoIEditWidgetSmall from '../PoIDetails/PoIEditWidgetSmall';

interface ComponentProps {
  stop: TourStopTemplate;
  onStopSelected?: (stop: TourStopTemplate, poi: PoI | undefined) => void;
}

const TourTemplateDetailsStopItem: FC<ComponentProps> = ({
  stop,
  onStopSelected,
}) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: stop.placeId,
  });

  const [loadingPoi, setLoadingPoi] = useState(true);
  const [error, setError] = useState<string | undefined>();
  const [poi, setPoi] = useState<PoI | undefined>();
  const { darkMode } = useContext(UICtx);

  const getStopIcon = (stop: TourStopTemplate) => {
    if (error) {
      return (
        <Tooltip title={error}>
          <ReportGmailerrorredIcon sx={{ color: 'red' }} />
        </Tooltip>
      );
    }

    if (poi) {
      if (!poi.reviewed) {
        return (
          <Tooltip title={'Pending PoI review'}>
            <ErrorOutlineIcon sx={{ color: 'gold' }} />
          </Tooltip>
        );
      } else {
        return <CheckCircleOutlineIcon sx={{ color: 'green' }} />;
      }
    } else {
      return (
        <Tooltip title={'Missing PoI'}>
          <ReportGmailerrorredIcon sx={{ color: 'red' }} />
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingPoi(true);
        const poi = await getPoI(stop.placeId);
        if (poi) {
          setPoi(poi);
        }
      } catch (error) {
        setError('Error fetching PoI');
      }
      setLoadingPoi(false);
    };

    if (stop.placeId) {
      fetchData();
    }
  }, [stop]);

  return (
    <Stack
      ref={setNodeRef}
      direction={'row'}
      spacing={'6px'}
      alignItems={'center'}
      onClick={() => {
        onStopSelected?.(stop, poi);
      }}
      sx={{
        ':hover': {
          cursor: 'pointer',
          background: darkMode ? '#222' : '#e2e2e2',
        },
        borderRadius: '5px',
        p: '4px',
        transform: CSS.Transform.toString(transform),
      }}
      {...attributes}
      {...listeners}
    >
      {loadingPoi ? (
        <CircularProgress
          sx={{ color: darkMode ? '#777' : '#000', p: '2px' }}
          size={24}
        />
      ) : (
        getStopIcon(stop)
      )}
      <CopySmallBold
        sx={{
          flex: 1,
          fontSize: '0.8rem',
          //   userSelect: 'none',
        }}
      >
        {stop.name}
      </CopySmallBold>
      <Stack direction={'row'} sx={{ alignItems: 'center' }}>
        {poi && <PoIEditWidgetSmall poi={poi} />}
        <IconButton sx={{ p: 0 }}>
          <MoreVertIcon
            sx={{
              color: darkMode ? '#777' : '#000',
              fontSize: '1.2rem',
              '&:hover': {
                color: darkMode ? '#fff' : '#000',
              },
            }}
          />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default TourTemplateDetailsStopItem;
