import { useCallback, useContext, useEffect, useState } from 'react';
import { Stack, styled } from '@mui/material';
import MainLayout from '../MainLayout';
import NavigationBar from '../../components/Navigation/NavigationBar';
import { City, PoI } from '../../ifaces';
import { UICtx } from '../../UIProvider';
import AITravelAssistantMap from '../../components/AITravelAssistantMap';
import { GeoPoint } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { getCity } from '../../API';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import CityDetails from '../../components/CityDetails/CityDetails';
import LoadingView from '../LoadingPage/LoadingView';

const LeftSideContainer = styled(Stack)({
  flex: 1,
  p: 2,
});

const RightSideContainer = styled(Stack)({
  borderRadius: '10px',
  overflow: 'hidden',
  margin: '16px',
  flex: 1,
});

const CityDetailsPage = () => {
  // Get cityId from URL
  const { cityId } = useParams<{ cityId: string }>();
  const [loading, setLoading] = useState(false);
  const { isMobile, addNotification } = useContext(UICtx);
  const [city, setCity] = useState<City>();
  const [currentCoordinates, setCurrentCoordinates] = useState<GeoPoint>();
  const [selectedZoom, setSelectedZoom] = useState<number>();
  const [pois, setPoIs] = useState<PoI[]>([]);

  const fetchData = useCallback(
    async (cityId: string) => {
      try {
        setLoading(true);
        const city = await getCity(cityId);
        if (city) {
          setCity(city);
          setCurrentCoordinates(city.location);
          setSelectedZoom(12);
        }
      } catch (error) {
        addNotification({
          type: 'error',
          message: `Failed to fetch City.`,
        });
      }
      setLoading(false);
    },
    [addNotification],
  );

  const handleCenterOnMap = (location: GeoPoint, zoom: number) => {
    setCurrentCoordinates(location);
    setSelectedZoom(zoom);
    setTimeout(() => {
      setCurrentCoordinates(undefined);
      setSelectedZoom(undefined);
    }, 500);
  };

  useEffect(() => {
    if (cityId) {
      fetchData(cityId);
    }
  }, [cityId, fetchData]);

  return (
    <MainLayout>
      <NavigationBar displayDarkModeSwitch hideLogo />
      <Stack direction={'row'} flex={1}>
        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                           CUSTOM DRAWER                        //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <CustomDrawer />

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                           CITY DETAILS                         //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <LeftSideContainer sx={{ pr: isMobile ? 2 : 0 }}>
          {loading && <LoadingView />}
          {!loading && city && (
            <CityDetails
              city={city}
              onPoIsUpdate={setPoIs}
              onCenterOnMap={handleCenterOnMap}
            />
          )}
        </LeftSideContainer>

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                               MAP                              //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        {!isMobile && (
          <RightSideContainer>
            <AITravelAssistantMap
              selectedCenter={currentCoordinates}
              selectedZoom={selectedZoom}
              pois={pois}
            />
          </RightSideContainer>
        )}
      </Stack>
    </MainLayout>
  );
};

export default CityDetailsPage;
