import axios from 'axios';
import { GeoPoint } from 'firebase/firestore';

export const fetchImageFromURL = async (url: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(url, { responseType: 'blob' });
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data as string);
      };
    } catch (error) {
      reject(error);
    }
  });
};

export const calculateDistanceBetweenCoordinates = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) => {
  var R = 6371; // km (change this constant to get miles)
  var dLat = ((lat2 - lat1) * Math.PI) / 180;
  var dLon = ((lon2 - lon1) * Math.PI) / 180;
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
};

export const calculateZoom = (viewportNE: GeoPoint, viewportSW: GeoPoint) => {
  const distance = calculateDistanceBetweenCoordinates(
    viewportNE.latitude,
    viewportNE.longitude,
    viewportSW.latitude,
    viewportSW.longitude,
  );

  // Simplified zoom level calculation (this is a very rough estimation and might need adjustments)
  let zoom = 8; // Starting zoom level for small spans
  if (distance > 100 || distance > 100)
    zoom = 9; // Very large area
  else if (distance > 50 || distance > 50)
    zoom = 10; // Large area
  else if (distance > 20 || distance > 20)
    zoom = 12; // Medium area
  else if (distance > 10 || distance > 10)
    zoom = 13; // Small area
  else if (distance > 5 || distance > 5)
    zoom = 14; // Smaller area
  else if (distance > 1 || distance > 1) zoom = 15; // Very small area

  return zoom + 1;
};

export const floatToFormattedUSDString = (value: number): string => {
  return `$${value.toFixed(2)}`;
};

export const floatToformattedSecondsString = (value: number): string => {
  return `${value.toFixed(2)}s`;
};

export function capitalize(str: string | undefined | null) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
