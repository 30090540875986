import { Stack, StackProps } from '@mui/material';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import LoadingView from '../../pages/LoadingPage/LoadingView';

interface ListContainerProps {
  children: ReactNode;
  spacing?: number | string;
  loading?: boolean;
}

const ListContainer: FC<ListContainerProps & StackProps> = ({
  children,
  spacing,
  loading,
  sx,
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      // Ensure current is not null before accessing its properties
      if (containerRef.current) {
        const current = containerRef.current;
        const hasOverflow = current.scrollHeight > current.clientHeight;
        setIsOverflowing(hasOverflow);
      }
    };

    // Check on mount and window resize
    window.addEventListener('resize', checkOverflow);
    checkOverflow(); // Initial check

    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <Stack
      sx={{
        flex: 1,
        position: 'relative',
      }}
    >
      <Stack
        ref={containerRef}
        sx={{
          // backgroundColor: 'salmon',
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          overflowY: 'auto',
          pr: isOverflowing ? '4px' : '0',
          '&::-webkit-scrollbar': {
            width: '0.4em',
            background: 'transparent',
          },
          '&::-webkit-scrollbar-track': {
            WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            // Adjusting the border-left property to simulate spacing
            borderLeft: '4px solid transparent', // This creates a "fake" spacing on the left side
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(120,120,120,.4)',
            borderRadius: '10px',
          },
          ...sx,
        }}
        spacing={spacing}
      >
        {loading ? <LoadingView /> : children}
      </Stack>
    </Stack>
  );
};

export default ListContainer;
