import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import {
  doc,
  onSnapshot,
  collection,
  getDocs,
  getDoc,
  GeoPoint,
} from 'firebase/firestore';
import {
  CustomMarker,
  DayItinerary,
  Place,
  Tour,
  TourTemplate,
  Trip,
  TripStop,
} from './ifaces';
import { database, firestore } from './Firebase';
import { AuthCtx } from './AuthProvider';
import { AutoCompletePrediction, getTours, getTrips } from './API';
import { get, ref } from 'firebase/database';

type TravelUserSettings = {
  currentTrip?: string;
};

interface DataProps {
  isLoading: boolean;
  setLoading: (state: boolean) => void;
  functionalCookiesAccepted: boolean;

  // Trips
  trips: Trip[];
  setTrips: (trips: Trip[]) => void;
  fetchAndSelectTrip: (tripId: string) => Promise<void>;
  fetchTrips: () => Promise<void>;

  selectedTrip?: Trip;
  setSelectedTrip: (trip: Trip) => void;

  // Tours
  tours: Tour[];
  fetchTours: (
    lat: number,
    lng: number,
    radius: number | undefined,
  ) => Promise<void>;

  // Tour Templates
  tourTemplates: TourTemplate[];
  fetchTourTemplates: () => Promise<void>;

  // Trip Stops
  tripStops: TripStop[];
  fetchTripStops: (tripId: string) => Promise<void>;

  // Day Itineraries
  dayItineraries: DayItinerary[];
  fetchDayItineraries: (tripId: string) => Promise<void>;

  // Map
  markers: CustomMarker[];
  setMarkers: React.Dispatch<React.SetStateAction<CustomMarker[]>>;
  viewport?: {
    viewportNE: GeoPoint;
    viewportSW: GeoPoint;
  };
  setViewport: React.Dispatch<
    React.SetStateAction<
      | {
          viewportNE: GeoPoint;
          viewportSW: GeoPoint;
        }
      | undefined
    >
  >;
  zoom?: number;
  setZoom: React.Dispatch<React.SetStateAction<number | undefined>>;

  // Search Bar
  selectedPrediction?: AutoCompletePrediction;
  setSelectedPrediction: (prediction: AutoCompletePrediction) => void;
  selectedPlace?: Place;
  setSelectedPlace: (place: Place) => void;
}

export const DataCtx = createContext({} as DataProps);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useContext(AuthCtx);

  const [isLoading, setLoading] = useState<boolean>(true);

  const [cookies, setCookie] = useCookies([
    'functional_cookies_accepted',
    'selected_place_id',
    'last_selected_prediction',
  ]);

  const [functionalCookiesAccepted, setFunctionalCookiesAccepted] =
    useState(false);

  // Trips
  const [trips, setTrips] = useState<Trip[]>([]);
  const [selectedTrip, setSelectedTrip] = useState<Trip>();

  // Trip Stops have to be fetched sepparatly
  // Because they are stored in a sub-collection
  const [tripStops, setTripStops] = useState<TripStop[]>([]);

  // Day Itineraries have to be fetched sepparatly
  // Because they are stored in a sub-collection
  const [dayItineraries, setDayItineraries] = useState<DayItinerary[]>([]);

  // Tours
  const [tours, setTours] = useState<Tour[]>([]);

  // Tour Templates
  const [tourTemplates, setTourTemplates] = useState<TourTemplate[]>([]);

  // Map State
  const [markers, setMarkers] = useState<CustomMarker[]>([]);
  const [viewport, setViewport] = useState<{
    viewportNE: GeoPoint;
    viewportSW: GeoPoint;
  }>();
  const [zoom, setZoom] = useState<number>();

  // Search Bar State
  const [selectedPrediction, setSelectedPrediction] =
    useState<AutoCompletePrediction>();
  const [selectedPlace, setSelectedPlace] = useState<Place>();

  const fetchAndSelectTrip = async (tripId: string): Promise<void> => {
    const docRef = doc(firestore, 'trips', tripId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setSelectedTrip(docSnap.data() as Trip);
    } else {
      console.log('No such document!');
    }
  };

  const fetchTrips = useCallback(async (): Promise<void> => {
    if (!user) {
      return;
    }
    try {
      setLoading(true);
      const resp = await getTrips();
      setTrips(resp.result);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [user]);

  const fetchTourTemplates = useCallback(async (): Promise<void> => {
    if (!user) {
      return;
    }
    try {
      // Load templates from firebase realtime database
      const templatesRef = ref(database, 'tour_generation_templates');

      get(templatesRef)
        .then(snapshot => {
          if (snapshot.exists()) {
            const templates: TourTemplate[] = [];
            const data = snapshot.val() as { [key: string]: TourTemplate };
            for (const key in data) {
              templates.push(data[key]);
            }
            setTourTemplates(templates);
          } else {
            console.log('No data available');
          }
        })
        .catch(error => {
          console.error(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  const fetchTours = useCallback(
    async (
      lat: number,
      lng: number,
      radius: number | undefined,
    ): Promise<void> => {
      try {
        setLoading(true);
        const resp = await getTours({
          lat,
          lng,
          radius,
        });
        setTours(resp.result);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [],
  );

  const handleSetSelectedPrediction = (prediction: AutoCompletePrediction) => {
    setSelectedPrediction(prediction);
    console.log('setting cookie prediction:', prediction);
    setCookie('last_selected_prediction', prediction, {
      path: '/',
      maxAge: 31536000,
    });
  };

  // Check if user has accepted functional cookies
  useEffect(() => {
    if (
      cookies.functional_cookies_accepted === 'true' ||
      cookies.functional_cookies_accepted === true
    ) {
      setFunctionalCookiesAccepted(true);
    } else {
      setFunctionalCookiesAccepted(false);
    }

    try {
      if (cookies.last_selected_prediction) {
        const lastPrediction =
          cookies.last_selected_prediction as AutoCompletePrediction;
        if (
          cookies.selected_place_id &&
          lastPrediction.place_id !== cookies.selected_place_id
        ) {
          return;
        }
        setSelectedPrediction(lastPrediction);
      }
    } catch (error) {
      console.error('Error parsing last selected prediction:', error);
    }
  }, [cookies]); // Re-run effect if the cookie value changes

  useEffect(() => {
    if (!user) {
      return;
    }
    const unsub = onSnapshot(
      doc(firestore, 'user_settings', user.uid),
      document => {
        if (!document.exists()) {
          return;
        }
        const userSettings = document.data() as TravelUserSettings;
        if (userSettings.currentTrip) {
          setSelectedTrip(
            trips.find(trip => trip.id === userSettings.currentTrip),
          );
        }
      },
    );
    return unsub;
  }, [user, trips]);

  const fetchTripStops = async (tripId: string) => {
    const querySnapshot = await getDocs(
      collection(firestore, 'trips', tripId, 'stops'),
    );
    const results: TripStop[] = [];
    querySnapshot.forEach(doc => {
      results.push(doc.data() as TripStop);
    });
    setTripStops(results);
  };

  const fetchDayItineraries = async (tripId: string) => {
    const querySnapshot = await getDocs(
      collection(firestore, 'trips', tripId, 'day_itineraries'),
    );
    const results: DayItinerary[] = [];
    querySnapshot.forEach(doc => {
      results.push(doc.data() as DayItinerary);
    });
    setDayItineraries(results);
  };

  const handleSetSelectedTrip = (trip: Trip) => {
    // TODO: Missing update of the currentTrip in the user settings
    setSelectedTrip(trip);
  };

  return (
    <DataCtx.Provider
      value={{
        isLoading,
        setLoading,
        functionalCookiesAccepted: functionalCookiesAccepted,

        // Trips
        trips: trips,
        setTrips: setTrips,
        selectedTrip: selectedTrip,
        setSelectedTrip: handleSetSelectedTrip,
        fetchAndSelectTrip: fetchAndSelectTrip,
        fetchTrips: fetchTrips,

        // Tours
        tours: tours,
        fetchTours: fetchTours,

        // Tour Templates
        tourTemplates: tourTemplates,
        fetchTourTemplates: fetchTourTemplates,

        // Trip Stops
        tripStops: tripStops,
        fetchTripStops: fetchTripStops,

        // Day Itineraries
        dayItineraries: dayItineraries,
        fetchDayItineraries: fetchDayItineraries,

        // Map
        markers: markers,
        setMarkers: setMarkers,
        viewport: viewport,
        setViewport: setViewport,
        zoom: zoom,
        setZoom: setZoom,

        // Search Bar
        selectedPrediction: selectedPrediction,
        setSelectedPrediction: handleSetSelectedPrediction,
        selectedPlace: selectedPlace,
        setSelectedPlace: setSelectedPlace,
      }}
    >
      {children}
    </DataCtx.Provider>
  );
};
