import {
  FC,
  useContext,
  useState,
  ElementType,
  ReactNode,
  ChangeEvent,
} from 'react';
import {
  BigTitle,
  Copy,
  CopyBold,
  CopySmall,
  CopySmallBold,
  Header1,
  Header2,
  Header3,
  MAIN_FONT,
  Quote,
  QuoteBold,
  getFontWeight,
  getRawFontSize,
} from './Typography';
import { IconButton, InputAdornment, InputBase, Stack } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { UICtx } from '../UIProvider';
import { FixedColors } from '../theme';

interface InputTypographyProps {
  children?: ReactNode;
  TypographyComponent: ElementType;
  disabled?: boolean;
  multiline?: boolean;
  editOnInit?: boolean;
  onSubmit?: (value: string) => void;
  disableUnderline?: boolean;
  onCancel?: () => void;
  fontSize?: string;
}

// Generalized EditableTypography component
export const EditableTypography: FC<InputTypographyProps> = ({
  children,
  TypographyComponent,
  disabled,
  multiline,
  disableUnderline,
  onSubmit,
  editOnInit = false,
  onCancel,
  fontSize,
}) => {
  const { darkMode, isMobile } = useContext(UICtx);
  const [editModeOn, setEditModeOn] = useState(editOnInit);
  const [localInputState, setLocalInputState] = useState<string>(
    children as string,
  );

  const handleTextChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newVal = event.target.value;
    setLocalInputState(newVal);
  };

  const handleSave = () => {
    // Save the new value
    setEditModeOn(false);
    onSubmit?.(localInputState);
  };

  const handleDismiss = () => {
    // Save the new value
    // setEditModeOn(false);
    // setLocalInputState(children as string);
    if (localInputState === '') {
      onCancel?.();
    } else {
      setLocalInputState('');
    }
  };

  // Handle enter key press
  const handleKeyPress = async (event: React.KeyboardEvent) => {
    if (!multiline && event.key === 'Enter') {
      handleSave();
    }
    if (event.key === 'Escape') {
      handleDismiss();
    }
  };

  const getRawFontSizeForComponent = () => {
    if (TypographyComponent === BigTitle)
      return getRawFontSize('bigTitle', isMobile);
    if (TypographyComponent === Header1)
      return getRawFontSize('header1', isMobile);
    if (TypographyComponent === Header2)
      return getRawFontSize('header2', isMobile);
    if (TypographyComponent === Header3)
      return getRawFontSize('header3', isMobile);
    if (TypographyComponent === Copy) return getRawFontSize('copy', isMobile);
    if (TypographyComponent === CopyBold)
      return getRawFontSize('copyBold', isMobile);
    if (TypographyComponent === CopySmall)
      return getRawFontSize('copySmall', isMobile);
    if (TypographyComponent === CopySmallBold)
      return getRawFontSize('copySmallBold', isMobile);
    if (TypographyComponent === Quote) return getRawFontSize('quote', isMobile);
    if (TypographyComponent === QuoteBold)
      return getRawFontSize('quoteBold', isMobile);
    return 16;
  };

  const getFontWeightForComponent = () => {
    if (TypographyComponent === BigTitle) return getFontWeight('bigTitle');
    if (TypographyComponent === Header1) return getFontWeight('header1');
    if (TypographyComponent === Header2) return getFontWeight('header2');
    if (TypographyComponent === Header3) return getFontWeight('header3');
    if (TypographyComponent === Copy) return getFontWeight('copy');
    if (TypographyComponent === CopyBold) return getFontWeight('copyBold');
    if (TypographyComponent === CopySmall) return getFontWeight('copySmall');
    if (TypographyComponent === CopySmallBold)
      return getFontWeight('copySmallBold');
    if (TypographyComponent === Quote) return getFontWeight('quote');
    if (TypographyComponent === QuoteBold) return getFontWeight('quoteBold');
    return 400;
  };

  return (
    <Stack
      direction={'row'}
      alignItems="center"
      sx={{
        position: 'relative',
        minHeight: !multiline ? getRawFontSizeForComponent() + 6 : undefined,
        // background: multiline ? (darkMode ? '#151515' : '#FFF') : 'none',
        borderRadius: '6px',
        overflow: !multiline ? 'hidden' : undefined,
      }}
      spacing={'5px'}
    >
      {editModeOn && (
        <InputBase
          autoFocus
          fullWidth
          onChange={handleTextChange}
          disabled={disabled}
          value={localInputState}
          onKeyDown={handleKeyPress}
          multiline={multiline}
          sx={{
            color: darkMode ? '#DDD' : 'black',
            pr: 0,
            fontSize: fontSize || `${getRawFontSizeForComponent()}px`,
            fontFamily: MAIN_FONT,
            fontWeight: getFontWeightForComponent(),
            // borderBottom:
            //   !darkMode || disableUnderline
            //     ? undefined
            //     : disabled
            //       ? '1px solid #444'
            //       : '1px solid #CCC',
            position: !multiline ? 'absolute' : undefined,
            flex: 1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // background: darkMode ? '#171717' : '#FFF',
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={multiline ? handleSave : handleDismiss}
                sx={{
                  color: disabled ? '#444' : '#B1B1A1',
                  p: '2px',
                  mt: '2px',
                }}
                disableRipple
              >
                {!multiline ? (
                  <CloseIcon
                    sx={{
                      fontSize: '1rem',
                    }}
                  />
                ) : (
                  <SaveIcon
                    sx={{
                      fontSize: '1rem',
                    }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      )}
      <Stack
        sx={{
          display: multiline && editModeOn ? 'none' : 'flex',
          flex: 1,
        }}
      >
        <TypographyComponent
          component={'p'}
          sx={{
            whiteSpace: multiline ? 'pre-wrap' : undefined, // Allow line breaks
            color: editModeOn
              ? 'transparent'
              : darkMode
                ? 'white'
                : FixedColors.almostBlack,
            fontSize: fontSize || `${getRawFontSizeForComponent()}px`,
          }}
        >
          {localInputState || children}
        </TypographyComponent>
      </Stack>
      {!editModeOn && (
        <IconButton sx={{ p: 0 }} onClick={() => setEditModeOn(true)}>
          <ModeEditIcon
            sx={{ color: darkMode ? '#777' : '#000', fontSize: '1rem' }}
          />
        </IconButton>
      )}
    </Stack>
  );
};

interface EditableTypographyProps {
  children?: ReactNode;
  disabled?: boolean;
  multiline?: boolean;
  onSubmit?: (value: string) => void;
  editOnInit?: boolean;
  disableUnderline?: boolean;
  fontSize?: string;
  onCancel?: () => void;
}

// For BigTitle
export const EditableBigTitle: FC<EditableTypographyProps> = props => (
  <EditableTypography TypographyComponent={BigTitle} {...props} />
);

// For Header1
export const EditableHeader1: FC<EditableTypographyProps> = props => (
  <EditableTypography TypographyComponent={Header1} {...props} />
);

// For Header2
export const EditableHeader2: FC<EditableTypographyProps> = props => (
  <EditableTypography TypographyComponent={Header2} {...props} />
);

// For Header3
export const EditableHeader3: FC<EditableTypographyProps> = props => (
  <EditableTypography TypographyComponent={Header3} {...props} />
);

// For Copy
export const EditableCopy: FC<EditableTypographyProps> = props => (
  <EditableTypography TypographyComponent={Copy} {...props} />
);

// For bold text
export const EditableCopyBold: FC<EditableTypographyProps> = props => (
  <EditableTypography TypographyComponent={CopyBold} {...props} />
);

// For CopySmall
export const EditableCopySmall: FC<EditableTypographyProps> = props => (
  <EditableTypography TypographyComponent={CopySmall} {...props} />
);

// For small bold text
export const EditableCopySmallBold: FC<EditableTypographyProps> = props => (
  <EditableTypography TypographyComponent={CopySmallBold} {...props} />
);

// For QuoteBold
export const EditableQuoteBold: FC<EditableTypographyProps> = props => (
  <EditableTypography TypographyComponent={QuoteBold} {...props} />
);

// For Quote
export const EditableQuote: FC<EditableTypographyProps> = props => (
  <EditableTypography TypographyComponent={Quote} {...props} />
);
