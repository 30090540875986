import { FC, useContext } from 'react';
import { ButtonProps } from '@mui/material';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { UICtx } from '../../UIProvider';
import PLessIconButton from './PLessIconButton';

const UpdateCityPoIsButton: FC<{ fontSize?: string } & ButtonProps> = ({
  onClick,
  sx,
  fontSize,
}) => {
  const { darkMode } = useContext(UICtx);

  return (
    <PLessIconButton
      onClick={onClick}
      sx={{
        color: darkMode ? '#777' : '#000',
        ...sx,
      }}
    >
      <AdsClickIcon
        sx={{
          fontSize: fontSize ? fontSize : '1.2rem',
        }}
      />
    </PLessIconButton>
  );
};

export default UpdateCityPoIsButton;
