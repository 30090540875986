import { FC, useContext, useEffect, useState } from 'react';
import Map, { Layer, Marker, Source } from 'react-map-gl';
import type { Feature } from 'geojson';
import { toGeoJSON } from '@mapbox/polyline';
import { Box, Stack } from '@mui/material';
import { UICtx } from '../UIProvider';
import { CustomMarker, PoI, Tour, TourTemplate } from '../ifaces';
import { GeoPoint } from 'firebase/firestore';
import MarkerImgRed from '../assets/markers/map-marker.svg';
import MarkerImgGreen from '../assets/markers/map-marker-green.svg';
import MarkerImgGray from '../assets/markers/map-marker-gray.svg';
import MarkerImgYellow from '../assets/markers/map-marker-yellow.svg';
import DestinationMarker from '../assets/markers/tour-end-icon.svg';
import TourStartMarker from '../assets/markers/tour-start-icon.svg';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import PoIDetailsMini from './PoIDetails/PoIDetailsMini';
import { CopySmallBold } from './Typography';
// import PoIDetailsMini from './PoIDetails/PoIDetailsMini';

interface AITravelAssistantMapProps {
  selectedCenter?: GeoPoint;
  selectedZoom?: number;
  // mapViewState?: MapViewState;
  viewport?: {
    viewportNE: GeoPoint;
    viewportSW: GeoPoint;
  };
  selectedPoI?: PoI;
  selectedTourTemplate?: TourTemplate;
  selectedTour?: Tour;
  pois?: PoI[];
  tourTemplates?: TourTemplate[];
  markers?: CustomMarker[];
  tourPoIIds?: string[];
  // encodedPolyline?: string;
  onSelectedPoI?: (poi: PoI) => void;
  onSaveCenterAndZoom?: (center: GeoPoint, zoom: number) => Promise<void>;
}

interface MapViewState {
  latitude: number;
  longitude: number;
  zoom: number;
}

const AITravelAssistantMap: FC<AITravelAssistantMapProps> = ({
  selectedCenter,
  selectedZoom = 15,
  selectedPoI,
  selectedTourTemplate,
  selectedTour,
  markers = [],
  pois = [],
  tourPoIIds,
  viewport,
  // encodedPolyline,
  onSelectedPoI,
  onSaveCenterAndZoom,
}) => {
  const INITIAL_CENTER = { latitude: 52, longitude: 13 };
  const [polyline, setPolyline] = useState<Feature | null>(null);
  const [selectedMarker, setSelectedMarker] = useState<CustomMarker | null>(
    null,
  );
  const [selectedMapPoI, setSelectedMapPoI] = useState<PoI>();
  const [selectedMapTourTemplate, setSelectedMapTourTemplate] =
    useState<TourTemplate>();
  const [displayPoIDetails, setDisplayPoIDetails] = useState(false);
  const [computedMarkers, setComputedMarkers] = useState<CustomMarker[]>([]);
  const [updatableCenter, setUpdatableCenter] = useState<GeoPoint>();
  const [updatedCenter, setUpdatedCenter] = useState<GeoPoint>();
  const [destMarker, setDestMarker] = useState<CustomMarker | null>();
  const [startMarker, setStartMarker] = useState<CustomMarker | null>();

  const { darkMode } = useContext(UICtx);
  const [viewState, setViewState] = useState<MapViewState>({
    latitude: INITIAL_CENTER.latitude,
    longitude: INITIAL_CENTER.longitude,
    zoom: 3.5,
  });

  useEffect(() => {
    if (selectedCenter) {
      setViewState(currentViewState => ({
        ...currentViewState,
        latitude: selectedCenter.latitude,
        longitude: selectedCenter.longitude,
        zoom: selectedZoom,
      }));
      setUpdatableCenter(undefined);
      setUpdatedCenter(undefined);
      // console.log('Selected center useEffect:', selectedCenter);
    }
  }, [selectedCenter, selectedZoom]);

  useEffect(() => {
    if (selectedPoI) {
      setViewState(currentViewState => ({
        ...currentViewState,
        zoom: currentViewState.zoom < 15 ? 15 : currentViewState.zoom,
        latitude: selectedPoI.location.latitude,
        longitude: selectedPoI.location.longitude,
      }));
      setSelectedMapPoI(selectedPoI);
      setUpdatableCenter(undefined);
      setUpdatedCenter(undefined);
      setSelectedMapTourTemplate(undefined);
      setPolyline(null);
      setStartMarker(null);
      setDestMarker(null);
      // console.log('Selected PoI useEffect:', selectedPoI);
    }
  }, [selectedPoI]);

  useEffect(() => {
    if (selectedTourTemplate) {
      if (selectedTourTemplate.encodedPolyline) {
        processEncodedPolyline(selectedTourTemplate.encodedPolyline);
      }
      if (selectedTourTemplate.viewportCenter) {
        const center = selectedTourTemplate.viewportCenter;
        const zoom = selectedTourTemplate.zoom;
        setViewState(currentViewState => ({
          ...currentViewState,
          latitude: center.latitude,
          longitude: center.longitude,
          zoom: zoom ? zoom : currentViewState.zoom,
        }));
        setUpdatableCenter(new GeoPoint(center.latitude, center.longitude));
      }
      setSelectedMapTourTemplate(selectedTourTemplate);
      setSelectedMapPoI(undefined);
      // console.log('selectedTourTemplate useEffect:', selectedTourTemplate);
    }
    //  else {
    //   setPolyline(null);
    //   setStartMarker(null);
    //   setDestMarker(null);
    // }
  }, [selectedTourTemplate]);

  const processEncodedPolyline = (encodedPolyline: string) => {
    try {
      const polylineGeoJSON: Feature = {
        type: 'Feature',
        properties: {},
        geometry: toGeoJSON(encodedPolyline),
      };
      setPolyline(polylineGeoJSON);

      // Check first if coordinates are available in Geometry or GeometryCollection
      const geometry = polylineGeoJSON.geometry;
      if (geometry.type === 'LineString') {
        const coordinates = geometry.coordinates;
        if (coordinates.length > 0) {
          // Set destination marker
          const lastCoordinate = coordinates[coordinates.length - 1];
          setDestMarker({
            id: 'destination',
            group: 'gray',
            lat: lastCoordinate[1],
            lng: lastCoordinate[0],
          });

          // Set start marker
          const firstCoordinate = coordinates[0];
          setStartMarker({
            id: 'start',
            group: 'gray',
            lat: firstCoordinate[1],
            lng: firstCoordinate[0],
          });
        }
      }
    } catch (error) {
      console.error('Error parsing polyline:', error);
    }
  };

  useEffect(() => {
    let customMarkers: CustomMarker[] = [];

    if (selectedMapTourTemplate !== undefined) {
      customMarkers = pois.map(poi => ({
        id: poi.id,
        group: !selectedMapTourTemplate.stops
          .map(stop => stop.placeId)
          .includes(poi.id)
          ? 'gray'
          : poi.reviewed
            ? 'green'
            : 'red',
        lat: poi.location.latitude,
        lng: poi.location.longitude,
      }));
    } else if (selectedMapPoI) {
      customMarkers = pois.map(poi => ({
        id: poi.id,
        group:
          poi.id !== selectedMapPoI.id
            ? 'gray'
            : selectedMapPoI.reviewed
              ? 'green'
              : 'red',
        lat: poi.location.latitude,
        lng: poi.location.longitude,
      }));
    } else {
      customMarkers = pois.map(poi => ({
        id: poi.id,
        group: poi.reviewed ? 'green' : 'red',
        lat: poi.location.latitude,
        lng: poi.location.longitude,
      }));
    }

    // Only update state if computedMarkers actually changed
    if (JSON.stringify(customMarkers) !== JSON.stringify(computedMarkers)) {
      setComputedMarkers(customMarkers);
    }
  }, [pois, selectedMapTourTemplate, selectedMapPoI, computedMarkers]); // Note: Including computedMarkers in dependencies can cause its issues, be cautious

  const getMarkerImage = (marker: CustomMarker, poi?: PoI) => {
    if (poi && poi.id === selectedMapPoI?.id) {
      if (poi.reviewed) {
        return MarkerImgGreen;
      } else {
        return MarkerImgRed;
      }
    }
    if (marker.group === 'green') {
      return MarkerImgGreen;
    } else if (marker.group === 'red') {
      return MarkerImgRed;
    }
    return MarkerImgGray;
  };

  const handleMarkerClick = (marker: CustomMarker) => {
    setSelectedMarker(marker);
    setDisplayPoIDetails(true);
    const selectedPoi = pois.find(poi => poi.id === marker.id);
    setSelectedMapPoI(selectedPoi);
  };

  const getPathMarker = (type: 'start' | 'dest') => {
    if (!destMarker && type === 'dest') {
      return null;
    }

    if (!startMarker && type === 'start') {
      return null;
    }

    return (
      <Marker
        latitude={type === 'start' ? startMarker!.lat : destMarker!.lat}
        longitude={type === 'start' ? startMarker!.lng : destMarker!.lng}
        anchor="center"
      >
        <img
          src={type === 'start' ? TourStartMarker : DestinationMarker}
          alt={`marker-${type === 'start' ? 'start' : 'dest'}`}
        />
      </Marker>
    );
  };

  const getPolyLineForGeoJSON = (geoJSON: Feature) => {
    return (
      <Source id="polylineSource" type="geojson" data={geoJSON}>
        <Layer
          id="polylineLayer"
          type="line"
          source="polylineSource"
          layout={{
            'line-join': 'round',
            'line-cap': 'round',
          }}
          paint={{
            'line-color': '#5ba9fc', // Line color
            'line-width': 5, // Line width
          }}
        />
      </Source>
    );
  };

  return (
    <Stack sx={{ flex: 1, position: 'relative' }}>
      {(selectedMapPoI || updatedCenter || selectedMapTourTemplate) && (
        <Stack
          sx={{
            background: darkMode ? '#111' : 'cadetblue',
            position: 'absolute',
            top: '10px',
            left: '10px',
            height: '30px',
            width: '80px',
            zIndex: 1000,
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
          }}
          onClick={async () => {
            setDisplayPoIDetails(false);
            setSelectedMapPoI(undefined);
            setUpdatedCenter(undefined);
            setSelectedMapTourTemplate(undefined);
            setPolyline(null);
            setStartMarker(null);
            setDestMarker(null);
          }}
        >
          <CopySmallBold
            sx={{
              fontSize: '0.8rem',
              color: 'white',
            }}
          >
            CLEAR
          </CopySmallBold>
        </Stack>
      )}

      {updatedCenter && onSaveCenterAndZoom && (
        <Box
          sx={{
            background: 'salmon',
            position: 'absolute',
            top: '0',
            right: '0',
            height: '50px',
            width: '50px',
            zIndex: 1000,
            cursor: 'pointer',
          }}
          onClick={async () => {
            await onSaveCenterAndZoom(updatedCenter, viewState.zoom);
            setUpdatableCenter(updatedCenter);
            setUpdatedCenter(undefined);
            setViewState(currentViewState => ({
              ...currentViewState,
              latitude: updatedCenter.latitude,
              longitude: updatedCenter.longitude,
            }));
          }}
        >
          Save
        </Box>
      )}
      <Map
        {...viewState}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''}
        initialViewState={INITIAL_CENTER}
        onMove={evt => setViewState(evt.viewState)}
        style={{ flex: 1 }}
        mapStyle={
          darkMode
            ? 'mapbox://styles/mapbox/dark-v11'
            : 'mapbox://styles/mapbox/streets-v12'
        }
        attributionControl={false}
      >
        {/* DISPLAY CENTER FOR DEBUGGING */}
        {updatableCenter && selectedMapTourTemplate && (
          <Marker
            latitude={updatedCenter?.latitude || updatableCenter.latitude}
            longitude={updatedCenter?.longitude || updatableCenter.longitude}
            draggable={true}
            anchor="bottom"
            onDrag={evt => {
              // console.log('Dragged marker:', evt);
              const newCenter = {
                latitude: evt.lngLat.lat,
                longitude: evt.lngLat.lng,
              };
              const geoPoint = new GeoPoint(
                newCenter.latitude,
                newCenter.longitude,
              );
              setUpdatedCenter(geoPoint);
            }}
          >
            <img
              src={MarkerImgYellow}
              alt={`marker-${updatableCenter.latitude}-${updatableCenter.longitude}`}
              style={{
                transform: 'scale(0.9)',
              }}
            />
          </Marker>
        )}

        {/* TOUR DESTINATION MARKER */}

        {getPathMarker('dest')}

        {/* TOUR START MARKER */}

        {getPathMarker('start')}

        {/* DISPLAY SELECTED MARKER SEPPARATELY */}

        {selectedMarker && (
          <Marker
            key={selectedMarker.id}
            latitude={selectedMarker.lat}
            longitude={selectedMarker.lng}
            draggable={false}
            anchor="bottom"
            style={{
              zIndex: 1000,
            }}
          >
            {/* <ClickAwayListener
              onClickAway={() => {
                setSelectedMapPoI(undefined);
                setSelectedMarker(null);
              }}
            > */}
            <img
              src={getMarkerImage(selectedMarker, selectedMapPoI)}
              alt={`marker-${selectedMarker.id}`}
              style={{
                transform: 'scale(1)',
              }}
            />
            {/* </ClickAwayListener> */}
          </Marker>
        )}

        {/* DISPLAY COMPUTED MARKERS */}

        {computedMarkers.map(marker => (
          <Marker
            key={marker.id}
            latitude={marker.lat}
            longitude={marker.lng}
            draggable={false}
            anchor="bottom"
            onClick={() => {
              handleMarkerClick(marker);
            }}
            style={{
              zIndex: selectedMapPoI?.id === marker.id ? 10 : 5,
            }}
          >
            <ClickAwayListener
              onClickAway={() => {
                // setSelectedMapPoI(undefined);
                setSelectedMarker(null);
              }}
            >
              <img
                src={getMarkerImage(
                  marker,
                  pois.find(poi => poi.id === marker.id),
                )}
                alt={`marker-${marker.id}`}
                style={{
                  transform:
                    selectedMapPoI?.id === marker.id
                      ? 'scale(1)'
                      : 'scale(0.8)',
                  cursor: 'pointer',
                }}
              />
            </ClickAwayListener>
          </Marker>
        ))}

        {/* DISPLAY POLYLINE */}

        {polyline && getPolyLineForGeoJSON(polyline)}
      </Map>
      <Stack
        sx={{
          display: displayPoIDetails ? 'flex' : 'none',
          height: '100px',
          position: 'absolute',
          bottom: 10,
          left: 10,
          right: 10,
          zIndex: 1000,
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <PoIDetailsMini
          poi={selectedMapPoI}
          onClose={() => {
            setDisplayPoIDetails(false);
            setSelectedMapPoI(undefined);
          }}
        />
      </Stack>
    </Stack>
  );
};

export default AITravelAssistantMap;
