import { FC, useContext, useEffect, useState } from 'react';

// Material UI Components
import { Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// Custom
import { UICtx } from '../../UIProvider';
import { getPoI } from '../../API';

interface ComponentProps {
  poiId?: string;
  reviewedStatus?: 'error' | 'pending' | 'approved';
  errorMsg?: string;
}

const PoIStatusIcon: FC<ComponentProps> = ({
  poiId,
  reviewedStatus,
  errorMsg,
}) => {
  const [status, setStatus] = useState<
    'error' | 'pending' | 'approved' | undefined
  >(reviewedStatus);
  const [loadingPoi, setLoadingPoi] = useState(false);
  const [error, setError] = useState<string | undefined>(errorMsg);
  // const [poi, setPoi] = useState<PoI | undefined>();
  const { darkMode } = useContext(UICtx);

  const getStatusIcon = () => {
    switch (status) {
      case 'error':
        return (
          <Tooltip title={error}>
            <ReportGmailerrorredIcon sx={{ color: 'red' }} />
          </Tooltip>
        );
      case 'pending':
        return (
          <Tooltip title={error || 'PoI not approved yet'}>
            <ErrorOutlineIcon sx={{ color: 'goldenrod' }} />
          </Tooltip>
        );
      case 'approved':
        return <CheckCircleOutlineIcon sx={{ color: 'green' }} />;
      default:
        return (
          <Tooltip title={'Missing PoI'}>
            <ReportGmailerrorredIcon sx={{ color: 'red' }} />
          </Tooltip>
        );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!poiId) {
          setLoadingPoi(false);
          return;
        }
        setLoadingPoi(true);
        const poi = await getPoI(poiId);
        if (poi) {
          // setPoi(poi);
          setStatus(poi.reviewed ? 'approved' : 'pending');
          if (!poi.reviewed) {
            setError('Pending PoI review for: ' + poi.name);
          }
        }
      } catch (error) {
        setError('Error fetching PoI');
        setStatus('error');
      }
      setLoadingPoi(false);
    };

    if (!reviewedStatus && poiId) {
      fetchData();
    }
  }, [reviewedStatus, poiId]);

  return loadingPoi ? (
    <CircularProgress
      sx={{ color: darkMode ? '#777' : '#000', p: '2px' }}
      size={24}
    />
  ) : (
    getStatusIcon()
  );
};

export default PoIStatusIcon;
