import { FC, useState } from 'react';
import { City, PoI, PoICandidate } from '../../ifaces';
import PoIItem from './PoIItem';
import ListContainer from '../containers/ListContainer';
import PoICandidateItem from './PoICandidateItem';
import { GeoPoint } from 'firebase/firestore';

interface PoIsListProps {
  loading?: boolean;
  pois?: PoI[];
  candidates?: PoICandidate[];
  onSubmitPoIsPreferences?: (
    PoIs_to_skip: PoI[],
    PoIs_to_include: PoI[],
  ) => void;
  onPoISelected?: (poi: PoI) => void;
  onCenterOnMap?: (location: GeoPoint) => void;
  onPoIDeleted?: (poi: PoI) => void;
  onAddPoIToCity?: (poi: PoI) => void;
  onPoIApproved?: (poi: PoI) => void;
  city?: City;
}

const PoIsList: FC<PoIsListProps> = ({
  loading,
  pois = [],
  candidates = [],
  onPoISelected,
  onCenterOnMap,
  onPoIDeleted,
  onAddPoIToCity,
  onPoIApproved,
  city,
}) => {
  const [selectedPoI, setSelectedPoI] = useState<PoI>();

  const handleSelectPoI = (poi: PoI) => {
    setSelectedPoI(poi);
    onPoISelected?.(poi);
  };

  const handleCenterOnMap = (poi: PoI) => {
    onCenterOnMap?.(poi.location);
    onPoISelected?.(poi);
  };

  return (
    <ListContainer spacing={1} loading={loading}>
      {pois.map((poi, index) => {
        return (
          <PoIItem
            key={index}
            poi={poi}
            onSelect={() => handleSelectPoI(poi)}
            onCenterOnMap={() => handleCenterOnMap(poi)}
            isSelected={poi.id === selectedPoI?.id}
            onPoIDeleted={() => onPoIDeleted?.(poi)}
            onAddToCity={() => onAddPoIToCity?.(poi)}
            onPoIApproved={() => onPoIApproved?.(poi)}
            city={city}
          />
        );
      })}
      {candidates.map((candidate, index) => {
        return (
          <PoICandidateItem
            key={index}
            candidate={candidate}
            onCenterOnMap={() => onCenterOnMap?.(candidate.location)}
            city={city}
          />
        );
      })}
    </ListContainer>
  );
};

export default PoIsList;
