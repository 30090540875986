import { FC, useContext, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { CopySmall, Header3 } from '../Typography';
import ChatInputField from '../InputField';
import DurationInput from '../DurationInput/DurationInput';
import { AutoCompletePrediction } from '../../API';
import { UICtx } from '../../UIProvider';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '15px',
  p: 5,
};

interface PlaceSelectionModalProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onConfirm?: (place_id: string, duration: number) => void;
}

export enum PlaceSelectionModalState {
  GET_DESTINATION = 0,
  GET_DURATION = 1,
}

const PlaceSelectionModal: FC<PlaceSelectionModalProps> = ({
  open,
  setOpen,
  onConfirm,
}) => {
  const { darkMode } = useContext(UICtx);

  const [destination, setDestination] = useState<string>();
  const [duration, setDuration] = useState<number>(0);

  const [assistantState, setAssistantState] = useState<
    PlaceSelectionModalState | undefined
  >(PlaceSelectionModalState.GET_DESTINATION);

  const handleClose = () => setOpen(false);

  const handlePlaceSelected = async (place: AutoCompletePrediction) => {
    setAssistantState(PlaceSelectionModalState.GET_DURATION);
    setDestination(place.place_id);
  };

  const handleStayLengthChange = (length: number) => {
    setDuration(length);
    handleConfirm();
  };

  const handleConfirm = () => {
    if (onConfirm && destination && duration > 0) {
      onConfirm(destination, duration);
      handleClose();
    }
  };

  return (
    <Modal
      aria-labelledby="stop-confirm-modal"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={{ ...style, background: darkMode ? '#141414' : '#fff' }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 20,
              right: 20,
              color: darkMode ? '#777' : '#000',
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* //////////////////////////////////////////////////////////////////////// */}
          {/* ////                       GET INITIAL DESTINATION                  //// */}
          {/* //////////////////////////////////////////////////////////////////////// */}

          {assistantState === PlaceSelectionModalState.GET_DESTINATION && (
            <Stack
              sx={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                p: 4,
              }}
              spacing={1}
            >
              <Stack spacing={2} alignItems={'center'}>
                <Header3
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  Where are you travelling to?
                </Header3>
                <CopySmall sx={{ color: '#666' }}>
                  Example: Berlin, Germany
                </CopySmall>
              </Stack>
              <ChatInputField onPlaceSelected={handlePlaceSelected} />
            </Stack>
          )}

          {/* //////////////////////////////////////////////////////////////////////// */}
          {/* ////                      GET INITIAL TRIP DURATION                 //// */}
          {/* //////////////////////////////////////////////////////////////////////// */}

          {assistantState === PlaceSelectionModalState.GET_DURATION && (
            <DurationInput onDurationSelected={handleStayLengthChange} />
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default PlaceSelectionModal;
