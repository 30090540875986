import { FC, useCallback, useContext, useEffect, useState } from 'react';

// MUI
import { Stack } from '@mui/material';

// Custom
import { Language, PoI, PoIDescription } from '../../ifaces';
import { CopySmall, CopySmallBold } from '../Typography';
import StyledStack from '../containers/StyledStack';
import { enqueuePoIDescriptionGeneration, getPoIDescriptions } from '../../API';
import { UICtx } from '../../UIProvider';
import { FlagIcon, languageToFlag } from '../../Constants';
import LangGenPicker from '../LanguagePicker/LangGenPicker';
import { SUPPORTED_LANGUAGES } from '../../Constants';

interface ComponentProps {
  poi: PoI;
  onUpdate?: (descriptions: PoIDescription[]) => void;
}

const PoIDescriptions: FC<ComponentProps> = ({ poi, onUpdate }) => {
  const { darkMode, addNotification } = useContext(UICtx);
  const [descriptions, setDescriptions] = useState<PoIDescription[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedDescription, setSelectedDescription] =
    useState<PoIDescription>();
  const [generatingDescription, setGeneratingDescription] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState<Language[]>([]);

  const fetchDescriptions = useCallback(async () => {
    try {
      setLoading(true);
      const descriptions = await getPoIDescriptions(poi.id);
      onUpdate?.(descriptions);
      setDescriptions(descriptions);
    } catch (error) {
      console.error('Failed to load PoI sources:', error);
      addNotification({
        type: 'error',
        message: 'Failed to fetch PoI sources',
      });
    } finally {
      setLoading(false);
    }
  }, [poi.id, addNotification, onUpdate]);

  const handleGenerateDescription = async (language: string) => {
    try {
      setGeneratingDescription(true);
      await enqueuePoIDescriptionGeneration(poi.id, language);
      await fetchDescriptions();
      addNotification({
        type: 'success',
        message: 'Description Job successfully created.',
      });
    } catch (error) {
      addNotification({
        type: 'error',
        message: `Failed to generate description.`,
      });
    }
    setGeneratingDescription(false);
  };

  useEffect(() => {
    if (poi) {
      fetchDescriptions();
    }
  }, [poi, fetchDescriptions]);

  useEffect(() => {
    if (descriptions.length > 0 && selectedDescription === undefined) {
      // Select english by default if available
      const english = descriptions.find(desc =>
        desc.language.toLowerCase().startsWith('en'),
      );
      if (english) {
        setSelectedDescription(english);
      } else {
        setSelectedDescription(descriptions[0]);
      }

      // Set available languages, by removing languages that are already in the descriptions from the supported languages
      const usedLanguages = descriptions.map(desc => desc.language);
      const available = SUPPORTED_LANGUAGES.filter(
        lang => !usedLanguages.includes(lang.code),
      );
      setAvailableLanguages(available);
    } else {
      setAvailableLanguages(SUPPORTED_LANGUAGES);
    }
  }, [descriptions, selectedDescription]);

  return (
    <Stack spacing={1}>
      <Stack
        direction={'row'}
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={'6px'}>
          <CopySmall
            sx={{
              lineHeight: '0.9rem',
              fontSize: '0.9rem',
            }}
          >
            Description
          </CopySmall>
          <LangGenPicker
            availableLanguages={availableLanguages}
            onLanguagePicked={async language =>
              await handleGenerateDescription(language)
            }
            disabled={generatingDescription || loading}
          />
        </Stack>

        <Stack direction={'row'} spacing={'4px'}>
          {descriptions.map((desc, idx) => {
            return (
              <StyledStack
                key={idx}
                direction={'row'}
                sx={{
                  alignItems: 'center',
                  cursor: 'pointer',
                  background:
                    selectedDescription !== desc
                      ? undefined
                      : darkMode
                        ? '#333'
                        : '#EEE',
                }}
                onClick={() => setSelectedDescription(desc)}
                spacing={'4px'}
              >
                <FlagIcon
                  countryCode={languageToFlag(desc.language)}
                  sx={{
                    height: '12px',
                    width: '20px',
                  }}
                />
                <CopySmallBold
                  sx={{
                    fontSize: '0.7rem',
                    lineHeight: '0.7rem',
                  }}
                >
                  {desc.language}
                </CopySmallBold>
              </StyledStack>
            );
          })}
        </Stack>
      </Stack>

      <StyledStack sx={{ p: '12px' }}>
        {selectedDescription && (
          <CopySmall
            multiline
            sx={{
              fontSize: '0.8rem',
              textAlign: 'justify',
            }}
          >
            {selectedDescription.text}
          </CopySmall>
        )}

        {descriptions.length === 0 && (
          <CopySmall
            sx={{
              color: darkMode ? '#888' : '#CCC',
              textAlign: 'center',
              p: 1,
            }}
          >
            No descriptions created yet. Please add one.
          </CopySmall>
        )}
      </StyledStack>
    </Stack>
  );
};

export default PoIDescriptions;
