import { Button, ButtonProps } from '@mui/material';
import FlightIcon from '@mui/icons-material/Flight';
import { FC, useContext } from 'react';
import { UICtx } from '../../UIProvider';

const GetStartedButton: FC<ButtonProps> = props => {
  const { darkMode, isMobile } = useContext(UICtx);

  return (
    <Button
      {...props}
      sx={{
        background: darkMode ? '#DEDEDE' : 'rgb(6, 3, 24)',
        color: darkMode ? '#0d0c22' : 'white',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        textTransform: 'none',
        borderRadius: '40px',
        py: 2,
        pr: 1,
        height: '50px',
        width: isMobile ? '170px' : '250px',
        fontSize: isMobile ? '0.9rem' : '1rem',
        fontWeight: 700,
        '&:hover': {
          background: darkMode ? '#E1E1E1' : '#0d0c22',
          color: darkMode ? '#0d0c22' : 'white',
        },
        ...props.sx,
      }}
      onClick={props.onClick}
      endIcon={<FlightIcon sx={{ ml: 1 }} />}
    >
      Try it out !
    </Button>
  );
};

export default GetStartedButton;
