import { FC } from 'react';
import { Stack } from '@mui/material';
import { Tour } from '../../ifaces';
import { CopySmallBold } from '../Typography';
import StyledBarItem from '../containers/StyledBarItem';

interface PoIItemProps {
  tour: Tour;
  locked?: boolean;
  isSelected?: boolean;
  onSelect: () => void;
}

const TourListItem: FC<PoIItemProps> = ({
  tour,
  locked = false,
  onSelect,
  isSelected = false,
}) => {
  return (
    <StyledBarItem>
      <Stack
        sx={{
          justifyContent: 'center',
          flex: 1,
          px: 1,
        }}
      >
        <CopySmallBold
          onClick={onSelect}
          sx={{
            ':hover': {
              cursor: 'pointer',
            },
          }}
        >
          {tour.title}
        </CopySmallBold>
      </Stack>
    </StyledBarItem>
  );
};

export default TourListItem;
