import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useWindowSize } from 'usehooks-ts';
import { useCookies } from 'react-cookie';
import { CustomNotification } from './ifaces';

// --------------------------------------------------------

// Context to hold Notifications Data.

type WindowSize = {
  width: number;
  height: number;
};

interface UICtxProps {
  isMobile: boolean;
  windowSize: WindowSize;
  darkMode: boolean;
  setDarkMode: (value: boolean) => void;
  locale: string;
  setLocale: (value: string) => void;
  notifications: Array<CustomNotification>;
  addNotification: (notification: CustomNotification) => void;
  removeNotification: (id: string) => void;
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
}

export const UICtx = createContext({} as UICtxProps);

export const UIProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<Array<CustomNotification>>(
    [],
  );
  const [darkMode, setDarkMode] = useState(true);
  const { height, width } = useWindowSize();
  const [cookies, setCookie] = useCookies([
    'functional_cookies_accepted',
    'dark_mode',
  ]);
  const isMobile = width !== undefined ? width < 768 : false;
  const [localisation, setLocalisation] = useState('en');
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  // Check if browser supports dark mode
  useEffect(() => {
    if (cookies.dark_mode !== undefined) {
      // Check if dark mode is enabled, as user cookie
      const darkModeEnabled =
        cookies.dark_mode === 'true' || cookies.dark_mode === true;
      setDarkMode(darkModeEnabled);
    } else {
      // If not then check system defaults
      const prefersDarkMode = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
      setDarkMode(prefersDarkMode);
    }
  }, [cookies]);

  const handleSetDarkMode = useCallback(
    (value: boolean) => {
      const functionalCookiesEnabled =
        cookies.functional_cookies_accepted === 'true';
      if (functionalCookiesEnabled) {
        document.cookie = `dark_mode=${value}; path=/; max-age=31536000`;
      }
      // Update cookie
      setCookie('dark_mode', value, { path: '/', maxAge: 31536000 });
      setDarkMode(value);
    },
    [cookies.functional_cookies_accepted, setCookie],
  );

  const removeNotification = useCallback((id: string) => {
    setNotifications(notifications =>
      notifications.filter(notification => notification.id !== id),
    );
  }, []);

  const addNotification = (notification: CustomNotification) => {
    setNotifications(notifications => [...notifications, { ...notification }]);
  };

  const handleAddNotification = useCallback(
    (notification: CustomNotification) => {
      addNotification({
        id: crypto.randomUUID(),
        ...notification,
      });
    },
    [],
  );

  const contextValue = useMemo(
    () => ({
      windowSize: { height, width },
      isMobile,
      darkMode,
      setDarkMode: handleSetDarkMode,
      locale: localisation,
      setLocale: setLocalisation,
      notifications,
      addNotification: handleAddNotification,
      removeNotification,
      drawerOpen: drawerOpen,
      setDrawerOpen: setDrawerOpen,
    }),
    [
      height,
      width,
      isMobile,
      darkMode,
      handleSetDarkMode,
      localisation,
      notifications,
      handleAddNotification,
      removeNotification,
      drawerOpen,
      setDrawerOpen,
    ],
  );

  return <UICtx.Provider value={contextValue}>{children}</UICtx.Provider>;
};
