import { FC, useContext } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UICtx } from '../../UIProvider';

interface CloseButtonProps {
  onClose?: () => void;
}

const CloseButton: FC<CloseButtonProps> = ({ onClose }) => {
  const { darkMode } = useContext(UICtx);

  return (
    <IconButton
      onClick={onClose}
      sx={{
        color: darkMode ? '#777' : '#000',
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;
