import { FC, useState } from 'react';
import { City, Place, PoI } from '../../ifaces';
import PoICreationModal from './PoICreationModal';
import PoICreateButton from './PoICreateButton';

interface WidgetProps {
  place?: Place;
  withCity?: City;
  onPoICreated?: (poi: PoI) => void;
}

const PoICreationWidget: FC<WidgetProps> = ({ withCity, onPoICreated }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           CREATION MODAL                       //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <PoICreationModal
        open={modalOpen}
        setOpen={setModalOpen}
        withCity={withCity}
        onPoICreated={onPoICreated}
        // onConfirm={handleCreateTour}
      />

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           CREATE BUTTON                        //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <PoICreateButton onClick={() => setModalOpen(true)}>
        Create
      </PoICreateButton>
    </>
  );
};

export default PoICreationWidget;
