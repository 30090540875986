import { useContext, useEffect, useState } from 'react';
import { NativeSelect, Stack } from '@mui/material';
import MainLayout from '../MainLayout';
import NavigationBar from '../../components/Navigation/NavigationBar';
import CitiesList from '../../components/CitiesList/CitiesList';
import { getCities } from '../../API';
import { City } from '../../ifaces';
import { UICtx } from '../../UIProvider';
import AITravelAssistantMap from '../../components/AITravelAssistantMap';
import { CopySmallBold } from '../../components/Typography';
import StyledStack from '../../components/containers/StyledStack';
import CityCreationWidget from './CityCreationWidget';
import { GeoPoint } from 'firebase/firestore';
import { COUNTRY_FLAG_ICONS_MAP } from '../../Constants';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import StyledInputBase from './StyledInputBase';

const CitiesPage = () => {
  const { isMobile, darkMode } = useContext(UICtx);
  const [cities, setCities] = useState<City[]>([]);
  const [currentCoordinates, setCurrentCoordinates] = useState<GeoPoint>();
  const [selectedZoom, setSelectedZoom] = useState<number>();

  const [textFilter, setTextFilter] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('name');
  const [filterByCountry, setFilterByCountry] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCities(
          sortBy,
          filterByCountry === '' ? undefined : filterByCountry,
          textFilter,
        );
        setCities(response);
      } catch (error) {
        console.error('Error fetching data for prediction', error);
      }
    };
    fetchData();
  }, [sortBy, filterByCountry, textFilter]);

  const handleCityCreated = (city: City) => {
    setCities([...cities, city]);
  };

  const handleSortByChange = (event: { target: { value: string } }) => {
    setSortBy(event.target.value as string);
  };

  const handleFilterByCountryChange = (event: {
    target: { value: string };
  }) => {
    setFilterByCountry(event.target.value as string);
  };

  return (
    <MainLayout>
      <NavigationBar displayDarkModeSwitch hideLogo />
      <Stack direction={'row'} flex={1}>
        <CustomDrawer />

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                              CITIES                            //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        <Stack
          flex={1}
          sx={{
            p: 1,
            py: 2,
            pr: isMobile ? 2 : 0,
          }}
          spacing={1}
        >
          <Stack
            direction={'row'}
            sx={{ alignItems: 'center', justifyContent: 'space-between' }}
            spacing={1}
          >
            <Stack
              direction={'row'}
              sx={{ alignItems: 'center', px: 2 }}
              spacing={1}
            >
              <StyledInputBase onValueChange={setTextFilter} />

              <NativeSelect
                value={filterByCountry}
                onChange={handleFilterByCountryChange}
                sx={{
                  '& .MuiInputBase-input': {
                    borderRadius: '8px',
                    position: 'relative',
                    color: darkMode ? 'white' : 'black',
                    fontSize: '0.8rem',
                    // Use the system font instead of the default Roboto font.
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                    textAlign: 'right',
                    '&:focus': {
                      borderColor: 'transparent',
                    },
                    '&:active': {
                      borderColor: 'transparent',
                    },
                  },
                }}
              >
                {Object.keys(COUNTRY_FLAG_ICONS_MAP).map((country, index) => {
                  return (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  );
                })}
              </NativeSelect>

              <NativeSelect
                value={sortBy}
                onChange={handleSortByChange}
                sx={{
                  '& .MuiInputBase-input': {
                    borderRadius: '8px',
                    position: 'relative',
                    color: darkMode ? 'white' : 'black',
                    fontSize: '0.8rem',
                    // Use the system font instead of the default Roboto font.
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                    textAlign: 'right',
                    '&:focus': {
                      borderColor: 'transparent',
                    },
                    '&:active': {
                      borderColor: 'transparent',
                    },
                  },
                }}
              >
                <option value={'name'}>Name</option>
                <option value={'countryShort'}>Country</option>
                <option value={'reviewed'}>Reviewed</option>
              </NativeSelect>
            </Stack>
          </Stack>
          <StyledStack flex={1}>
            <CitiesList
              cities={cities}
              onCenterOnMap={city => {
                setCurrentCoordinates(city.location);
                setSelectedZoom(10);
                // Timeout to clear selection
                setTimeout(() => {
                  setCurrentCoordinates(undefined);
                  setSelectedZoom(undefined);
                }, 5000);
              }}
            />
          </StyledStack>
          <Stack
            direction={'row'}
            sx={{ alignItems: 'center', px: 1, justifyContent: 'flex-end' }}
            spacing={1}
          >
            <CopySmallBold>Add City</CopySmallBold>
            <CityCreationWidget onCityCreated={handleCityCreated} />
          </Stack>
          {/* <Pagination
            count={10}
            // page={1}
            // Style the pagination component colors
            sx={{
              '& .MuiPaginationItem-root': {
                color: darkMode ? 'white' : 'black',
              },
              '& .MuiPaginationItem-page.Mui-selected': {
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
              },
            }}
          /> */}
        </Stack>

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                               MAP                              //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        {!isMobile && (
          <Stack
            flex={1}
            sx={{
              borderRadius: '10px',
              overflow: 'hidden',
              m: 2,
            }}
          >
            <AITravelAssistantMap
              selectedCenter={currentCoordinates}
              selectedZoom={selectedZoom}
            />
          </Stack>
        )}
      </Stack>
    </MainLayout>
  );
};

export default CitiesPage;
