import { FC, useContext } from 'react';
import { Helmet } from 'react-helmet';

// MUI
import { Box } from '@mui/material';
import { Props } from '../ifaces';
import { UICtx } from '../UIProvider';
import NotificationManager from '../components/NotificationManager/NotificationManager';

const MainLayout: FC<Props & { orientation?: 'row' | 'column' }> = ({
  children,
  orientation = 'column',
}) => {
  const { darkMode } = useContext(UICtx);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: orientation,
        position: 'relative',
        flex: 1,
        justifyContent: 'center',
      }}
    >
      <NotificationManager />
      <Helmet>
        <style type="text/css">{`
        body {
            background-color: ${darkMode ? '#000000' : '#ffffff'} !important};
        }
    `}</style>
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content={darkMode ? 'black-translucent' : 'default'}
        />
      </Helmet>
      {children}
    </Box>
  );
};

export default MainLayout;
