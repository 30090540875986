import { FC, useContext, useEffect, useState } from 'react';
import { Button, IconButton, Link, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Copy, CopySmall, CopySmallBold } from '../../components/Typography';
import { UICtx } from '../../UIProvider';
import ChatInputField from '../../components/InputField';
import {
  AutoCompletePrediction,
  getPlaceDetails,
  createCity,
  getCity,
} from '../../API';
import { City } from '../../ifaces';
import { GeoPoint } from 'firebase/firestore';
import LoadingView from '../LoadingPage/LoadingView';
import StyledStack from '../../components/containers/StyledStack';
import StyledBarItem from '../../components/containers/StyledBarItem';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px',
  p: 4,
};

interface ComponentProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onCityCreated?: (city: City) => void;
}

const CityCreationModal: FC<ComponentProps> = ({
  open,
  setOpen,
  onCityCreated,
}) => {
  const { darkMode } = useContext(UICtx);
  const [selectedPrediction, setSelectedPrediction] =
    useState<AutoCompletePrediction | null>(null);
  const [loading, setLoading] = useState(false);
  const clear = () => {
    setSelectedPrediction(null);
  };
  const [cityAlreadyExists, setCityAlreadyExists] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSelectedPrediction(null);
    setTimeout(clear, 500); // Delay due to closing animation
  };

  const handleCreateCity = async () => {
    // Create a new city
    if (selectedPrediction) {
      try {
        setLoading(true);
        const placeDetails = await getPlaceDetails({
          placeId: selectedPrediction.place_id,
        });

        const newCity: City = {
          id: placeDetails.result.placeId,
          placeId: placeDetails.result.placeId,
          name: placeDetails.result.name,
          country: placeDetails.result.country,
          countryShort: placeDetails.result.countryShort,
          location: new GeoPoint(
            placeDetails.result.location.latitude,
            placeDetails.result.location.longitude,
          ),
          viewportNE: placeDetails.result.viewportNE
            ? new GeoPoint(
                placeDetails.result.viewportNE.latitude,
                placeDetails.result.viewportNE.longitude,
              )
            : null,
          viewportSW: placeDetails.result.viewportSW
            ? new GeoPoint(
                placeDetails.result.viewportSW.latitude,
                placeDetails.result.viewportSW.longitude,
              )
            : null,
          imageName: null,
          geoHash: placeDetails.result.geoHash,
          poisCount: 0,
        };

        await createCity(newCity);
        onCityCreated?.(newCity);
        clear();
      } catch (error) {
        console.error('Failed to create city:', error);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCity = async (placeId: string) => {
      try {
        const city = await getCity(placeId);
        console.log('City:', city);
        if (city) {
          setCityAlreadyExists(true);
        } else {
          setCityAlreadyExists(false);
        }
      } catch (error) {
        console.error('Error fetching data for prediction', error);
      }
    };

    if (selectedPrediction) {
      fetchCity(selectedPrediction.place_id);
    }
  }, [selectedPrediction]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={{ ...style, background: darkMode ? '#161616' : '#fff' }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 20,
              right: 20,
              color: darkMode ? '#999' : '#000',
              '&:hover': {
                color: darkMode ? '#fff' : '#000',
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* //////////////////////////////////////////////////////////////////////// */}
          {/* ////                       SELECT CREATION METHOD                   //// */}
          {/* //////////////////////////////////////////////////////////////////////// */}

          <Stack
            sx={{
              flex: 1,
              justifyContent: 'center',
              minHeight: '50vh',
            }}
            spacing={2}
          >
            {loading ? (
              <LoadingView />
            ) : (
              <>
                <ChatInputField onPredictionSelected={setSelectedPrediction} />
                <Stack flex={1}>
                  {selectedPrediction && (
                    <>
                      <Copy>{selectedPrediction.country}</Copy>
                      <Copy>{selectedPrediction.description}</Copy>
                      <Copy>{selectedPrediction.main_text}</Copy>
                      <Copy>{selectedPrediction.place_id}</Copy>
                      <Copy>{selectedPrediction.secondary_text}</Copy>
                      <StyledBarItem>
                        <Link
                          href={`https://www.google.com/maps/place/?q=place_id:${selectedPrediction.place_id}`}
                          target="_blank"
                          rel="noreferrer"
                          sx={{
                            color: darkMode ? '#fff' : '#000',
                            fontSize: '0.7rem',
                          }}
                        >
                          Open in Google Maps
                        </Link>
                      </StyledBarItem>
                    </>
                  )}
                </Stack>
              </>
            )}
          </Stack>

          {/* //////////////////////////////////////////////////////////////////////// */}
          {/* ////                         BOTTOM ACTIONS BAR                     //// */}
          {/* //////////////////////////////////////////////////////////////////////// */}

          {!loading && selectedPrediction && (
            <Stack
              sx={{
                alignItems: 'flex-end',
                display: 'flex',
              }}
            >
              {cityAlreadyExists && (
                <CopySmall
                  sx={{ color: cityAlreadyExists ? 'red' : 'inherit' }}
                >
                  City already exists
                </CopySmall>
              )}

              <Button
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: !selectedPrediction ? '#999' : 'green',
                  height: '50px',
                  width: '150px',
                  cursor: 'pointer',
                  userSelect: 'none',
                  borderRadius: '10px',
                  color: 'white',
                  textTransform: 'none',
                  ':hover': {
                    background: !selectedPrediction ? '#999' : 'green',
                    filter: 'contrast(200%)',
                  },
                  ':disabled': {
                    background: '#999',
                    color: 'white',
                  },
                }}
                disabled={cityAlreadyExists}
                onClick={handleCreateCity}
              >
                <CopySmallBold sx={{ fontSize: '0.8rem' }}>
                  Create
                </CopySmallBold>
              </Button>
            </Stack>
          )}

          {/* End of Bottom Actions Stack */}
        </Box>
        {/* End of Modal Box */}
      </Fade>
    </Modal>
  );
};

export default CityCreationModal;
