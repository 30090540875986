import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Collapse, Link, Stack } from '@mui/material';
import { UICtx } from '../../UIProvider';
import { City, PoI } from '../../ifaces';
import { CopySmall, CopySmallBold } from '../Typography';
import StyledBarItem from '../containers/StyledBarItem';
import PoIStatusIcon from '../PoIDetails/PoIStatusIcon';
import MyLocationButton from '../buttons/MyLocationButton';
import { FlagIcon } from '../../Constants';
import { getCityPoIs } from '../../API';
import PoIsList from '../PoIsList/PoIsList';
import PoICreationWidget from '../PoICreationWidget/PoICreationWidget';
import { useNavigate } from 'react-router-dom';

interface CityListItemProps {
  city: City;
  expanded?: boolean;
  onClick: () => void;
  onCenterOnMap?: () => void;
}

const CityListItem: FC<CityListItemProps> = ({
  city,
  onClick,
  onCenterOnMap,
  expanded,
}) => {
  const navigate = useNavigate();
  const { darkMode, addNotification } = useContext(UICtx);
  const [loadingPoIs, setLoadingPoIs] = useState(false);
  const [pois, setPoIs] = useState<PoI[]>([]);

  const fetchData = useCallback(
    async (city: City) => {
      try {
        if (city) {
          setLoadingPoIs(true);
          const pois = await getCityPoIs(city.id);
          setPoIs(pois);
        }
      } catch (error) {
        addNotification({
          type: 'error',
          message: `Failed to fetch PoIs for location.`,
        });
        console.error('Error fetching data for prediction', error);
      }
      setLoadingPoIs(false);
    },
    [addNotification],
  );

  useEffect(() => {
    if (expanded) {
      fetchData(city);
    }
  }, [city, expanded, fetchData]);

  const handleOpenCityDetails = () => {
    navigate(`/cities/${city.id}`);
  };

  return (
    <StyledBarItem
      direction={'column'}
      sx={{
        p: '4px',
      }}
      spacing={1}
    >
      <Stack
        direction={'row'}
        sx={{
          p: '6px',
          pl: 1,
          alignItems: 'center',
        }}
        spacing={'4px'}
      >
        <FlagIcon countryCode={city.countryShort} />
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            flex: 1,
            px: 1,
          }}
        >
          <Stack direction={'row'} spacing={'4px'}>
            <CopySmallBold
              onClick={handleOpenCityDetails}
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
                fontSize: '0.9rem',
              }}
            >
              {city.name}
            </CopySmallBold>
            <MyLocationButton onClick={onCenterOnMap} fontSize={'1.0rem'} />
          </Stack>
          <CopySmall sx={{ fontSize: '0.7rem' }}>{city.placeId}</CopySmall>
          <Link
            href={`https://www.google.com/maps/place/?q=place_id:${city.placeId}`}
            target="_blank"
            rel="noreferrer"
            sx={{
              color: darkMode ? '#fff' : '#000',
              fontSize: '0.7rem',
            }}
          >
            Open in Google Maps
          </Link>
        </Stack>
        <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={'4px'}>
          <PoIStatusIcon />
        </Stack>
      </Stack>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Stack sx={{ height: '500px' }}>
          <PoIsList pois={pois} loading={loadingPoIs} />
        </Stack>
        {/* //////////////////////////////////////////////////////////////////// */}
        {/* ////                      PoI Creation Widget                   //// */}
        {/* //////////////////////////////////////////////////////////////////// */}

        <PoICreationWidget />
      </Collapse>
    </StyledBarItem>
  );
};

export default CityListItem;
