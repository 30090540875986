import { FC, useContext, useEffect, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';

// DnD Components
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

// Custom
import { UICtx } from '../../UIProvider';
import { PoI, TourStopTemplate, TourTemplate } from '../../ifaces';
import { CopySmallBold } from '../Typography';
import CloseButton from '../buttons/CloseButton';
import TourTemplateDetailsStopItem from './TourTemplateDetailsStopItem';
import { EditableCopySmall, EditableHeader3 } from '../TypographyEditable';
import TourImage from '../TourDetails/TourImage';
import {
  approveTourTemplate,
  updateTourTemplate,
  uploadTourImage,
} from '../../API';
import ListContainer from '../containers/ListContainer';

interface TourTemplateDetailsProps {
  template: TourTemplate;
  isSelected?: boolean;
  onClose?: () => void;
  availablePoIs?: PoI[];
  onPoISelected?: (poi: PoI) => void;
}

const TourTemplateDetails: FC<TourTemplateDetailsProps> = ({
  template,
  isSelected = false,
  onClose,
  availablePoIs,
  onPoISelected,
}) => {
  const { darkMode } = useContext(UICtx);
  const [stops, setStops] = useState<TourStopTemplate[]>([]);
  const [completeness, setCompleteness] = useState(0);

  // const handleStopSelected = (stop: TourStopTemplate) => {
  //   // Find the PoI that matches the stop
  //   const poi = availablePoIs?.find(poi => poi.id === stop.placeId);
  //   if (poi) {
  //     onPoISelected?.(poi);
  //   }
  // };

  useEffect(() => {
    const sortedStops = template.stops.sort(
      (a, b) => a.orderIndex - b.orderIndex,
    );
    setStops(sortedStops);
  }, [template.stops]);

  useEffect(() => {
    const calculateCompleteness = (stops: TourStopTemplate[]) => {
      const percentage = 100;
      setCompleteness(percentage);
    };
    calculateCompleteness(template.stops);
  }, [template.stops, availablePoIs]);

  const handleUploadImageToStorage = async (file: File) => {
    let newImageName = template?.imageName;
    if (!newImageName) {
      newImageName = crypto.randomUUID();
    } else {
      // Check if the image is already in the database
      // If it exists ask the user if they want to overwrite it
      console.log('Asking for confirmation');
    }

    await uploadTourImage(newImageName, file);
    await updateTourTemplate(template.id, { imageName: newImageName });
  };

  const handleTourNameUpdate = async (name: string) => {
    await updateTourTemplate(template.id, { name });
  };

  const handleTourDescriptionUpdate = async (description: string) => {
    await updateTourTemplate(template.id, { description });
  };

  const handleApproveTemplate = async () => {
    await approveTourTemplate(template.id);
  };

  const getIndex = (uuid: string) => stops.map(t => t.placeId).indexOf(uuid);

  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    if (over) {
      const activeIndex = getIndex(active.id as string);
      const overIndex = getIndex(over.id as string);
      if (activeIndex !== overIndex) {
        const _items = arrayMove(stops, activeIndex, overIndex);
        setStops(_items);
        // await bulkUpdateTechDetail(
        //   _items.map((item, index) => {
        //     return { uuid: item.uuid, order_index: index };
        //   }),
        // );
      }
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  return (
    <Stack
      sx={{
        flex: 1,
        background: darkMode ? '#111' : '#fff',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          right: '7px',
          top: '10px',
          zIndex: 1,
          background: darkMode ? '#111' : '#fff',
          borderRadius: '50%',
          ':hover': {
            background: darkMode ? '#222' : '#f6f6f6',
          },
        }}
      >
        <CloseButton onClose={onClose} />
      </Box>

      <TourImage
        imageName={template?.imageName}
        onUploadTourImage={handleUploadImageToStorage}
      />

      <Stack
        sx={{
          flex: 1,
          p: 2,
        }}
        spacing={1}
      >
        <Stack>
          <EditableHeader3 onSubmit={handleTourNameUpdate}>
            {template.name}
          </EditableHeader3>
          <EditableCopySmall onSubmit={handleTourDescriptionUpdate} multiline>
            {template.description}
          </EditableCopySmall>
          <CopySmallBold>{template.distance}</CopySmallBold>
        </Stack>

        <ListContainer>
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            sensors={sensors}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext
              items={stops.map(p => {
                return { ...p, id: p.placeId };
              })}
              strategy={verticalListSortingStrategy}
            >
              <Stack spacing={'4px'} flex={1}>
                {stops.map((stop, idx) => (
                  <TourTemplateDetailsStopItem
                    key={`stop-${idx}`}
                    stop={stop}
                  />
                ))}
              </Stack>
            </SortableContext>
          </DndContext>
        </ListContainer>

        <Stack
          sx={{
            alignItems: 'flex-end',
            border: '0.1px solid #222',
          }}
        >
          <Button
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              background: completeness < 100 ? 'brown' : 'green',
              height: '50px',
              width: '150px',
              cursor: 'pointer',
              userSelect: 'none',
              borderRadius: '10px',
              color: 'white',
              textTransform: 'none',
              ':hover': {
                background: completeness < 100 ? 'gray' : 'green',
              },
            }}
            disabled={completeness < 100}
            onClick={handleApproveTemplate}
          >
            <CopySmallBold sx={{ fontSize: '0.8rem' }}>
              {completeness < 100 ? `Incomplete (${completeness}%)` : 'Approve'}
            </CopySmallBold>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TourTemplateDetails;
