import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { UICtx } from '../../UIProvider';
import { Trip } from '../../ifaces';
import { Copy } from '../../components/Typography';
import TripStopsListWithMap from '../../components/PoIsList/TripStopsListWithMap';
import NavigationBar from '../../components/Navigation/NavigationBar';

const TourDetailsPage = () => {
  const { darkMode } = useContext(UICtx);

  // Get Tour Id from URL
  const { id } = useParams<{ id: string }>();

  // Trip State
  const [tour] = useState<Trip>();

  // const [dayItineraries, setDayItineraries] = useState<DayItinerary[]>([]);

  // Fetch day itineraries
  // useEffect(() => {
  //   if (trip?.dayItineraries) {
  //     setDayItineraries(trip.dayItineraries);
  //   }
  // }, [trip]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (id) {
  //       const tripRef = doc(firestore, 'trips', id);
  //       const tripDoc = await getDoc(tripRef);
  //       if (tripDoc.exists()) {
  //         const loadedTrip = tripDoc.data() as Trip;
  //         setTrip(loadedTrip);
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [id]);

  return (
    <Stack sx={{ flex: 1, background: darkMode ? '#121212' : '#fff' }}>
      <NavigationBar displayDarkModeSwitch />
      <Stack direction={'row'}>
        <Copy>{id}</Copy>
        <Copy>{tour?.id}</Copy>
      </Stack>
      <TripStopsListWithMap />
    </Stack>
  );
};

export default TourDetailsPage;
