import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { City, PoI } from '../../ifaces';
import { UICtx } from '../../UIProvider';
import { GeoPoint } from 'firebase/firestore';
import { addPoIToCity, getCityPoIs, getPoIsForLocation } from '../../API';
import PoIsList from '../../components/PoIsList/PoIsList';
import PoICreationWidget from '../../components/PoICreationWidget/PoICreationWidget';
import StyledStack from '../../components/containers/StyledStack';
import { CopySmallBold } from '../../components/Typography';
import { FlagIcon } from '../../Constants';
import UpdateCityPoIsButton from '../../components/buttons/UpdateCityPoIsButton';
import MyLocationButton from '../../components/buttons/MyLocationButton';

interface CityDetailsProps {
  city: City;
  onPoIsUpdate?: (pois: PoI[]) => void;
  onCenterOnMap?: (location: GeoPoint, zoom: number) => void;
}

const CityDetails: FC<CityDetailsProps> = ({
  city,
  onPoIsUpdate,
  onCenterOnMap,
}) => {
  const [loading, setLoading] = useState(false);
  const [pois, setPoIs] = useState<PoI[]>();
  const { isMobile, addNotification } = useContext(UICtx);

  const fetchData = useCallback(
    async (cityId: string) => {
      try {
        setLoading(true);
        const pois = await getCityPoIs(cityId);
        setPoIs(pois);
        onPoIsUpdate?.(pois);
      } catch (error) {
        addNotification({
          type: 'error',
          message: `Failed to fetch PoIs for location.`,
        });
        console.error('Error fetching data for prediction', error);
      }
      setLoading(false);
    },
    [addNotification, onPoIsUpdate],
  );

  const handlePoICreated = (poi: PoI) => {
    setPoIs([...(pois || []), poi]);
  };

  const handleAddPoIToCity = async (poi: PoI) => {
    if (city) {
      try {
        await addPoIToCity(city.id, poi.id);
        addNotification({
          type: 'success',
          message: `PoI added to city.`,
        });
      } catch (error) {
        addNotification({
          type: 'error',
          message: `Failed to add PoI to city.`,
        });
      }
    }
  };

  const fetchPoIs = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getPoIsForLocation({
        lat: city.location.latitude,
        lng: city.location.longitude,
        radius: 20000,
      });
      setPoIs(response.result);
      onPoIsUpdate?.(response.result);
    } catch (error) {
      addNotification({
        type: 'error',
        message: `Failed to fetch PoIs for location.`,
      });
      console.error('Error fetching data for prediction', error);
    } finally {
      setLoading(false);
    }
  }, [city, addNotification, onPoIsUpdate]);

  const handlePoIApproved = async (poi: PoI) => {
    await fetchPoIs();
  };

  useEffect(() => {
    fetchData(city.id);
  }, [city, fetchData]);

  return (
    <Stack
      sx={{
        flex: 1,
        p: 2,
        pr: isMobile ? 2 : 0,
      }}
      spacing={1}
    >
      <StyledStack
        sx={{
          width: '100%',
          p: 1,
          borderRadius: '10px',
          alignItems: 'center',
        }}
        direction={'row'}
      >
        <Stack
          direction={'row'}
          spacing={1}
          sx={{
            alignItems: 'center',
            flex: 1,
          }}
        >
          {city && (
            <>
              <FlagIcon countryCode={city.countryShort} />
              <Stack>
                <CopySmallBold>
                  {city.name}, {city.country}
                </CopySmallBold>
                <CopySmallBold
                  sx={{
                    fontSize: '0.5rem',
                    lineHeight: '0.5rem',
                  }}
                >
                  {city.placeId}
                </CopySmallBold>
              </Stack>
            </>
          )}
        </Stack>
        <MyLocationButton onClick={() => onCenterOnMap?.(city.location, 12)} />
        <UpdateCityPoIsButton onClick={fetchPoIs} />
      </StyledStack>

      {/* //////////////////////////////////////////////////////////////////// */}
      {/* ////                           PoIs List                        //// */}
      {/* //////////////////////////////////////////////////////////////////// */}

      <PoIsList
        loading={loading}
        pois={pois}
        onCenterOnMap={location => onCenterOnMap?.(location, 15)}
        onAddPoIToCity={handleAddPoIToCity}
        onPoIApproved={handlePoIApproved}
        city={city}
      />

      {/* //////////////////////////////////////////////////////////////////// */}
      {/* ////                      PoI Creation Widget                   //// */}
      {/* //////////////////////////////////////////////////////////////////// */}

      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        <PoICreationWidget onPoICreated={handlePoICreated} withCity={city} />
      </Stack>
    </Stack>
  );
};

export default CityDetails;
