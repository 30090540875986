export const FIREBASE_APP_API_KEY =
  process.env.REACT_APP_FIREBASE_API_KEY || '';
export const API_URL = process.env.REACT_APP_API_URL || '';
export const REACT_APP_PX_API_URL = process.env.REACT_APP_PX_API_URL || '';
export const REACT_APP_MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN || '';
export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
export const REACT_APP_DEV_BACKEND_URL =
  process.env.REACT_APP_DEV_BACKEND_URL || '';

export const firebaseConfig = {
  apiKey: FIREBASE_APP_API_KEY,
  authDomain: 'travel-dd765.firebaseapp.com',
  databaseURL:
    'https://travel-dd765-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'travel-dd765',
  storageBucket: 'travel-dd765.appspot.com',
  messagingSenderId: '380446101372',
  appId: '1:380446101372:web:a2f3fa7fb228977b211ef9',
  measurementId: 'G-JFRFJXYB3Q',
};
