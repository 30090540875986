import { FC, useState } from 'react';
import { City } from '../../ifaces';
import ListContainer from '../containers/ListContainer';
import CityListItem from './CityListItem';

interface CitiesListProps {
  cities?: City[];
  onCitySelected?: (city: City) => void;
  onCenterOnMap?: (city: City) => void;
}

const CitiesList: FC<CitiesListProps> = ({
  cities = [],
  onCitySelected,
  onCenterOnMap,
}) => {
  const [expandedItem, setExpandedItem] = useState<number | null>(null);

  const toggleItem = (index: number) => {
    setExpandedItem(prevIndex => (prevIndex === index ? null : index));
  };

  return (
    <ListContainer spacing={1}>
      {cities.map((city, index) => {
        return (
          <CityListItem
            key={index}
            city={city}
            onClick={() => toggleItem(index)}
            onCenterOnMap={() => onCenterOnMap?.(city)}
            expanded={expandedItem === index}
          />
        );
      })}
    </ListContainer>
  );
};

export default CitiesList;
