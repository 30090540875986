import { FC, useContext, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { UICtx } from '../../UIProvider';
import { PoI } from '../../ifaces';
import { Copy } from '../Typography';
import { fetchImageFromURL } from '../../utils';
import CloseButton from '../buttons/CloseButton';

interface PoIDetailsMiniProps {
  poi?: PoI;
  onClose?: () => void;
}

const PoIDetailsMini: FC<PoIDetailsMiniProps> = ({ poi, onClose }) => {
  // UI Context
  const { darkMode } = useContext(UICtx);

  // PoI Image State
  const [loadingImage, setLoadingImage] = useState(true);
  const [poiImage, setPoIImage] = useState<string>();

  useEffect(() => {
    const fetchImage = async (url: string) => {
      try {
        const data = await fetchImageFromURL(url);
        setPoIImage(data);
      } catch (error) {
        setLoadingImage(false);
      }
    };

    if (poi?.imageName) {
      fetchImage(poi.imageName);
    } else {
      setLoadingImage(false);
    }
  }, [poi]);

  return (
    <Stack
      direction={'row'}
      sx={{
        position: 'relative',
        width: '100%',
        background: darkMode ? '#040404' : '#fff',
      }}
    >
      {onClose && poi && (
        <Box
          sx={{
            position: 'absolute',
            right: '0',
            top: '0',
          }}
        >
          <CloseButton onClose={onClose} />
        </Box>
      )}
      {poi ? (
        <>
          <Stack
            sx={{
              position: 'relative',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Stack sx={{ height: '100px', width: '100px', background: 'gray' }}>
              {loadingImage ? (
                <CircularProgress sx={{ color: 'white' }} />
              ) : (
                <img
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  src={poiImage}
                  loading="lazy"
                  alt={'Profile'}
                />
              )}
            </Stack>
          </Stack>
          <Stack flex={1}>
            <Copy>
              {poi.name}, {poi.country}
            </Copy>
            {/* <Copy>{poi.description}</Copy> */}
          </Stack>
          <Stack sx={{ background: 'salmon', justifyContent: 'center' }}>
            <Button>Approve</Button>
          </Stack>
        </>
      ) : null}
    </Stack>
  );
};

export default PoIDetailsMini;
