import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Stack, Tooltip, Collapse } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CachedIcon from '@mui/icons-material/Cached';
import { CopySmall } from '../Typography';
import { PoIAudio, PoI, PoITranscript } from '../../ifaces';
import StyledBarItem from '../containers/StyledBarItem';
import PLessIconButton from '../buttons/PLessIconButton';
import { firestore } from '../../Firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import StyledStack from '../containers/StyledStack';
import LangGenPicker from '../LanguagePicker/LangGenPicker';
import { UICtx } from '../../UIProvider';
import {
  deletePoITranscript,
  enqueuePoITranscriptGeneration,
  generatePoITranscriptAudio,
  getPoITranscriptAudios,
  getPoITranscripts,
  regeneratePoITranscript,
} from '../../API';

interface ListItemProps {
  transcript: PoITranscript;
  expanded: boolean;
  onClick: () => void;
  onDeleted?: () => void;
  loading?: boolean;
}

const PoITranscriptListItem: FC<ListItemProps> = ({
  loading,
  transcript,
  expanded,
  onClick,
  onDeleted,
}) => {
  const [localLoading, setLoading] = useState(false);
  const { addNotification } = useContext(UICtx);
  const [localTranscript, setLocalTranscript] =
    useState<PoITranscript>(transcript);
  const [poiAudios, setPoIAudios] = useState<PoIAudio[]>([]);

  // Assume we have a sub-list component to render, for demonstration purposes.

  interface PoIAudioItemProps {
    audio: PoIAudio;
  }

  const PoIAudioItem: FC<PoIAudioItemProps> = ({ audio }) => (
    <div style={{ paddingLeft: '20px' }}>{audio.fileName}</div>
  );

  const handleGenerateAudio = async () => {
    try {
      setLoading(true);
      await generatePoITranscriptAudio(transcript.poi, transcript.id);
    } catch (error) {
      console.log('Failed to generate audio:', error);
      addNotification({
        type: 'error',
        message: 'Failed to generate audio',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRegenerateTranscript = async () => {
    try {
      setLoading(true);
      await regeneratePoITranscript(transcript);
      if (onDeleted) {
        onDeleted();
      }
    } catch (error) {
      addNotification({
        type: 'error',
        message: 'Failed to regenerate transcript',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePoITranscript = async () => {
    try {
      setLoading(true);
      await deletePoITranscript(transcript.poi, transcript.id);
    } catch (error) {
      addNotification({
        type: 'error',
        message: 'Failed to delete transcript',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const transcripts = await getPoITranscriptAudios(
          transcript.poi,
          transcript.id,
        );
        setPoIAudios(transcripts);
      } catch (error) {
        console.error('Failed to load PoI transcripts:', error);
      }
    };
    if (expanded) {
      fetchData();
    }
  }, [transcript, expanded]);

  useEffect(() => {
    const unsub = onSnapshot(
      doc(firestore, 'pois/' + transcript.poi + '/transcripts', transcript.id),
      snapshot => {
        const transcript = snapshot.data() as PoITranscript;
        setLocalTranscript(transcript);
      },
    );
    return unsub;
  }, [transcript]);

  return (
    <StyledBarItem
      direction={'column'}
      sx={{
        p: '10px',
        py: 1,
      }}
    >
      <Stack
        direction={'row'}
        onClick={onClick} // Add this line to handle item clicks
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Stack direction={'row'} flex={1} spacing={1}>
          {/* <Stack sx={{ alignItems: 'flex-end' }}>
            <Tooltip title="The cost of generating this transcript">
              <CopySmallBold
                sx={{
                  color: 'goldenrod',
                  fontSize: '0.6rem',
                  lineHeight: '0.7rem',
                }}
              >
                {floatToFormattedUSDString(localTranscript.totalCost)}
              </CopySmallBold>
            </Tooltip>
            <Tooltip title="The time it took to generate this transcript">
              <CopySmallBold
                sx={{
                  color: 'cadetblue',
                  fontSize: '0.6rem',
                  lineHeight: '0.7rem',
                }}
              >
                {localTranscript.genDuration
                  ? floatToformattedSecondsString(localTranscript.genDuration)
                  : '0s'}
              </CopySmallBold>
            </Tooltip>
          </Stack> */}
          <CopySmall>{localTranscript.language}</CopySmall>
          <CopySmall
            sx={{
              fontSize: '0.8rem',
            }}
            multiline
          >
            {localTranscript.source}
          </CopySmall>
        </Stack>
        <PLessIconButton
          onClick={handleRegenerateTranscript}
          disabled={localLoading || loading}
        >
          <CachedIcon
            sx={{
              color: localLoading || loading ? 'gray' : 'white',
              fontSize: '1.4rem',
              ':hover': {
                filter: 'contrast(200%)',
              },
            }}
          />
        </PLessIconButton>
        <PLessIconButton
          onClick={handleGenerateAudio}
          disabled={localLoading || loading}
        >
          <AudioFileIcon
            sx={{
              color: localLoading || loading ? 'gray' : 'cadetblue',
              fontSize: '1.4rem',
              ':hover': {
                filter: 'contrast(200%)',
              },
            }}
          />
        </PLessIconButton>
        {!localTranscript.locked ? (
          <PLessIconButton
            onClick={handleDeletePoITranscript}
            disabled={localLoading || loading}
          >
            <Tooltip title="Transcript not locked yet">
              <DeleteForeverIcon
                sx={{
                  color: localLoading || loading ? 'gray' : 'crimson',
                  fontSize: '1.5rem',
                  ':hover': {
                    filter: 'contrast(200%)',
                  },
                }}
              />
            </Tooltip>
          </PLessIconButton>
        ) : (
          <Tooltip title="Transcript locked">
            <LockIcon
              sx={{
                color: 'goldenrod',
                fontSize: '1.5rem',
                ':hover': {
                  filter: 'contrast(200%)',
                },
              }}
            />
          </Tooltip>
        )}
      </Stack>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Stack>
          <CopySmall sx={{ fontSize: '0.8rem', color: 'gray' }} multiline>
            {transcript.text}
          </CopySmall>
        </Stack>
        {poiAudios.map((audio, index) => (
          <PoIAudioItem key={index} audio={audio} />
        ))}
      </Collapse>
    </StyledBarItem>
  );
};

interface ComponentProps {
  poi: PoI;
  onUpdate?: (transcripts: PoITranscript[]) => void;
}

const PoITranscriptList: FC<ComponentProps> = ({ poi, onUpdate }) => {
  const { addNotification } = useContext(UICtx);
  const [transcripts, setTranscripts] = useState<PoITranscript[]>([]);
  const [loading, setLoading] = useState(false);
  const [expandedItem, setExpandedItem] = useState<number | null>(null);
  const [generatingTranscript, setGeneratingTranscript] = useState(false);

  const toggleItem = (index: number) => {
    setExpandedItem(prevIndex => (prevIndex === index ? null : index));
  };

  const handleGenerateDescription = async (language: string) => {
    try {
      setGeneratingTranscript(true);
      await enqueuePoITranscriptGeneration(poi.id, language);
      await fetchTranscripts();
      addNotification({
        type: 'success',
        message: 'Transcript Job successfully created.',
      });
    } catch (error) {
      addNotification({
        type: 'error',
        message: `Failed to generate description.`,
      });
    } finally {
      setGeneratingTranscript(false);
    }
  };

  const fetchTranscripts = useCallback(async () => {
    try {
      setLoading(true);
      const transcripts = await getPoITranscripts(poi.id);
      setTranscripts(transcripts);
      onUpdate?.(transcripts);
    } catch (error) {
      console.error('Failed to load PoI sources:', error);
      addNotification({
        type: 'error',
        message: 'Failed to fetch PoI sources',
      });
    } finally {
      setLoading(false);
    }
  }, [poi.id, addNotification, onUpdate]);

  const handleTranscriptDeleted = () => {
    fetchTranscripts();
  };

  useEffect(() => {
    if (poi) {
      fetchTranscripts();
    }
  }, [poi, fetchTranscripts]);

  return (
    <Stack spacing={1}>
      <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={'6px'}>
        <CopySmall
          sx={{
            lineHeight: '0.9rem',
            fontSize: '0.9rem',
          }}
        >
          Transcripts
        </CopySmall>
        <LangGenPicker
          onLanguagePicked={async language =>
            await handleGenerateDescription(language)
          }
          disabled={generatingTranscript || loading}
        />
      </Stack>
      <StyledStack>
        {transcripts.map((transcript, index) => (
          <PoITranscriptListItem
            key={index}
            transcript={transcript}
            expanded={expandedItem === index}
            onClick={() => toggleItem(index)}
            onDeleted={handleTranscriptDeleted}
            loading={generatingTranscript}
          />
        ))}
        {!loading && transcripts.length === 0 && (
          <CopySmall
            sx={{
              textAlign: 'center',
              p: 1,
            }}
          >
            No transcripts available. Please create one.
          </CopySmall>
        )}
      </StyledStack>
    </Stack>
  );
};

export default PoITranscriptList;
