import MainLayout from '../MainLayout';
import AITravelAssistant from '../../components/AITravelAssistant';
import FixedNavigationBar from '../../components/Navigation/FixedNavigationBar';

function IndexPage() {
  return (
    <MainLayout>
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                             Navigation                         //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <FixedNavigationBar displayDarkModeSwitch />

      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                           Travel Assistant                     //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      <AITravelAssistant />
    </MainLayout>
  );
}

export default IndexPage;
