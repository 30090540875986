import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Box, IconButton, InputAdornment, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { UICtx } from '../../UIProvider';

interface ChatInputFieldProps {
  placeholder?: string;
  inputText?: string;
  onValueChange?: (input: string) => void;
  disabled?: boolean;
  height?: string;
  fontSize?: string;
  maxWidth?: string;
}

const StyledInputBase: FC<ChatInputFieldProps> = ({
  placeholder = 'Enter your destination',
  inputText,
  onValueChange,
  disabled,
  height,
  fontSize,
  maxWidth,
}) => {
  const FONT_SIZE = '0.8rem';
  const { darkMode, isMobile } = useContext(UICtx);
  const [localInputState, setLocalInputState] = useState('');

  const [lastInputTime, setLastInputTime] = useState(0);
  const [lastInputTimeout, setLastInputTimeout] =
    useState<NodeJS.Timeout | null>(null);

  const handleValueChange = (newVal: string) => {
    if (onValueChange) {
      onValueChange(newVal);
    }
  };

  const handleTextChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newVal = event.target.value;
    setLocalInputState(newVal);

    // Check if last input was less than 500ms ago
    if (Date.now() - lastInputTime < 500) {
      // Clear timeout
      if (lastInputTimeout) {
        clearTimeout(lastInputTimeout);
      }
    }
    // Set new timeout
    const newTimeout = setTimeout(async () => {
      handleValueChange(newVal);
    }, 500);

    setLastInputTimeout(newTimeout);

    // Set last input time
    setLastInputTime(Date.now());
  };

  useEffect(() => {
    if (inputText) {
      setLocalInputState(inputText);
    }
  }, [inputText]);

  return (
    <Box
      sx={{
        display: 'flex',
        background: darkMode ? 'transparent' : 'rgb(244, 245, 251)',
        borderRadius: '40px',
        width: '100%',
        minWidth: isMobile ? undefined : '300px',
        maxWidth: maxWidth,
        position: 'relative',
        height: height || '50px',
      }}
    >
      <InputBase
        onChange={handleTextChange}
        disabled={disabled}
        value={localInputState}
        placeholder={placeholder}
        sx={{
          color: darkMode ? '#DDD' : 'black',
          pl: '10px',
          pr: 0,
          fontSize: fontSize || FONT_SIZE,
          fontFamily: "'Plus Jakarta Sans', sans-serif",
          '.MuiInputBase-input::placeholder': {
            fontSize: fontSize || FONT_SIZE,
            lineHeight: fontSize || FONT_SIZE,
          },
          '.MuiInputBase-input:focus::placeholder': {
            color: 'transparent',
            fontSize: fontSize || FONT_SIZE,
          },
          borderBottom: !darkMode
            ? undefined
            : disabled
              ? '2px solid #444'
              : '2px solid #CCC',
          position: 'absolute',
          top: 5,
          left: 0,
          right: 0,
          bottom: 5,
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              sx={{
                color: disabled ? '#444' : '#B1B1A1',
                p: '2px',
                mt: '2px',
              }}
              disableRipple
            >
              <SearchIcon
                sx={{
                  fontSize: '1.5rem',
                }}
              />
            </IconButton>
          </InputAdornment>
        }
      />
    </Box>
  );
};
export default StyledInputBase;
