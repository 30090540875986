import { FC, useContext, useState } from 'react';
import { Link, Stack } from '@mui/material';
import { UICtx } from '../../UIProvider';
import { City, PoI } from '../../ifaces';
import { MediumItalic } from '../Typography';
import StyledBarItem from '../containers/StyledBarItem';
import PoIStatusIcon from '../PoIDetails/PoIStatusIcon';
import MyLocationButton from '../buttons/MyLocationButton';
import PoIEditWidgetSmall from '../PoIDetails/PoIEditWidgetSmall';
import PoIItemOptions from './PoIItemOptions';
import ConfirmationModal from '../ConfirmModal';
import { deletePoI } from '../../API';
import PoIPopularity from './PoIPopularity';

interface PoIItemProps {
  poi: PoI;
  locked?: boolean;
  isSelected?: boolean;
  onSelect: () => void;
  onCenterOnMap?: () => void;
  onPoIDeleted?: () => void;
  onAddToCity?: () => void;
  onPoIApproved?: () => void;
  city?: City;
}

const PoIItem: FC<PoIItemProps> = ({
  poi,
  onSelect,
  onCenterOnMap,
  onPoIDeleted,
  onAddToCity,
  onPoIApproved,
  city,
}) => {
  const { darkMode, addNotification } = useContext(UICtx);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <StyledBarItem
      direction={'row'}
      sx={{
        p: 1,
        alignItems: 'center',
      }}
    >
      <ConfirmationModal
        loading={loading}
        loadingMsg="Deleting PoI"
        title={'Confirm PoI Deletion'}
        open={confirmDeleteModalOpen}
        maxWidth="sm"
        onClose={() => setConfirmDeleteModalOpen(false)}
        confirmButtonAction={async () => {
          try {
            setLoading(true);
            await deletePoI(poi.id);
            onPoIDeleted?.();
            setConfirmDeleteModalOpen(false);
            setTimeout(() => {
              setLoading(false);
            }, 500);
          } catch (error) {
            setLoading(false);
            addNotification({
              type: 'error',
              message: 'Failed to delete PoI',
            });
          }
        }}
        cancelButtonAction={() => {
          setConfirmDeleteModalOpen(false);
        }}
      />
      <PoIStatusIcon reviewedStatus={poi.reviewed ? 'approved' : 'pending'} />
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'flex-start',
          flex: 1,
          px: 1,
        }}
      >
        <Stack
          direction={'row'}
          sx={{
            alignItems: 'center',
          }}
          spacing={'4px'}
        >
          <MediumItalic
            onClick={onSelect}
            sx={{
              ':hover': {
                cursor: 'pointer',
              },
              fontSize: '0.9rem',
            }}
          >
            {poi.name}
          </MediumItalic>
          <PoIPopularity poi={poi} />
        </Stack>
        <Link
          href={`https://www.google.com/maps/place/?q=place_id:${poi.placeId}`}
          target="_blank"
          rel="noreferrer"
          sx={{
            color: darkMode ? '#fff' : '#000',
            fontSize: '0.7rem',
          }}
        >
          Open in Google Maps
        </Link>
      </Stack>
      <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={'4px'}>
        <PoIEditWidgetSmall
          poi={poi}
          editModalOpen={editModalOpen}
          onPoIApproved={onPoIApproved}
        />
        <MyLocationButton onClick={onCenterOnMap} />
        <PoIItemOptions
          onEdit={() => {
            setEditModalOpen(true);
          }}
          onDelete={() => {
            setConfirmDeleteModalOpen(true);
          }}
          onAddToCity={onAddToCity}
          city={city}
        />
      </Stack>
    </StyledBarItem>
  );
};

export default PoIItem;
