import { useNavigate } from 'react-router-dom';
import ScrollTo from 'react-scroll-into-view';
// MUI
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
  Stack,
} from '@mui/material';

import MenuItem from '@mui/material/MenuItem';

// Custom Components
import { getAuth, signOut } from 'firebase/auth';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import UserProfileImage from '../UserProfileImage';

import { ReactComponent as Logo } from '../../assets/logo.svg';
import { AuthCtx } from '../../AuthProvider';
import DarkModeSwitch from '../DarkModeSwitch';
import { UICtx } from '../../UIProvider';
import SignInButton from '../buttons/SignInButton';
import ChatInputField from '../InputField';
import { DataCtx } from '../../DataProvider';

interface NavigationBarProps {
  lightVersion?: boolean;
  displayDarkModeSwitch?: boolean;
  useFixedPosition?: boolean;
  withSearchBar?: boolean;
  hideLogo?: boolean;
}

const NavigationBar: FC<NavigationBarProps> = ({
  hideLogo,
  lightVersion,
  displayDarkModeSwitch,
  useFixedPosition,
  withSearchBar,
}) => {
  const { user, loading } = useContext(AuthCtx);
  const { isMobile, darkMode, setDarkMode } = useContext(UICtx);
  const { selectedPrediction, setSelectedPrediction } = useContext(DataCtx);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleSignOut = async () => {
    navigate('/signout');
    setTimeout(async () => {
      const auth = getAuth();
      await signOut(auth);
    }, 100);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleSignIn = () => {
    navigate('/login');
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef?.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack
      sx={{
        height: '64px',
        background: darkMode ? 'rgba(13,11,12,1)' : 'white',
        px: 2,
        alignItems: 'center',
        py: 1,
        position: useFixedPosition ? 'fixed' : 'relative',
        top: 0,
        zIndex: 100,
        width: '100%',
        transition: 'all 0.3s ease-in',
      }}
    >
      <Stack
        maxWidth={hideLogo ? undefined : 'lg'}
        flexDirection={'row'}
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: hideLogo ? 'flex-end' : 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        {!hideLogo && (
          <Stack
            sx={{
              width: isMobile ? '24px' : '30px',
              height: isMobile ? '24px' : '30px',
              cursor: 'pointer',
              ml: 1,
            }}
            onClick={() => {
              navigate('/');
            }}
          >
            <ScrollTo selector={`#page-head`}>
              <Logo
                fill={lightVersion && !darkMode ? 'black' : 'white'}
                style={lightVersion ? {} : {}}
              />
            </ScrollTo>
          </Stack>
        )}

        {withSearchBar && (
          <ChatInputField
            // onPredictionSelected={handleSelectPrediction}
            placeholder="Search for place"
            height="50px"
            fontSize="0.9rem"
            maxWidth="500px"
            onPlaceSelected={prediction => {
              setSelectedPrediction(prediction);
            }}
            onPredictionSelected={prediction => {
              setSelectedPrediction(prediction);
            }}
            initialPrediction={selectedPrediction}
          />
        )}

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleSignOut}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={1}>
          {displayDarkModeSwitch && (
            <DarkModeSwitch
              isDarkMode={darkMode}
              onValueChange={value => setDarkMode(value)}
            />
          )}
          {user && (
            <IconButton ref={anchorRef} onClick={handleToggle} sx={{ p: 0 }}>
              <UserProfileImage size={isMobile ? 32 : 36} />
            </IconButton>
          )}

          {!user && !loading && <SignInButton onClick={handleSignIn} />}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NavigationBar;
