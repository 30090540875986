import { FC, ReactNode, useContext } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  Paper,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { UICtx } from '../UIProvider';
import { CopyBold, CopySmallBold, Header3 } from './Typography';

const CloseModalButtonSVG: FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.46468 25.6875C1.82723 25.7246 1.20032 25.5127 0.716155 25.0965C-0.238719 24.1359 -0.238719 22.5846 0.716155 21.624L21.6248 0.71533C22.6179 -0.213997 24.1763 -0.162336 25.1057 0.830817C25.946 1.72892 25.995 3.10933 25.2203 4.06469L4.18854 25.0965C3.71062 25.5067 3.09377 25.7182 2.46468 25.6875Z"
      fill="#0D1116"
    />
    <path
      d="M23.3487 25.6876C22.7027 25.6848 22.0835 25.4284 21.6248 24.9734L0.716079 4.06466C-0.168562 3.03161 -0.0482908 1.47692 0.984764 0.592194C1.90679 -0.197398 3.2666 -0.197398 4.18855 0.592194L25.2203 21.5009C26.2133 22.4305 26.2646 23.989 25.335 24.9819C25.298 25.0214 25.2598 25.0596 25.2203 25.0965C24.7054 25.5444 24.0276 25.7584 23.3487 25.6876Z"
      fill="#0D1116"
    />
  </svg>
);

export const CancelButton = styled(Button)({
  '&.MuiButton-root': {
    minWidth: '150px',
    color: 'white',
    fontFamily: "'Poppins', normal",
    textTransform: 'none',
    fontSize: '12px',
    width: 'auto',
    padding: '10px 50px',
    backgroundColor: '#FF4D4F',
    '&:hover': {
      backgroundColor: '#FF4D4F',
    },
  },
});

export const ProceedButton = styled(Button)({
  '&.MuiButton-root': {
    minWidth: '150px',
    color: 'white',
    fontFamily: "'Poppins', normal",
    textTransform: 'none',
    fontSize: '12px',
    width: 'auto',
    padding: '10px 50px',
  },
});

interface ModalProps {
  children?: ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  title?: string;
  open?: boolean;
  onClose?: () => void;
  cancelButtonText?: string;
  cancelButtonAction?: () => void;
  confirmButtonText?: string;
  confirmButtonAction?: () => void;
  loading?: boolean;
  loadingMsg?: string;
}

export const CustomModal: FC<ModalProps> = ({
  maxWidth = 'xl',
  title,
  open = false,
  onClose,
  cancelButtonText = 'Cancel',
  cancelButtonAction,
  confirmButtonText = 'Confirm',
  confirmButtonAction,
  loading,
  loadingMsg,
  children,
}) => {
  const { darkMode } = useContext(UICtx);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={true}
      PaperProps={{
        style: {
          borderRadius: '15px',
          backgroundColor: darkMode ? '#161616' : '#fff',
          boxShadow: 'none',
          overflow: 'none',
          position: 'relative',
        },
      }}
    >
      {/* //////////////////////////////////////////////////////////////////////// */}
      {/* ////                         MODAL ACTION BUTTONS                   //// */}
      {/* //////////////////////////////////////////////////////////////////////// */}

      {(cancelButtonText || confirmButtonText) && !loading ? (
        <Stack
          direction={'row'}
          spacing={2}
          mt={2}
          sx={{
            height: '50px',
            marginBottom: '20px',
            bottom: 0,
            right: 0,
            left: 0,
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
          }}
        >
          {cancelButtonText && (
            <CancelButton
              id="ModalCancelButton"
              onClick={() => {
                if (cancelButtonAction) {
                  cancelButtonAction();
                }
              }}
            >
              <CopySmallBold>{cancelButtonText}</CopySmallBold>
            </CancelButton>
          )}
          {confirmButtonAction && confirmButtonText && (
            <ProceedButton
              id="TISModalProceedButton"
              disabled={loading}
              onClick={() => {
                if (confirmButtonAction) {
                  confirmButtonAction();
                }
              }}
              sx={{
                background: darkMode ? '#7896ff' : '#7896ff',
                '&:hover': {
                  background: darkMode ? '#7896ff' : '#7896ff',
                },
              }}
            >
              <CopySmallBold>{confirmButtonText}</CopySmallBold>
            </ProceedButton>
          )}
        </Stack>
      ) : null}

      <Paper
        sx={{
          p: 4,
          pb: loading ? 4 : '80px', // Only extend padding button when the modal is not loading
          borderRadius: '15px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: maxWidth,
          overflowY: 'auto',
          overflowX: 'none',
          background: darkMode ? '#161616' : '#fff',
        }}
      >
        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                         MODAL CLOSE BUTTON                     //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        {(!loading || loading === undefined) && (
          <div
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              cursor: 'pointer',
            }}
            onClick={onClose}
          >
            <CloseModalButtonSVG />
          </div>
        )}

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                     LOADING SPINNER AND MESSAGE                //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        {loading && (
          <Stack
            spacing={4}
            sx={{
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10,
            }}
          >
            {loadingMsg && <CopyBold>{loadingMsg}</CopyBold>}
            <CircularProgress
              id={'ModalCircularProgress'}
              size={70}
              thickness={2}
              sx={{ color: '#DDD' }}
            />
          </Stack>
        )}

        {/* //////////////////////////////////////////////////////////////////////// */}
        {/* ////                            MODAL CONTENT                       //// */}
        {/* //////////////////////////////////////////////////////////////////////// */}

        {!loading && (
          <>
            {title && (
              <Typography
                sx={{
                  fontSize: '1.2rem',
                  fontFamily: "'Poppins', normal",
                  mb: '10px',
                }}
              >
                <Header3>{title}</Header3>
              </Typography>
            )}
            <Stack
              sx={{
                flex: 1,
                position: 'relative',
                maxHeight: '500px',
                overflowY: 'scroll',
                pr: '2px',
                '&::-webkit-scrollbar': {
                  width: 5,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#7896ff',
                  borderRadius: 2,
                },
              }}
            >
              {children}
            </Stack>
          </>
        )}
      </Paper>
    </Dialog>
  );
};

export default CustomModal;
